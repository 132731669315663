import {CookieStorage} from 'cookie-storage';
import Constants from "../Configuration";
import moment from "moment";
import {Log, SigninRedirectArgs, User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';

export class AuthService {
    public userManager: UserManager;

    constructor() {
        const cookieStorage = new CookieStorage({
            domain: Constants.cookie_domain,
            path: '/',
            secure: true,
            sameSite: "None",
        });
        // @ts-ignore
        if (!window.cookieStorage) {
            // @ts-ignore
            window.cookieStorage = cookieStorage;
        }
        const settings: UserManagerSettings = {
            authority: Constants.stsAuthority,
            client_id: Constants.clientId,
            redirect_uri: `${Constants.clientRoot}signin-callback.html`,
            silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
            post_logout_redirect_uri: `${Constants.clientRoot}`,
            response_type: 'code',
            scope: Constants.clientScope,
            // userStore: new WebStorageStateStore({ store: window.localStorage }),
            clockSkewInSeconds: 90000,
            automaticSilentRenew: true,
            validateSubOnSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true,
            monitorSession: true,
            // userStore: new WebStorageStateStore({ store: window.localStorage }),
            // stateStore: new WebStorageStateStore({ store: window.localStorage }),
            // @ts-ignore
            userStore: new WebStorageStateStore({ store: window.cookieStorage }),
            // stateStore: new WebStorageStateStore({ store: cookieStorage }),
            // userStore: new WebStorageStateStore({ store: sessionStorage }),
            accessTokenExpiringNotificationTimeInSeconds: 10
            // clockSkew: 1,
        };
        this.userManager = new UserManager(settings);

        Log.setLogger(console);
        Log.setLevel(Log.DEBUG);
    }

    public async getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public getToken = () => {
        // @ts-ignore
        const storage: CookieStorage = window.cookieStorage;
        const tokenJson = storage.getItem(`oidc.user:${this.userManager.settings.authority}:${this.userManager.settings.client_id}`);
        // const tokenJson = localStorage.getItem(`oidc.user:${this.userManager.settings.authority}:${this.userManager.settings.client_id}`);
        if (tokenJson) return JSON.parse(tokenJson);
        return null;
    };

    public isExpired = (token: any): boolean => {
        if (!token) return true;
        if (!token.expires_at) return false;
        const mExpiresAt = moment.unix(token.expires_at);
        const res = mExpiresAt.isBefore(moment());
        return res;
    };

    public login(): Promise<void> {
        const path = window.location.pathname;
        const search = window.location.search;
        return this.userManager.signinRedirect(
            {
                state: {
                    path,
                    search
                }
            } as SigninRedirectArgs
        );
    }

    public renewToken(): Promise<User | null> {
        return this.userManager.signinSilent();
    }

    public async logout(): Promise<void> {
        await this.userManager.signoutRedirect();
        // localStorage.removeItem(`oidc.user:${this.userManager.settings.authority}:${this.userManager.settings.client_id}`);
    }
}
