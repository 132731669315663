import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import {Provider} from "react-redux";
import './i18n';
import {Helmet} from "react-helmet";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {createRoot} from 'react-dom/client';

Sentry.init({
    dsn: "http://16b474cbb8834f9b98316aed50de593f@185.244.180.6:9000/2",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
        <Provider store={store}>
            <Helmet meta={[
                { "name": "description", "content": "Dota 2 scripts and plugins"},
                {"name": "keywords", "content": "чит, dota чит, dota 2, dota, dota 2 чит, мапхак, dota 2 maphack, читы для доты, мапХак, автохук, автопрокаст, комбо на мипо и инвокера, divine dota, divine dota 2, читы дота divine, divine cheat дота, скрипты дота 2, скрипты dota 2, divine wtf читы дота 2, скрипты для доты, скачать чит на доту 2, дота скачать скрипты, дота скачать скрипты, скрипты на мипо дота, скрипты на инвокера дота 2, читы дота 2, чит дота 2 лобби, divine дота 2 скачать, читы дота 2 скачать divine cheats, скачать чит на дота 2 дивайн, дивайн чит на доту, дивайн втф чит дота 2, divine wtf dota 2, divinecheat dota 2, divine scripts dota 2 cheat, крашер дота 2, crasher dota 2, crasher, спам инвайтами в команду."},
            ]}>
                <meta charSet="utf-8"/>
                <title>Divine - Dota 2 scripts and plugins | Divine - приватный чит Dota 2</title>
                <link rel="canonical" href="https://divine.wtf"/>

            </Helmet>

            <App/>
        </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
