import {Input, Modal} from "semantic-ui-react";
import {useModalState} from "../../../Hooks/useModalState";
import Button from "../../../Components/Button";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import keys from "../../../translations/keys";

export type ICreateForumPageComponentProps = {
    isActive: boolean;
    onCreated: (title: string, description: string) => void;
    onClose: () => void;
}

const CreateForumPageComponent = (props: ICreateForumPageComponentProps) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const {t} = useTranslation();
    useEffect(() => {
        if (!props.isActive)
            setTitle('');
    }, [props.isActive]);

    if (!props.isActive) {
        return <></>
    }

    return <Modal open={props.isActive} onClose={props.onClose}>
        <Modal.Content>
            <Input fluid placeholder={'Page Title'} value={title} onChange={(event, data) => setTitle(data.value)}/>
            <Input fluid placeholder={'Mini description'} value={description} onChange={(event, data) => setDescription(data.value)}/>
        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={props.onClose}>{t(keys.cancel)}</Button>
            <Button positive onClick={() => props.onCreated(title, description)} disabled={title.length > 50 || !description}>{t(keys.forum.create)}</Button>
        </Modal.Actions>
    </Modal>
}

export default CreateForumPageComponent;