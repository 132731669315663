import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../Context/RootContext";
import {useHistory} from "react-router-dom";
import {UserManager, UserManagerSettings, WebStorageStateStore} from "oidc-client-ts";
import Button from "../Components/Button";
import {CookieStorage} from "cookie-storage";
import Constants from "../Configuration";

const SignInCallback = () => {
    // const {authService} = useContext(AuthContext)
    const {push, replace} = useHistory();
    const [error, setError] = useState('');
    let mgr: UserManager | null = null;
    useEffect(() => {
        const cookieStorage = new CookieStorage({
            domain: Constants.cookie_domain,
            path: '/',
            secure: true,
            sameSite: "None"
        });
        // @ts-ignore
        if (!window.cookieStorage) {
            // @ts-ignore
            window.cookieStorage = cookieStorage;
        }
        const settings: UserManagerSettings = {
            authority: Constants.stsAuthority,
            client_id: Constants.clientId,
            redirect_uri: `${Constants.clientRoot}signin-callback.html`,
            silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
            post_logout_redirect_uri: `${Constants.clientRoot}`,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true,
            monitorSession: true,
            validateSubOnSilentRenew: true,
            // userStore: new WebStorageStateStore({ store: window.localStorage }),
            // @ts-ignore
            userStore: new WebStorageStateStore({ store: window.cookieStorage }),
            // stateStore: new WebStorageStateStore({ store: cookieStorage }),
            // userStore: new WebStorageStateStore({store: sessionStorage}),
            accessTokenExpiringNotificationTimeInSeconds: 10,
            response_mode: "query",
        };
        mgr = new UserManager(settings);
        if (!mgr) {
            setError('Manager is not loaded')
            console.error("MGR IS NOT LOADED")
            return;
        }
        mgr.signinRedirectCallback().then(function (user) {
            const redirectPath = (user.state as any).path;
            push(redirectPath);
        }).catch(function (e) {
            console.error("ERROR in signinRedirectCallback");
            console.error(e);
            setError(e.toString());
            if (e.message.indexOf('exp is in the past') < 0 && e.message.indexOf('iat is in the future') < 0) {
                setTimeout(() => {
                    // window.location.href = 'https://' + window.location.host;
                }, 0);
            } else {
                push('/invalid-time');
            }
        });
    }, [])

    const onLoginHandler = () => {
        if (mgr as UserManager) {
            mgr?.signoutRedirect();
            mgr?.removeUser();
        }
        push('/main');
    };

    return <div className="sign-in-callback-page">
        <div className="sign-in-callback-page__container">
            <img className="sign-in-callback-page__container__logo" src='/Plugins/LogoBig.png' alt={'DIVINE'}/>
            {/*<span>Loading...</span>*/}
            {error && <div className="sign-in-callback-page__container__error">
                {error}
                <Button fluid negative onClick={onLoginHandler}>Login again</Button>
            </div>}
        </div>

    </div>
}

export default SignInCallback;
