import {useTranslation} from "react-i18next";
import keys from "../translations/keys";

const ResetPasswordErrorConfirm = () => {
    const {t} = useTranslation();
    return <div className="reset-password-error-confirm-page">
        <div className="reset-password-error-confirm-page__title">
            {t(keys.resetPassword.confirmPage.title)}
        </div>
        <div className="reset-password-error-confirm-page__content">
            {t(keys.resetPassword.confirmPage.text1)}
            <br/>
            <br/>
            <br/>
            {t(keys.resetPassword.confirmPage.text2)}
        </div>

    </div>
}

export default ResetPasswordErrorConfirm;
