import React from 'react';
import {useTranslation} from "react-i18next";
import keys from "../translations/keys";

const InvalidLocalTimePage = () => {
    const {t} = useTranslation();
    return (
        <div className="page__invalid-time">
            <div className="page__invalid-time__message">
                {t(keys.invalidTime)}
            </div>
            <img src="https://i.imgur.com/VeIZEFT.png" alt={'image'}/>

        </div>
    );
}

export default InvalidLocalTimePage;
