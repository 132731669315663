import {useState} from "react";
import classNames from "classnames";

export type IICategoryPickerItem = {
    id: number;
    text: string;
    disabled?: boolean;
}

export type ICategoryPickerProps = {
    items: IICategoryPickerItem[];
    onSelect: (item: IICategoryPickerItem) => void;
}

const CategoryPicker = ({items, onSelect}: ICategoryPickerProps) => {
    const [selected, setSelected] = useState({} as IICategoryPickerItem);

    const itemClass = (item: IICategoryPickerItem) => classNames('category-picker__item', {
        'category-picker__item--disabled': item.disabled,
        'category-picker__item--selected': item.id === selected.id,
    })

    const onSelectHandler = (item: IICategoryPickerItem) => {
        onSelect(item);
        setSelected(item)
    }

    return <div className={'category-picker'}>
        {items.map((item, index) => {
            return <div className={itemClass(item)} onClick={() => onSelectHandler(item)}>{item.text}</div>
        })}
    </div>
}

export default CategoryPicker;

