import useProgress from "./useProgress";
import axios from "axios";
import Constants from "../Configuration";
import React, {useState} from "react";
import {AuthContext} from "../Context/RootContext";
import {RequestType} from "../Services/ApiService";

export type ISubInfo = {
    // time: {
    //     days: number
    //     hours: number
    //     minutes: number
    // }
    time: Date;
    percent: number
}

export type ITokenInfoDto = {
    tokenString: string
    isUsed: boolean
    activatedBy: string
    activateTime: Date
    createdTime: Date
}

export type ITransactionInfoDto = {
    days: number;
    price: string;
    date: Date;
}

const useSubscribe = () => {
    const {error, isLoading, BeginLoading} = useProgress();
    const state = React.useContext(AuthContext)
    const [transaction, setTransaction] = useState([] as ITransactionInfoDto[]);
    const [subInfo, setSubInfo] = useState({} as ISubInfo);

    const generateToken = async (days: number, count: number) => {
        return await BeginLoading(async () => {
            const data = await axios.get(Constants.apiEndpoint + `Subscribe/generateTokens/${days}/${count}`)
            return data.data;
        })
    }
    const getSubInfo = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/subInfo`)
            setSubInfo(data.data);
            return data.data;
        })
    }

    const getSubInfoById = async (id: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/subInfo/${id}`)
            setSubInfo(data.data);
            return data.data;
        })
    }

    const activateToken = async (token: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/activateToken`, {
                token: token
            }, RequestType.POST)
            return data.data;
        })
    }

    const addSubDaysToUser = async (userId: string, days: number) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/addSubDaysToUser/${userId}/${days}`)
            return data.data;
        })
    }

    const getAllTokens = async (): Promise<ITokenInfoDto[]> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/tokens/all`)

            return data.data;
        })
    }


    const getTransactionHistory = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + 'Subscribe/transactions');
            setTransaction(data.data);
            return data.data;
        })
    }

    const getTransactionById = async (tranId: string): Promise<{ isSuccess: boolean, days: number }> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/transactions/${tranId}`);
            setTransaction(data.data);
            return data.data;
        })
    }

    const getGamesCount = async (): Promise<number> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Subscribe/totalGamesPlayed`);
            return data.data;
        })
    }

    return {
        generateToken,
        activateToken,
        getSubInfo,
        getAllTokens,
        getTransactionHistory,
        addSubDaysToUser,
        getSubInfoById,
        isLoading,
        subInfo,
        transaction,
        getTransactionById,
        getGamesCount
    }
}

export default useSubscribe;
