import classNames from "classnames";

const MainContainPageBlock = ({children, className}: any) => {
    const cls = classNames("main-contain-page-block", className)
    return <div className={cls}>
        {children}
    </div>
}

export default MainContainPageBlock;
