import React from "react";
import {AuthContext} from "../Context/RootContext";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {RequestType} from "../Services/ApiService";

const useVkApi = () => {
    const state = React.useContext(AuthContext)
    const {MessageContainer, MessagePanel, error, isLoading, BeginLoading} = useProgress();
    const auth = async (response: any) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + 'VkApi/VkAuth', response, RequestType.POST);
            return data.data;
        })
    }

    return {
        auth
    }
}

export default useVkApi;
