import { TAIL_CLASS_NAME } from './tail';

export const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const closePortalEvent = 'close_portal_event';

type ClosePortalSettings = { 
    maxBottomOffset: number
};

export const tryClosePortal = (element: any, settings: ClosePortalSettings) => {
    const portalElements = document.getElementsByClassName('krkn__portal');
    if (!portalElements || !portalElements.length) return;
    if (!element || !element.current) return;

    const foundPortal = portalElements[0];
    const tailElements = foundPortal.getElementsByClassName(TAIL_CLASS_NAME);
    if (!tailElements || !tailElements.length) return;

    const tail = tailElements[0];

    const tailChildElements = tail.getElementsByTagName('div');
    if (!tailChildElements || !tailChildElements.length) return;

    const tailChild = tailChildElements[0];

    const tailContentElements = tailChild.getElementsByTagName('div');
    if (!tailContentElements || !tailContentElements.length) return;

    const elementRect = element.current.getBoundingClientRect();
    const tailContentRect = tailContentElements[0].getBoundingClientRect();

    const removePortal = () => {
        document.body.removeChild(foundPortal);
        const event = new CustomEvent(closePortalEvent);
        document.dispatchEvent(event);
    };

    const { maxBottomOffset } = settings;

    const isOutsideTop = tailContentRect.y < elementRect.y;
    const isOutsideBottom = tailContentRect.y + tailContentRect.height > elementRect.y + elementRect.height + maxBottomOffset;
    if (isOutsideTop || isOutsideBottom) {
        removePortal();
    }
};
