import {useTranslation} from "react-i18next";
import {Label, Search} from "semantic-ui-react";
import {IConfigList} from "../../Hooks/useConfig";
import React, {useEffect, useState} from "react";
import Scrollbars from "rc-scrollbars";
import Button from "../../Components/Button";
import useDebouncedCallback from "../../Hooks/useDebouncedCallback";
import keys from "../../translations/keys";
import Image from 'react-image-webp';

export type IConfigsBrowserProps = {
    getPublicList: (search: string) => Promise<IConfigList[]>
    installPlugin: (config: IConfigList) => void
    installPluginByCode: (config: IConfigList) => void
    isLoading: boolean;

    publicConfigs: IConfigList[];
}

const ConfigsBrowser = (props: IConfigsBrowserProps) => {
    const {t} = useTranslation();
    // const [configs, setConfigs] = useState([] as IConfigList[]);
    const {publicConfigs: configs} = props;
    const [result, setResult] = useState([] as IConfigList[]);
    const [search, setSearch] = useState('');
    // const [configs, setConfigs] = useState([
    //     {id: 1, name: 'Config name 1', authorName: 'jumpAttacker'},
    //     {id: 2, name: 'Config name 2', authorName: 'jumpAttacker'},
    //     {id: 3, name: 'Config name 3', authorName: 'jumpAttacker'},
    //     {id: 4, name: 'Config name 4', authorName: 'jumpAttacker'},
    // ] as IConfigList[]);
    // const fetch = async () => {
    //     const response = await props.getPublicList('');
    //     if (response)
    //         setConfigs(response)
    // }

    const getList = async () => {
        if (search.length > 0) {

            const response = await props.getPublicList(search);
            if (response)
                setResult(response)
        } else {
            setResult([])
        }
    }


    const resultRenderer = ({name}: any) => <Label content={name}/>
    const loadList = useDebouncedCallback(getList, 500)

    // useEffect(() => {
    //     fetch();
    // }, [])

    useEffect(() => {
        loadList();
    }, [search])


    return <div className="configs-browser">
        <div className="configs-browser--title">{t(keys.configPage.all.title)}</div>
        <div className="configs-browser--search">
            <Search
                loading={props.isLoading}
                onResultSelect={(e, data) => {
                    // dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })
                    props.installPluginByCode(data.result)
                }}
                onSearchChange={(event, data) => setSearch(data.value ? data.value : '')}
                resultRenderer={resultRenderer}
                results={result}
                value={search}
            />
        </div>
        <div className="configs-browser--list">
            <Scrollbars>
                <div className="configs-browser--list--content">
                    {configs?.map((config, index) => {
                        return <div className="config-item">
                            {/*<img className="backImage" src="/publicConfig.png" alt={'divine'}/>*/}
                            <Image
                                className="backImage"
                                src={'/publicConfig.png'}
                                webp={'/publicConfig.webp'}
                            />
                            <div className="config-item--title">
                                {config.name}
                            </div>
                            <div className="config-item--author">
                                {t(keys.configPage.author)} {config.authorName}
                            </div>
                            <div className="config-item--actions">
                                <Button onClick={event => {
                                    props.installPlugin(config)
                                }} circular loading={props.isLoading}>{t(keys.configPage.all.install)}</Button>
                            </div>
                        </div>
                    })}
                </div>
            </Scrollbars>
        </div>
    </div>
}

export default ConfigsBrowser;
