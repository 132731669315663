import {useHistory, useParams} from "react-router-dom";
import useForum, {IForumPageDto, IForumThemeDto} from "../../Hooks/useForum";
import {useEffect, useState} from "react";
import moment from "moment";
import Button from "../../Components/Button";
import CreateForumPageComponent from "./Components/CreateForumPageComponent";
import {useModalState} from "../../Hooks/useModalState";
import useAuth from "../../Hooks/useAuth";
import _ from "underscore";
import CreateForumThemeComponent from "./Components/CreateForumThemeComponent";
import {Icon, Loader, Placeholder, Transition} from "semantic-ui-react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useTranslation} from "react-i18next";
import keys from "../../translations/keys";

export type IForumPageItem = {
    page: IForumPageDto | undefined;
    isFake?: boolean;
}
export type IForumThemePageItem = {
    theme: IForumThemeDto;
}

const ForumThemeItem = ({theme}: IForumThemePageItem) => {
    const {push} = useHistory();
    const {t} = useTranslation();
    const handleClick = () => {
        push('/forum/theme/' + theme.id)
    }
    return <CSSTransition
        key={theme!.id}
        timeout={500}
        classNames="item"
    >
        <div className="forum_page__theme" onClick={handleClick}>
            <div className="forum_page__theme__item__wrapper">
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__title">{theme.hidden && `[${t(keys.forum.hidden)}] `}{theme.title} </div>
                </div>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__info">
                        <div className="forum_page__theme__info__author">{`${t(keys.forum.startedBy)} ${theme.authorName}`}</div>
                        <div
                            className="forum_page__theme__info__date">{t(keys.forum.updated)} {moment(theme.lastUpdate).fromNow()}</div>
                    </div>
                    <div className="forum_page__theme__action">
                        {theme.isPinned && <Icon name={'pin'} color={'orange'}/>}
                        <Icon name={'comments'} color={'orange'}/>
                        {theme.commentsCount}
                    </div>
                </div>
            </div>
        </div>
    </CSSTransition>
}

const ForumPageItem = ({page, isFake}: IForumPageItem) => {
    const {push} = useHistory();
    const {t} = useTranslation();
    const handleClick = () => {
        push('/forum/page/' + page!.id)
    }

    if (isFake) {
        return <div className="forum_page__theme">
            <div className="forum_page__theme__wrapper forum_page__theme__wrapper--simulate">
                <Loader active inline/>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__title"/>
                    <div className="forum_page__theme__action"/>
                </div>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__description"/>
                </div>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__action__date"/>
                    <div className="forum_page__theme__action__date"/>
                </div>
            </div>
        </div>
    }

    return <CSSTransition
        key={page!.id}
        timeout={500}
        classNames="item"
    >
        <div className="forum_page__theme" onClick={handleClick}>
            <div className="forum_page__theme__wrapper">
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__title">{page!.hidden && `[${t(keys.forum.hidden)}] `}{page!.title}</div>
                    <div className="forum_page__theme__action">
                        {page!.isPinned && <Icon name={'pin'} color={'orange'}/>}
                    </div>
                </div>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__description">{page!.miniDescription}</div>
                </div>
                <div className="forum_page__theme__wrapper__row">
                    <div className="forum_page__theme__action__date">{t(keys.forum.created)} {moment(page!.createdTime).fromNow()}</div>
                    {page!.updatedTime.toString() != '0001-01-01T00:00:00' &&
                    <div
                        className="forum_page__theme__action__date">{t(keys.forum.updated)} {moment(page!.updatedTime).fromNow()}</div>}
                </div>
            </div>
        </div>
    </CSSTransition>
}


const ForumPage = () => {
    const {t} = useTranslation();
    const {getPage, isLoading: isLoadingPage} = useForum();
    const {createPage, createTheme, hidePage} = useForum();
    let {id} = useParams<{ id: string | undefined }>();
    const [page, setPage] = useState(undefined as undefined | IForumPageDto);
    const createPageMode = useModalState();
    const createThemeMode = useModalState();
    const {claims} = useAuth();
    const canCreateForumPage = _.contains(claims, 'c_forum_page')
    const canDeleteForumPage = _.contains(claims, 'd_forum_page') && Number.parseInt(id!, 10) > 1;

    const loadPage = async () => {
        const page = await getPage(Number.parseInt(id!, 10));
        setPage(page);
    }

    const onCreatedPageHandler = async (title: string, description: string) => {
        await createPage(Number.parseInt(id!, 10), title, description);
        createPageMode.onClose();
        await loadPage();
    }

    const onCreateThemeHandler = async (title: string, description: string) => {
        await createTheme(Number.parseInt(id!, 10), title, description);
        createThemeMode.onClose();
        await loadPage();
    }

    const hidePageHandler = async () => {
        await hidePage(Number.parseInt(id!, 10));
        await loadPage();
    }

    useEffect(() => {
        if (id) {
            setPage(undefined);
            loadPage()
        }
    }, [id]);

    // if (isLoadingPage){
    //     return <div className="forum_page">
    //
    //     </div>
    // }

    return <div className="forum_page">
        <CSSTransition
            in={!isLoadingPage}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div className="forum_page__header">
                <div className="forum_page__title">
                    {page?.hidden && `[${t(keys.forum.hidden)}] `}{page?.title}
                </div>
                <div className="forum_page__actions">
                    {(canDeleteForumPage) && <Button circular negative onClick={hidePageHandler}>{t(keys.forum.delete)}</Button>}
                    {canCreateForumPage &&
                    <Button circular primary onClick={createPageMode.onOpen}>{t(keys.forum.createPage)}</Button>}
                    <Button circular primary onClick={createThemeMode.onOpen}>{t(keys.forum.createTheme)}</Button>
                </div>
            </div>
        </CSSTransition>
        {isLoadingPage ? <div className="forum_page__wrapper">
            <ForumPageItem page={undefined} isFake/>
            <ForumPageItem page={undefined} isFake/>
            <ForumPageItem page={undefined} isFake/>
            <ForumPageItem page={undefined} isFake/>
        </div> : <TransitionGroup className="forum_page__wrapper">
                {page?.pages?.map((page, index) => {
                    return <ForumPageItem page={page}/>
                })}
                {page?.themes?.map((theme, index) => {
                    return <ForumThemeItem theme={theme}/>
                })}
            </TransitionGroup>}

        <CreateForumPageComponent isActive={createPageMode.isOpen} onClose={createPageMode.onClose}
                                  onCreated={onCreatedPageHandler}/>
        <CreateForumThemeComponent isActive={createThemeMode.isOpen} onClose={createThemeMode.onClose}
                                   onCreated={onCreateThemeHandler}/>
    </div>
}

export default ForumPage;