import {Table} from "semantic-ui-react";
import BasicTopNavBar from "../Components/BasicTopNavBar";
import React from "react";

const Contacts = () => {
    return <div className="contacts__container">
        {/*<BasicTopNavBar show={true} headerText={'Contacts'}/>*/}
        <div className="contacts__container-table">
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Email</Table.Cell>
                        <Table.Cell>divinedota2cheat@gmail.com</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Full name</Table.Cell>
                        <Table.Cell>O. A.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Username</Table.Cell>
                        <Table.Cell>RoccoZero</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Discord</Table.Cell>
                        <Table.Cell>@RoccoZero#7777</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    </div>
}

export default Contacts;
