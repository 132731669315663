import React, {useContext, useEffect} from "react";
import useMainData, {IMainData, LoadMainDataEnum} from "../Hooks/useMainData";
import {AuthContext, IAuthTypeStatus} from "./RootContext";
import {useHistory, useLocation} from "react-router-dom";

export interface IStoreData extends IMainData {
    isLoading: boolean;
    isPricesLoaded: boolean;
    isMainLoaded: boolean;
    isPluginsLoaded: boolean;
}

const initialState: IStoreData = {
    isMainLoaded: false,
    isPricesLoaded: false,
    isPluginsLoaded: false,
    isDiscordLinked: false,
    isVkConfirmed: false,
    email: undefined,
    isLoading: true,
    isEmailConfirmed: false,
    isOldCostumer: false,
    pluginToggleGetDtos: [],
    prices: [],
    subEnd: null,
    crasherEnd: null,
    totalGamePlayed: 0
};

export const DataContext = React.createContext({
    ...initialState
} as IStoreData);

const DataProvider = ({children}: any) => {
    const authContext = useContext(AuthContext)
    const [state, setState] = React.useState({
        ...initialState
    } as IStoreData)

    const {loadData} = useMainData();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const fetch = async (type: LoadMainDataEnum) => {
            const result: IMainData = await loadData(type)
            if (result) {
                setState((prevState) => {
                    switch (type) {
                        case LoadMainDataEnum.prices:
                            return {
                                ...prevState,
                                email: result.email,
                                isEmailConfirmed: result.isEmailConfirmed,
                                prices: result.prices,
                                isOldCostumer: result.isOldCostumer,
                                isLoading: false,
                                isPricesLoaded: true
                            }
                        case LoadMainDataEnum.main:
                            return {
                                ...prevState,
                                email: result.email,
                                isEmailConfirmed: result.isEmailConfirmed,
                                subEnd: result.subEnd,
                                crasherEnd: result.crasherEnd,
                                totalGamePlayed: result.totalGamePlayed,
                                isDiscordLinked: result.isDiscordLinked,
                                isVkConfirmed: result.isVkConfirmed,
                                isLoading: false,
                                isMainLoaded: true
                            }
                        case LoadMainDataEnum.plugins:
                            return {
                                ...prevState,
                                email: result.email,
                                isEmailConfirmed: result.isEmailConfirmed,
                                pluginToggleGetDtos: result.pluginToggleGetDtos,
                                isPluginsLoaded: true,
                                isLoading: false
                            }
                    }

                })
                if (!result.isEmailConfirmed) {
                    history.push('/confirm-email')
                }
            }
        }
        if (authContext.user && authContext.status === IAuthTypeStatus.LoggedIn) {
            if (location.pathname.indexOf('/plugins/') >= 0) {
                fetch(LoadMainDataEnum.plugins)
            } else
                switch (location.pathname) {
                    case '/buy': {
                        if (!state.isPricesLoaded)
                            fetch(LoadMainDataEnum.prices)
                        break;
                    }
                    case '/main':
                    case '/confirm-email': {
                        if (!state.isMainLoaded)
                            fetch(LoadMainDataEnum.main)
                        break;
                    }
                    case '/plugins/':
                    case '/plugins': {
                        fetch(LoadMainDataEnum.plugins)
                        break;
                    }
                }
        }

        if (!state.isLoading) {
            if (!state.isEmailConfirmed) {
                history.push('/confirm-email')
            }
        }
    }, [location.pathname]);

    return (
        <DataContext.Provider value={state}>
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider;
