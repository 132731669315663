import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useLogs from "../Hooks/useLogs";
import ReactMarkdown from "react-markdown";

const BuildLogView = () => {
    let {id} = useParams<{ id: string | undefined }>();
    const {getBuildLog} = useLogs()
    const [logData, setLogData] = useState({} as any);
    useEffect(() => {
        const fetchLog = async () => {
            if (id) {
                const data = await getBuildLog(id)
                setLogData(data);
            }
        }
        fetchLog();
    }, [])

    const str = `\`\`\`
${logData}
\`\`\``
    return (
        <ReactMarkdown className="line-break">
            {str}
        </ReactMarkdown>
    )
}

export default BuildLogView;
