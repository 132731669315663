import useAuth from "./useAuth";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {useState} from "react";

const useSignalR = () => {
    const {state} = useAuth();
    const [isOnline, setIsOnline] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        error,
        isLoading,
        BeginLoading
    } = useProgress();

    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();
    const loadOnlineState = async () => {
        StartLoading();
        const onlineData = await state.apiService.callApi(Constants.apiEndpoint + 'SignalR/online');
        setIsOnline(onlineData.data.isOnline)
        EndLoading();
    }

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const startDota = async () => {
        return await BeginLoading(async () => {
            if (isDisabled){
                return;
            }
            setDisabled(true)
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'SignalR');
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
            return result.data;
        })
    }

    const checkAccount = async (id: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `SignalR/check/${id}`);
            return result.data;
        })
    }

    return {
        isOnline,
        startDota,
        loadOnlineState,
        isLoading,
        checkAccount
    }

}

export default useSignalR;
