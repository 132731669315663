import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Icon, Loader} from "semantic-ui-react";
import Button from "../Components/Button";
import useSubscribe from "../Hooks/useSubscribe";
import {SignalRContext} from "../Context/SignalRContext";
import keys from "../translations/keys";
import {useTranslation} from "react-i18next";

const SuccessPayment = () => {
    let {id} = useParams<{ id: string | undefined }>();
    const {push} = useHistory();
    const {getTransactionById} = useSubscribe();
    const [status, setStatus] = useState(false);
    const [days, setDays] = useState(0);
    const {connection} = React.useContext(SignalRContext)
    const {t} = useTranslation();
    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const response = await getTransactionById(id.toString())
                const isSuccess = response?.isSuccess;
                const days = response?.days;
                setStatus(isSuccess);
                setDays(days);
            }
        }
        fetch();
    }, [id]);

    const changeState = (args: any) => {
        const days = args[0];
        const paymentId = args[1];
        if (paymentId === id) {
            setDays(days);
            setStatus(true);
        }
    }

    useEffect(() => {
        if (connection) {
            connection.on('PaymentConfirm', (args: any) => {
                changeState(args);
            });
        }
    }, [connection]);

    useEffect(() => {
        if (status)
            setTimeout(() => {
                push('/main');
            }, 5000);
    }, [status])

    const getIcon = () => {
        if (status) {
            return <Icon className="status_icon" name={'check circle outline'} size={"massive"} color={'green'}/>
        } else {
            return <Icon className="status_icon" name={'wait'} size={"massive"} color={'orange'}/>
        }
    }

    const text = t(keys.notifications.added) + t(keys.day.day, {count: Number(days)});
    return (
        <div className="success_payment_page">
            <div className="success_payment_page__container">
                {getIcon()}
                {status ? <>
                        <div className={'container__text success'}>{t(keys.notifications.successDone)}!</div>
                    </> :
                    <div className={'container__text'}>{t(keys.notifications.processing)} #{id}</div>
                }

                {!status && <div className={'container__sub'}>{t(keys.notifications.processingDescription)}</div>}
                {status && <div className={'container__success'}>{text}</div>}
                <div className="container__loader">
                    <Loader active inverted/>
                </div>
                {/*<Button className={'container__btn'} inverted onClick={(event, data) => {*/}
                {/*    push('/main');*/}
                {/*}}>*/}
                {/*    Вернуться на главную страницу*/}
                {/*</Button>*/}
                {/*<Button onClick={event => changeState([10, id])}>test</Button>*/}
            </div>
        </div>
    )
}

export default SuccessPayment;
