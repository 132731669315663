import React from "react";
import {Confirm} from "semantic-ui-react";
import {useModalState} from "./useModalState";
import {SemanticShorthandItem} from "semantic-ui-react/dist/commonjs/generic";
import {ButtonProps} from "semantic-ui-react/dist/commonjs/elements/Button";
import {ModalContentProps} from "semantic-ui-react/dist/commonjs/modules/Modal/ModalContent";


export type IUseConfirmationDialog = {
    headerText?: string;
    contentText?: SemanticShorthandItem<ModalContentProps>;
    onCloseCallback?: () => void;
    onConfirmCallback?: () => void;
    confirmButton?: SemanticShorthandItem<ButtonProps>
    cancelButton?: SemanticShorthandItem<ButtonProps>
}

const useConfirmationDialog =
    ({
         headerText,
         contentText,
         onCloseCallback,
         onConfirmCallback,
         confirmButton,
         cancelButton,
     }: IUseConfirmationDialog) => {
        const {isOpen, onOpen, onClose, onToggle} = useModalState();

        const onCloseHandler = () => {
            if (onCloseCallback)
                onCloseCallback();
            onClose();
        }

        const onConfirmHandler = () => {
            if (onConfirmCallback)
                onConfirmCallback();
            onClose();
        }

        const Dialog = <Confirm
            basic
            open={isOpen}
            onCancel={onCloseHandler}
            header={headerText}
            onConfirm={onConfirmHandler}
            content={contentText}
            confirmButton={confirmButton}
            cancelButton={cancelButton}
        />
        //
        // const Dialog = useCallback(
        //     () => (
        //         <Confirm
        //             open={isOpen}
        //             onCancel={onCloseHandler}
        //             header={headerText}
        //             onConfirm={onConfirmHandler}
        //             content={contentText}
        //             confirmButton={confirmButton}
        //             cancelButton={cancelButton}
        //         />
        //     ),
        //     [isOpen]
        // );

        return {
            Dialog,
            onOpen,
        };
    }

export default useConfirmationDialog;
