import React, {useContext, useEffect, useState} from "react";
import {DataContext} from "../Context/DataContext";
import {useTranslation} from "react-i18next";
import useAccount from "../Hooks/useAccount";
import Button from "../Components/Button";
import keys from "../translations/keys";
import useConfirmationDialog from "../Hooks/useConfirmationDialog";
import {Input} from "semantic-ui-react";
import {useToasts} from "react-toast-notifications";
import {useHistory} from "react-router-dom";
import moment from "moment";

const ConfirmEmailPage = () => {
    const {
        isLoading: isMainDataLoading,
        subEnd,
        totalGamePlayed,
        isEmailConfirmed,
        email: currentEmail
    } = useContext(DataContext);
    const {t} = useTranslation();
    const {
        changePassword,
        getHwid,
        resetHwid,
        error,
        isLoading,
        // userInfo,
        sendEmailVerification,
        changeEmail,
        loadUserInfoAndSave
    } = useAccount();
    const history = useHistory();
    const [onCooldown, setOnCooldown] = useState(false);
    const [email, setEmail] = useState('');
    const {addToast} = useToasts();
    const handlerError = (error: any) => {
        addToast(error, {appearance: 'error', autoDismiss: true});
    }
    useEffect(() => {
        if (currentEmail && currentEmail.length > 0) {
            setEmail(currentEmail);
        }
    }, [currentEmail])
    const {onOpen: onOpenNewEmail, Dialog: EmailDialog} = useConfirmationDialog({
        headerText: t(keys.account.changeEmail),
        contentText: () => {
            return <div className="account-page__modal" style={{width: '100%'}}>
                <Input onChange={(e) => setEmail(e.target.value)} focus
                       placeholder={t(keys.account.email)}
                       style={{width: '100%', padding: '0 5px'}}/>
            </div>
        },
        onConfirmCallback: async () => {
            const response = await changeEmail(email);
            if (response?.error) {
                handlerError(response.error);
            }
        }
    });

    useEffect(() => {
        if (isEmailConfirmed) {
            history.push('/main')
        }
    }, [isEmailConfirmed]);

    const sendEmailVerificationHandler = async () => {
        const result = await sendEmailVerification();
        if (result?.status === 200) {
            addToast('Done');
            setOnCooldown(true);
            setTimeout(args => {
                setOnCooldown(false);
            }, 60000)
        }
    }

    return <div className="need-to-confirm">
        <div className="need-to-confirm__wrapper">
            <img className={'need-to-confirm__wrapper__image'} src={'/ConfirmEmail/Mail.png'}/>
            <div className="need-to-confirm__wrapper__content">
                <img className={'need-to-confirm__wrapper__content__image'} src={'/ConfirmEmail/MiniMail.png'}/>
                <div className="need-to-confirm__title">
                    {t(keys.confirmEmailPage.title)}
                </div>
                <div className="need-to-confirm__mail">
                    {`${t(keys.confirmEmailPage.urEmail)}:`}<span
                    className={'need-to-confirm__mail__hint'}>{email}</span>
                </div>
                <div className="need-to-confirm__description">
                    {t(keys.confirmEmailPage.description)}
                </div>
                <div className="need-to-confirm__actions">
                    <Button loading={isLoading} color={'red'} fluid
                            onClick={onOpenNewEmail}>{t(keys.account.changeEmail)}</Button>
                    <Button loading={isLoading} disabled={isEmailConfirmed || onCooldown} color={'green'} fluid
                            onClick={sendEmailVerificationHandler}>{t(keys.account.confirmEmail)}</Button>
                </div>
            </div>

        </div>
        {EmailDialog}
    </div>
}

export default ConfirmEmailPage;
