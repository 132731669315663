import React, {useEffect, useState} from 'react'
import {Button, Checkbox, List, Modal} from 'semantic-ui-react'
import useAuth from "../Hooks/useAuth";
import axios from 'axios';
import useClaims from "../Hooks/useClaims";
import Constants from "../Configuration";

const regex = /^\w_\w*/;

export type IClaim = {
    name: string,
    isEnabled: boolean
}

const UserEditPage = ({open, setOpen, userId}: any) => {
    const [claims, setClaims] = useState([] as IClaim[]);

    const {setClaimsForUser, getClaims, isLoading, MessagePanel} = useClaims();

    useEffect(() => {
        const fetch = async () => {
            try {
                const response: any = await axios.get(Constants.stsAuthority + ".well-known/openid-configuration")
                const allClaims = response.data.claims_supported;
                const filteredClaims = allClaims.filter((x: string) => regex.exec(x))
                const {claims} = await getClaims(userId);
                setClaims(filteredClaims.map((x: string) => {
                    return {name: x, isEnabled: claims.some((z: string) => z === x)}
                }))
            } catch (e) {
                console.error(e)
            }

        }
        if (userId !== 0)
            fetch()
    }, [userId])

    const onUpdateProp = (index: number, newValue: boolean) => {
        setClaims((prevState: IClaim[]) => {
            const newArray = [...prevState]
            newArray[index].isEnabled = newValue
            return newArray
        })
    }

    const save = async () => {
        try {
            const postResult = await setClaimsForUser(userId, claims);
            console.log(postResult)
        } catch (e) {
            console.error(e)
        }
    }

    const convertName = (claim: IClaim) => {
        return claim.name
            .replace('r_', 'read_')
            .replace('d_', 'delete_')
            .replace('c_', 'create_')
            .replace('u_', 'update_')
            .replace('readelete_', 'read_')
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>Select claims</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <MessagePanel/>
                    <List>
                        {claims.map((claim: IClaim, index) => {
                            return (
                                <List.Item key={index}>
                                    <List.Content><Checkbox checked={claim.isEnabled}
                                                            onClick={(event) => onUpdateProp(index, !claim.isEnabled)}
                                                            label={`${convertName(claim)}`}/></List.Content>
                                </List.Item>
                            )
                        })}
                    </List>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' loading={isLoading} onClick={() => setOpen(false)}>
                    Back
                </Button>
                <Button
                    content="Save"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => save()}
                    positive
                    loading={isLoading}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default UserEditPage