import React from "react";
import {AuthContext} from "../Context/RootContext";
import Constants from "../Configuration";
import useProgress from "./useProgress";
import {IUserCreateModel} from "../Views/UserCreatePage";
import {RequestType} from "../Services/ApiService";

export type IUserModel = {
    userName: string;
    id: string;
    email: string;
    emailConfirmed: string;
}

export type IUserWithPageResponseDto = {
    users: IUserModel[],
    total: number
}

const useUsers = () => {
    const state = React.useContext(AuthContext)
    const {MessageContainer, MessagePanel, error, isLoading, BeginLoading} = useProgress();

    const getUsers = async (): Promise<IUserModel[]> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'User');
            return data.data;
        })
    }

    const getUsersWithPage = async (page: number, size: number): Promise<IUserWithPageResponseDto> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + `User/${page}/${size}`);
            return data.data;
        })
    }

    const createUser = async (userData: IUserCreateModel) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'User', userData, RequestType.POST);
            return data.data;
        })
    }

    const deleteUser = async (userId: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'User/' + userId, undefined, RequestType.DELETE);
            return data.data;
        })
    }

    return {
        state,
        getUsers,
        createUser,
        deleteUser,
        MessageContainer,
        MessagePanel,
        getUsersWithPage,
        error,
        isLoading
    }
}

export default useUsers
