import useAuth from "./useAuth";
import Constants from "../Configuration";
import {RequestType} from "../Services/ApiService";
import useProgress from "./useProgress";
import {ILocalPluginCreateDto} from "../Pages/LocalPluginsPage";

const FileDownload = require('js-file-download');

// import FileSaver from "file-saver";

export enum PluginType {
    Unknown = -1,
    Hero = 0,
    Utility = 1,
    Library = 2
}

export enum CostType {
    Free = 0,
    Buy = 1,
    Purchased = 2
}

export type CreateScriptDto = {
    Name: string;
    Author: string;
    GithubUrl: string;
    FullDescription: string;
    // FileId: number;
    PluginType: PluginType;
}

export type UpdateScriptDto = CreateScriptDto & {
    Id: number;
};

export type IPluginHistory = {
    updateDate: string;
    version: number;
};

// authorName: "Admin"
// cost: 0
// costType: 0
// createdTime: "2021-04-05T12:37:38"
// description: "best plugin"
// fileName: "O9K.AutoUsage.dll"
// hash: "3bd01c46dd93ccf3d6a0ebb74be5aea7"
// id: 23
// isLocal: "False"
// path: "https://github.com/DivineSharp/Divine.Plugins/blob/main/O9K/O9K.AutoUsage/O9K.AutoUsage.csproj"
// pluginName: "O9K.AutoUsage"
// type: 1
// youTubeLink: null

export type IPlugin = {
    id: number;
    pluginName: string;
    authorName: string;
    createdTime: string;
    type: PluginType;
    costType: CostType;
    description: string;
    path: string;
};

export interface ITogglePlugin extends IPlugin {
    isEnabled: boolean;
    isLocal: boolean;
    isAutoCompile: boolean;
    isOwner: boolean;
    countOfUsers: number;
    youTubeLink: string;
    imageId: string;
}

const useScripts = () => {
    const {state} = useAuth();
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        BeginLoading,
        error,
        isLoading
    } = useProgress();

    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();

    const GetScripts = async () => {
        const result = await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'info/plugins');
            return scripts.data as IPlugin[];
        })
        return result;
    }

    const GetScriptsForToggle = async () => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'info/plugins/with-toggle');
            return scripts.data as ITogglePlugin[];
        })
    }
    const GetScriptForApprove = async () => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'info/plugins/approve');
            return scripts.data as IPlugin[];
        })
    }

    /***
     * Обновить поле isEnabled у плагина
     * @param id - айди плиган
     * @param value новое значение
     * @constructor
     */
    const TogglePlugin = async (id: number, value: boolean) => {
        return await BeginLoading(async () => {
            return await state.apiService.callApi(Constants.apiEndpoint + `config/toggle/${id}/${value}`, undefined, RequestType.POST);
        });
    }

    /***
     * Обновить поле isEnabled у плагина
     * @param id - айди плиган
     * @param value новое значение
     * @constructor
     */
    const ToggleAutoCompilePlugin = async (id: number, value: boolean) => {
        try {
            startToggleLoading()
            await state.apiService.callApi(Constants.apiEndpoint + `config/toggle/autoCompile/${id}/${value}`, undefined, RequestType.POST);
        } catch (e) {
        } finally {
            endToggleLoading();
        }
    }
    const ForceCompilePlugin = async (id: number) => {
        try {
            startToggleLoading()
            await state.apiService.callApi(Constants.apiEndpoint + `config/toggle/forceUpdate/${id}`, undefined, RequestType.POST);
        } catch (e) {
        } finally {
            endToggleLoading();
        }
    }

    const UpdateDescription = async (id: number, description: string, youtubeLink: string) => {
        try {
            startToggleLoading()
            await state.apiService.callApi(Constants.apiEndpoint + `manager/changePluginDescription`, {
                PluginId: id,
                Description: description,
                YoutubeLink: youtubeLink,
            }, RequestType.PUT);
        } catch (e) {
        } finally {
            endToggleLoading();
        }
    }

    const GetScriptById = async (id: number) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'Script/' + id);
            return scripts.data;
        })
    }

    const CreateScript = async (data: CreateScriptDto) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'Manager/plugin', data, RequestType.POST);
            return scripts.data;
        })
    }

    const CreateLocalScript = async (data: ILocalPluginCreateDto) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'Manager/local-plugin', data, RequestType.POST);
            return scripts.data;
        })
    }

    const GetLocalScripts = async () => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'info/local-plugins');
            return scripts.data;
        })
    }
    const DeleteLocalScript = async (id: number) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + `Manager/local-plugin/${id}`, null, RequestType.DELETE);
            return scripts.data;
        })
    }

    const UpdateScript = async (data: UpdateScriptDto) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'Script', data, RequestType.PUT);
            return scripts.data;
        })
    }

    const ApprovePlugin = async (id: number) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'manager/plugin/approve/' + id, {}, RequestType.POST);
            return scripts.data;
        })
    }
    const AddToLocal = async (id: number) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'config/from-local/' + id);
            return scripts.data;
        })
    }

    const DeleteScript = async (id: number) => {
        return await BeginLoading(async () => {
            const scripts = await state.apiService.callApi(Constants.apiEndpoint + 'manager/plugin/' + id, undefined, RequestType.DELETE);
            return scripts.data;
        })
    }

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const DownloadFile = async (id: number, name: string) => {
        return await BeginLoading(async () => {
            const response = await state.apiService.callApi(Constants.apiEndpoint + 'Script/' + id + '/file', undefined, RequestType.GET, 'blob');
            FileDownload(response.data, `${name}.dll`);
        })
    }

    const GetLastUpdatedPlugins = async () => {
        const url = 'info/last-updated-plugins';
        return await BeginLoading(async () => {
            const response = await state.apiService.callApi(Constants.apiEndpoint + url);
            return response.data;
        })
    }

    return {
        GetScripts,
        TogglePlugin,
        GetScriptsForToggle,
        GetScriptById,
        CreateScript,
        UpdateScript,
        DeleteScript,
        DownloadFile,
        isLoading,
        MessagePanel,
        MessageContainer,
        GetLastUpdatedPlugins,
        ToggleAutoCompilePlugin,
        error,
        toggleLoading,
        CreateLocalScript,
        GetLocalScripts,
        DeleteLocalScript,
        ForceCompilePlugin,
        GetScriptForApprove,
        ApprovePlugin,
        AddToLocal,
        UpdateDescription
    }
}

export default useScripts;
