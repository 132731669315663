import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useLogs from "../Hooks/useLogs";
import {Container, Header, Segment, Table} from "semantic-ui-react";
import _ from "lodash";

const LogView = () => {
    let {id} = useParams<{id: string | undefined}>();
    const {getLog} = useLogs()
    const [logData, setLogData] = useState({} as any);
    useEffect(() => {
        const fetchLog = async () => {
            if (id) {
                const data = await getLog(Number(id))
                setLogData(data);
            }
        }
        fetchLog();
    }, [])

    const keys = logData?.data ? Object.keys(logData?.data || {}) : undefined;

    return (
        <>
            <Container>
                <Segment>
                    <Table basic='very' celled compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Ключ</Table.HeaderCell>
                                <Table.HeaderCell>Значение</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {_.without(Object.keys(logData), 'deleted', 'data', 'id').map((key, index) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    {key}
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell>{logData[key]}</Table.Cell>
                                    </Table.Row>)
                            })}
                            {keys?.map((key, index) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    {key}
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell>{logData?.data[key]}</Table.Cell>
                                    </Table.Row>)
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Container>
        </>
    )
}

export default LogView;
