import React from 'react'
import ReactMarkdown from 'react-markdown';
import {Button, Header, Modal, Table} from 'semantic-ui-react'

const LogViewModal = ({open, setOpen, log}: any) => {
    const keys = log?.data ? Object.keys(log?.data || {}) : undefined;

    const str = `\`\`\`
${log?.message}
\`\`\``

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>Полное описание</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <ReactMarkdown>
                        {str}
                    </ReactMarkdown>
                </Modal.Description>
                {keys && keys.length > 0 && <Table basic='very' celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Ключ</Table.HeaderCell>
                            <Table.HeaderCell>Значение</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {keys.map((key, index) => {
                            return (
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as='h4' image>
                                            <Header.Content>
                                                {key}
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{log?.data[key]}</Table.Cell>
                                </Table.Row>)
                        })}
                    </Table.Body>
                </Table>}
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                    Back
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default LogViewModal
