import HotkeyComponent from "../../Components/HotkeyComponent";
import CustomSlider from "../../Components/CustomSlider";
import React, {useEffect, useState} from "react";
import LangSelector from "../../Components/LangSelector";
import Dropdown, {IDropdownOption} from "../../Components/Dropdown";
import useAuth from "../../Hooks/useAuth";
import _ from "underscore";

export type IConfigSettingsProps = {
    isLoading: any;
    error: any;
    config: any;
    updateValue: (key: string, value: any) => void;
}

const ConfigSettings = ({isLoading, error, config, updateValue}: IConfigSettingsProps) => {
    const {claims} = useAuth();
    const [branchItems, setBranchItems] = useState([] as IDropdownOption[]);
    const [performanceItems, setPerformance] = useState([] as IDropdownOption[]);

    const canUse = (claimsToHave: string[]) => {
        return claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
    }

    useEffect(() => {
        const performanceItemsToAdd = [
            {id: 0, text: 'Safe'},
            {id: 1, text: 'Normal'},
            {id: 2, text: 'Fast'},
        ];

        setPerformance(performanceItemsToAdd);
    }, [])

    useEffect(() => {
        const branchItemsToAdd = [
            {id: 0, text: 'Prod'},
            {id: 1, text: 'Dev', claims: ['r_devpanel_in_loader']},
            {id: 2, text: 'Test', claims: ['test_branch']},
        ];

        const add: any = branchItemsToAdd.filter(((z: any) => canUse(z.claims))).map((z) => {
            return {
                id: z.id,
                text: z.text
            } as IDropdownOption
        });

        setBranchItems(add);

    }, [claims])

    // console.log('config.branch', config.branch)
    return <div className="config-settings">
        {!isLoading && !error && config && <>
            <HotkeyComponent value={config.reloadKey} label={'Reload'}
                             onChange={(_, value) => updateValue('reloadKey', value)}/>
            <HotkeyComponent value={config.unloadKey} label={'Unload'}
                             onChange={(_, value) => updateValue('unloadKey', value)}/>
            <HotkeyComponent value={config.fullReloadKey} label={'Full reload'}
                             onChange={(_, value) => updateValue('fullReloadKey', value)}/>
            <HotkeyComponent value={config.fullUnloadKey} label={'Full unload'}
                             onChange={(_, value) => updateValue('fullUnloadKey', value)}/>
            {/*<div className="input-block launch">*/}
            {/*    <div className="label">Launch options</div>*/}
            {/*    <input className="base-input" type="text" value={config.launchOptions || ''}*/}
            {/*           onChange={(value) => updateValue('launchOptions', value.target.value)}/>*/}
            {/*</div>*/}
            {/*<div className="input-block">*/}
            {/*    <div className="label">Dota2 path</div>*/}
            {/*    <input className="base-input" type="text" placeholder={`don't change it if all working fine`}*/}
            {/*           value={config.dotaPath || ''}*/}
            {/*           onChange={(value) => updateValue('dotaPath', value.target.value)}/>*/}
            {/*</div>*/}
            <div className="input-block toggle">
                <div className="label">Branch</div>
                <Dropdown dontSelectedFirstItemOnDefault onSelect={(value) => updateValue('branch', value.text)}
                          text={branchItems.filter((z: any) => z.text === config.branch)[0]?.text}
                          items={branchItems}
                />
            </div>
            {/* <div className="input-block toggle toggle--long">
                <div className="label">Performance mode</div>
                <Dropdown dontSelectedFirstItemOnDefault onSelect={(value) => updateValue('performanceMode', value.text)}
                          text={performanceItems.filter((z: any) => z.text === config.performanceMode)[0]?.text}
                          items={performanceItems}
                />
            </div> */}
            <div className="input-block toggle">
                <div className="label">Loader console</div>
                <CustomSlider value={config.loaderConsole} onChange={(value) => updateValue('loaderConsole', value)}/>
            </div>
            <div className="input-block toggle">
                <div className="label">Divine console</div>
                <CustomSlider value={config.dotaConsole} onChange={(value) => updateValue('dotaConsole', value)}/>
            </div>
            <div className="input-block toggle">
                <div className="label">Steam Spoofer</div>
                <CustomSlider value={config.hwidSpoof} onChange={(value) => updateValue('hwidSpoof', value)}/>
            </div>
            {/* <div className="input-block toggle full">
                <div className="label">Advanced Spoofer</div>
                <CustomSlider value={config.fullSpoof} onChange={(value) => updateValue('fullSpoof', value)}/>
            </div> */}


            <LangSelector/>
            {/*<div className="save" onClick={saveConfig}>Save</div>*/}
        </>}
    </div>
}

export default ConfigSettings;
