import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import useScripts from "../Hooks/useScripts";
import {Scrollbars} from "rc-scrollbars";
import useSignalR from "../Hooks/useSignalR";
import useAuth from "../Hooks/useAuth";
import useDllFiles from "../Hooks/useDllFiles";
import {SignalRContext} from "../Context/SignalRContext";
import {useToasts} from "react-toast-notifications";
import {Progress} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import keys from "../translations/keys";
import TransactionsBlock from "../Views/MainPageBlocks/Transactions/TransactionsBlock";
import Block from "../Components/Block";
import SubscribeBlock from "../Views/MainPageBlocks/SubscribeBlock/SubscribeBlock";
import BuySubBlock from "../Views/MainPageBlocks/BuySubBlock/BuySubBlock";
import MainContainPageBlock from "./Main/MainContainPageBlock";
import UserInfoBlock from "./Main/UserInfoBlock";
import StartDotaBlock from "./Main/StartDotaBlock";
import OtherSettings from "./Main/OtherSettings";
import _ from "underscore";
import classNames from "classnames";
import {DataContext} from "../Context/DataContext";
import NewYearBanner from "../Components/NewYearBanner";
import Snowfall from 'react-snowfall'
import CrasherBanner from "../Components/CrasherBanner";

function declOfNum(number: number, words: Array<string>) {
    return words[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
        ];
}


const MainContentPage = () => {

    const {t} = useTranslation();
    // const {GetLastUpdatedPlugins} = useScripts();
    const {isClientOnline: isOnline, downloads, canPlay, connection} = useContext(SignalRContext);
    // const [updatedList, setUpdatedList] = useState([])
    const {startDota, loadOnlineState, isLoading} = useSignalR();
    const {addToast} = useToasts();
    const {downloadClientFile} = useDllFiles();
    const {claims, isAuthenticated, user, state} = useAuth();
    const login = `${user?.profile.name}`
    // const {onOpen, Dialog} = useConfirmationDialog({
    //     headerText: 'Warning!',
    //     contentText: 'At this time cheat can be detected. Continue?',
    //     confirmButton: `I don't give a fuck, let me play`,
    //     cancelButton: `I will not use cheat right now`
    // })

    // const [loadingStage, setLoadingStage] = useState(1);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setLoadingStage(prevState => {
    //             if (prevState >= 100)
    //                 return 0
    //             else
    //                 return prevState + 1;
    //         })
    //     }, 10);
    //     return () => clearInterval(interval);
    // })

    // useEffect(() => {
    //     onOpen();
    // }, [])

    // useEffect(() => {
    //
    //     const fetch = async () => {
    //         // const data = await GetLastUpdatedPlugins();
    //         // const calcDifferentTime = (time: string) => {
    //         //     const now = moment.utc();
    //         //     const month = now.month() + 1;
    //         //     const dateString = `${now.date() < 10 ? `0${now.date()}` : now.date()}.${
    //         //         month < 10 ? `0${month}` : month
    //         //     }.${now.year()} ${now.hours()}:${now.minutes()}:${now.seconds()}`;
    //         //     const format = 'DD-MM-YYYY HH:mm:ss';
    //         //     const newNow = moment(dateString, format);
    //         //     const different = moment.duration(newNow.diff(time));
    //         //
    //         //     // let finalText = ('hours_ago');
    //         //     let finalText = ('hours ago');
    //         //     let finalDifferent = Math.ceil(different.asHours());
    //         //     if (finalDifferent > 48) {
    //         //         finalDifferent = Math.ceil(different.asDays());
    //         //         finalText = (
    //         //             declOfNum(finalDifferent, ['day ago', 'days ago', 'days ago'])
    //         //         );
    //         //     } else {
    //         //         finalText = (
    //         //             declOfNum(finalDifferent, ['hour ago', 'hours ago', 'hours ago'])
    //         //         );
    //         //     }
    //         //     const lastUpdate = `${finalDifferent} ${finalText}`;
    //         //     return lastUpdate;
    //         // }
    //         // data?.forEach((d: any) => {
    //         //     d.updateDate = calcDifferentTime(d.updateDate)
    //         // })
    //         // setUpdatedList(data);
    //
    //     }
    //     fetch();
    // }, [])

    // useEffect(() => {
    //     loadOnlineState();
    //     const interval = setInterval(() => {
    //         // loadOnlineState();
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);

    const startDotaHandler = async () => {
        if (_.isNull(canPlay)) {
            return;
        }
        // if (!canPlay) {
        //     addToast("You can't run the client without a subscription. Create a ticket in the discord if an error occurred.", {
        //         appearance: "error",
        //         autoDismiss: true
        //     })
        //     return;
        // }
        const result: any = await startDota();
        if (result?.error) {
            addToast(result.error, {
                appearance: "error",
                autoDismiss: true
            })
        }
    }

    const isSignalRActive = connection?.connectionState === "Connected";
    const onlineCls = classNames('title--status', {
        'title--status--active': isSignalRActive
    })

    return <div className="after-login-content">
        {/*<NewYearBanner/>*/}
        {/*<CrasherBanner/>*/}
        <MainContainPageBlock className={'welcome-info'}>
            <div className="welcome-info--title">{t(keys.welcomeHi)}
                <div className="title--bold">{login}</div>
                <div className={onlineCls}/>
            </div>
            <div className="welcome-info--description">{t(keys.welcomeInfo)}</div>
        </MainContainPageBlock>
        <MainContainPageBlock className={'user-info'}>
            <UserInfoBlock/>
        </MainContainPageBlock>
        <MainContainPageBlock className={'start-client-block'}>
            <div className="start-client-block--info">{t(keys.downloadInfo)}</div>
            <div className="start-client-block--title">{t(keys.download)}</div>
            <div className="start-client-block--content">
                <StartDotaBlock/>
            </div>
            <img className="start-client-block--icon" src="/startBlockImage.png" alt={'start-client-block--icon'}/>
        </MainContainPageBlock>
        <MainContainPageBlock>
            <OtherSettings/>
        </MainContainPageBlock>
    </div>

    /*return <div className="after-login-content">
        <div className="col">
            <TransactionsBlock/>
            <SubscribeBlock/>
        </div>
        <div className="col">
            <BuySubBlock/>
            <div className="block block-download-and-start">
                {/!*<div className="header">*!/}
                {/!*    {isOnline ? 'Divine is running' : 'Divine is not running'}*!/}
                {/!*</div>*!/}
                <div className="content">
                    {isOnline ?
                        <div className="online-content">
                            <div className="start-btn" onClick={startDotaHandler}>{t(keys.mainPage.startDota)}</div>
                            <div className="scroll-bar-container">
                                <Scrollbars autoHide={true} autoHeight autoHeightMax={150} style={{width: '100%'}}>
                                    {Object.keys(downloads).filter(x => {
                                        const numberValue = Number(downloads[x]);
                                        return numberValue > 0 && numberValue < 100
                                    }).map((download) => {
                                        // return <ProgressBarComponent key={download} value={downloads[download]}
                                        //                              title={download}/>
                                        return <Progress key={download} percent={downloads[download]}
                                                         progress>{download}</Progress>
                                    })}
                                </Scrollbars>
                            </div>
                        </div> :
                        <>
                            <div className="buttons">
                                <div className="btn btn-one" onClick={() => {
                                    addToast(t(keys.mainPage.downloadingClient), {
                                        appearance: "info",
                                        autoDismiss: true
                                    })
                                    // NotificationManager.info()
                                    downloadClientFile()
                                }}>{t(keys.mainPage.downloadClient)}
                                </div>
                                <div className="or"/>
                                {/!*<div className="btn btn-two">*!/}
                                <a className='btn btn-two'
                                   href={`Divine://-StartedFromWebsite;userId:${user?.profile.sub}`}
                                   onClick={() => addToast(t(keys.mainPage.startingClient), {
                                       appearance: "info",
                                       autoDismiss: true
                                   })}>
                                    {t(keys.mainPage.startDivineClient)}
                                </a>
                                {/!*</div>*!/}
                            </div>
                        </>}
                </div>
            </div>
            <div className="block block-system">
                <div className="header">
                    {t(keys.mainPage.systemStatus)}
                </div>
                <div className="sub-header">
                    {moment().format('L')}
                </div>
                <div className="content">
                    <div className="el">
                        <svg width="0.8em" height="0.8em" viewBox="0 0 10 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 19V1M5 1L1 5M5 1L9 5" stroke="#5BC044" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        <div className="el">{t(keys.mainPage.uptime)}</div>
                        {/!*UpTime 99.9%*!/}
                    </div>
                    <div className="el">{t(keys.mainPage.working)}</div>
                </div>
            </div>
            <div className="block block-vac">
                <div className="header">
                    Anti-VAC system status
                </div>
                <div className="sub-header">
                    {moment().format('L')}
                </div>
                <div className="content">
                    <div className="el">
                        <svg width="0.8em" height="0.8em" viewBox="0 0 10 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 19V1M5 1L1 5M5 1L9 5" stroke="#5BC044" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        {/!*Protection 99.9%*!/}
                        {t(keys.mainPage.protection)}
                    </div>
                    <div className="el">{t(keys.mainPage.working)}</div>
                </div>
            </div>
            <div className="block block-updates">
                <div className="header">
                    <div className="text">{t(keys.mainPage.lastPluginsUpdate)}</div>
                </div>
                <div className="block__content">
                    <Scrollbars autoHide>
                        {updatedList && updatedList.map((x: any, index: number) => {
                            return <div className="item" key={index}>
                                <div className="name-with-icon">
                                    <div className="icon">
                                        <img className={'plugin-icon'} src="divine-main.png" alt=""/>
                                    </div>
                                    <div className="text">{x.pluginName}</div>
                                </div>
                                <div className="date">
                                    <svg width=".8em" height=".8em" viewBox="0 0 12 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 0L11.1962 7.5H0.803848L6 0Z" fill="#5BC044"/>
                                    </svg>
                                    {(x.updateDate)}
                                </div>
                            </div>
                        })}
                    </Scrollbars>
                </div>
            </div>
        </div>
        <div className="after-login-content__low_screen">
            <SubscribeBlock/>
            <div className="block block-download-and-start">
                <div className="content">
                    {isOnline ?
                        <div className="online-content">
                            <div className="start-btn" onClick={startDotaHandler}>{t(keys.mainPage.startDota)}</div>
                            <div className="scroll-bar-container">
                                <Scrollbars autoHide={true} autoHeight autoHeightMax={150} style={{width: '100%'}}>
                                    {Object.keys(downloads).filter(x => {
                                        const numberValue = Number(downloads[x]);
                                        return numberValue > 0 && numberValue < 100
                                    }).map((download) => {
                                        return <Progress key={download} percent={downloads[download]}
                                                         progress>{download}</Progress>
                                    })}
                                </Scrollbars>
                            </div>
                        </div> :
                        <>
                            <div className="buttons">
                                <div className="btn btn-one" onClick={() => {
                                    addToast(t(keys.mainPage.downloadingClient), {
                                        appearance: "info",
                                        autoDismiss: true
                                    })
                                    // NotificationManager.info()
                                    downloadClientFile()
                                }}>{t(keys.mainPage.downloadClient)}
                                </div>
                                <div className="or"/>
                                <a className='btn btn-two'
                                   href={`Divine://-StartedFromWebsite;userId:${user?.profile.sub}`}
                                   onClick={() => addToast(t(keys.mainPage.startingClient), {
                                       appearance: "info",
                                       autoDismiss: true
                                   })}>
                                    {t(keys.mainPage.startDivineClient)}
                                </a>
                            </div>
                        </>}
                </div>
            </div>
            <BuySubBlock/>
            <div className="block block-system">
                <div className="header">
                    {t(keys.mainPage.systemStatus)}
                </div>
                <div className="sub-header">
                    {moment().format('L')}
                </div>
                <div className="content">
                    <div className="el">
                        <svg width="0.8em" height="0.8em" viewBox="0 0 10 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 19V1M5 1L1 5M5 1L9 5" stroke="#5BC044" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        <div className="el">{t(keys.mainPage.uptime)}</div>
                        {/!*UpTime 99.9%*!/}
                    </div>
                    <div className="el">{t(keys.mainPage.working)}</div>
                </div>
            </div>
            <div className="block block-vac">
                <div className="header">
                    Anti-VAC system status
                </div>
                <div className="sub-header">
                    {moment().format('L')}
                </div>
                <div className="content">
                    <div className="el">
                        <svg width="0.8em" height="0.8em" viewBox="0 0 10 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 19V1M5 1L1 5M5 1L9 5" stroke="#5BC044" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        {/!*Protection 99.9%*!/}
                        {t(keys.mainPage.protection)}
                    </div>
                    <div className="el">{t(keys.mainPage.working)}</div>
                </div>
            </div>
            <Block title={t(keys.mainPage.lastPluginsUpdate)} className={'block-updates'}>
                {updatedList && updatedList.map((x: any, index: number) => {
                    return <div className="item" key={index}>
                        <div className="name-with-icon">
                            <div className="icon">
                                <img className={'plugin-icon'} src="divine-main.png" alt=""/>
                            </div>
                            <div className="text">{x.pluginName}</div>
                        </div>
                        <div className="date">
                            <svg width=".8em" height=".8em" viewBox="0 0 12 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 0L11.1962 7.5H0.803848L6 0Z" fill="#5BC044"/>
                            </svg>
                            {(x.updateDate)}
                        </div>
                    </div>
                })}
            </Block>
            <TransactionsBlock/>
        </div>
    </div>*/
}

export default MainContentPage;
