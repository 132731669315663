import {Button, Form, Input, Radio, Table} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import useSubscribe, {ISubInfo, ITokenInfoDto} from "../Hooks/useSubscribe";
import {useToasts} from "react-toast-notifications";
import moment from "moment";
import useAccount from "../Hooks/useAccount";
// import Paypal from "../Components/Paypal";
// import YandexPaymentBtn from "../Components/YandexPaymentBtn";
import Scrollbars from "rc-scrollbars";
import _ from "underscore";
import {useTranslation} from "react-i18next";
import DiscordButton from "../Components/DiscordButton";
import VkButton from "../Components/VkButton";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import PaypalButton from "../Components/PaypalButton";
import {AuthContext} from "../Context/RootContext";
import keys from "../translations/keys";
import HtmlEditor from "./Forum/Components/HtmlEditor";

const TestPage = () => {
    const {authService} = useContext(AuthContext)
    // const {isLoading, generateToken, activateToken, getSubInfo, getAllTokens} = useSubscribe();
    // const {getIp, getHwid} = useAccount(true);
    // const {addToast} = useToasts();
    //
    // const [count, setCount] = useState(1);
    // const [days, setDays] = useState(1);
    // const [tokensResult, setTokensResult] = useState([] as string[]);
    // const [allTokens, setAllTokens] = useState([] as ITokenInfoDto[]);
    // const [ips, setIps] = useState([])
    // const [hwid, setHwid] = useState('')
    const [value, setValue] = useState('<p>Hey this <strong>editor</strong> rocks 😀</p>')
    //
    // const [tokensForActivate, setTokensForActivate] = useState("");
    // const [daysLeft, setDatesLeft] = useState('');
    const {t} = useTranslation();
    // // @ts-ignore
    // // window.PDFJS.workerSrc  = process.env.PUBLIC_URL + '/pdf.worker.js'
    // // const [checkout, setCheckout] = useState(false);
    //
    // const GenerateTokensHandler = async () => {
    //     const result = await generateToken(days, count);
    //     setTokensResult(prevState => {
    //         return [...prevState, ...result]
    //     });
    // }
    //
    // const ActivateTokenHandler = async () => {
    //     const result = await activateToken(tokensForActivate);
    // }
    //
    // const GetSubInfoHandler = async () => {
    //     const result: ISubInfo = await getSubInfo();
    //     if (!result)
    //         return;
    //     // const timeExp = moment().add(result.time.days, 'day').add(result.time.hours, 'hours').add(result.time.minutes, 'minutes')
    //     // setDatesLeft(timeExp.fromNow());
    // }
    //
    // const GetIpHandler = async () => {
    //     const result = await getIp();
    //     console.log('result', result)
    //     setIps(result);
    // }
    // const GetHwidHandler = async () => {
    //     const result = await getHwid();
    //     setHwid(result);
    // }
    //
    // const GetAllTokensHandler = async () => {
    //     const result = await getAllTokens();
    //     setAllTokens(result);
    // }
    //
    // useEffect(() => {
    //     GetSubInfoHandler();
    //     GetIpHandler()
    //     GetHwidHandler()
    //     GetAllTokensHandler()
    // }, []);
    //
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    return <div className={'test_page'}>
        {/*<Button onClick={event => {*/}
        {/*    authService?.userManager.removeUser();*/}
        {/*}*/}
        {/*}>removeUser</Button>*/}
        {/*<Button onClick={event => {*/}
        {/*    authService?.userManager.revokeAccessToken();*/}
        {/*}*/}
        {/*}>signoutCallback</Button>*/}
        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">Активация токена</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <Input value={tokensForActivate}*/}
        {/*               onChange={(event, data) => setTokensForActivate(data.value)}/>*/}

        {/*        <Button onClick={ActivateTokenHandler} disabled={!tokensForActivate}>Активировать</Button>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">Текущая подписка (по UTC)</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <div>{`Истекает: ${daysLeft}`}</div>*/}

        {/*        <Button onClick={GetSubInfoHandler}>Обновить информацию</Button>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">История ip адресов</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <div className="test_page__block__content__result">*/}
        {/*            <div className="test_page__block__content__result__data" style={{height: 200}}>*/}
        {/*                <Scrollbars autoHide autoHeight={false}>*/}
        {/*                    {_.chain(ips).uniq((x: any) => x.ip).value().map((item: any, index) => {*/}
        {/*                        return <div key={index}>{item.ip}</div>;*/}
        {/*                    })}*/}
        {/*                </Scrollbars>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">hwid</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {hwid}*/}
        {/*    </div>*/}
        {/*</div>*/}


        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">checkout</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <Paypal/>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">checkout</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <iframe*/}
        {/*            src="http://www.free-kassa.ru/merchant/forms.php?gen_form=1&writer=seller&targets=1 month&default-sum=500&button-text=Оплатить&hint=&encoding=CP1251&type=big&m=307647&id=1694651"*/}
        {/*            width="650" height="540" frameBorder="0"/>*/}
        {/*    </div>*/}
        {/*</div>*/}


        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">Оплата</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        /!*<YandexPaymentBtn days={7}/>*!/*/}
        {/*    </div>*/}

        {/*</div>*/}

        {/*<div className="test_page__block" style={{width: '500px'}}>*/}
        {/*    /!*<DiscordButton/>*!/*/}
        {/*    /!*<VkButton/>*!/*/}
        {/*    /!*<PaypalButton/>*!/*/}
        {/*</div>*/}


        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 0})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 1})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 2})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 3})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 4})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.vehicle.vehicle, {count: 5})}*/}
        {/*    </div>*/}

        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.comment_base.comment, {count: 0})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.comment_base.comment, {count: 1})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.comment_base.comment, {count: 2})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.comment_base.comment, {count: 5})}*/}
        {/*    </div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        {t(keys.forum.comment_base.comment, {count: 10})}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div>
            <HtmlEditor onChange={setValue} value={value}/>
        </div>


        {/*<div className="test_page__block">*/}
        {/*    <div className="test_page__block__header">Генерация токена</div>*/}
        {/*    <div className="test_page__block__content">*/}
        {/*        <div className="test_page__block__content__forms">*/}
        {/*            <Form>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='1 токен'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={count === 1}*/}
        {/*                        onChange={() => setCount(1)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='10 токенов'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={count === 10}*/}
        {/*                        onChange={() => setCount(10)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*            </Form>*/}
        {/*            <Form>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='1 день'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 1}*/}
        {/*                        onChange={() => setDays(1)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='3 дней'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 3}*/}
        {/*                        onChange={() => setDays(3)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='7 дней'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 7}*/}
        {/*                        onChange={() => setDays(7)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='30 дней'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 30}*/}
        {/*                        onChange={() => setDays(30)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='90 дней'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 90}*/}
        {/*                        onChange={() => setDays(90)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*                <Form.Field>*/}
        {/*                    <Radio*/}
        {/*                        label='180 дней'*/}
        {/*                        name='radioGroup'*/}
        {/*                        checked={days === 180}*/}
        {/*                        onChange={() => setDays(180)}*/}
        {/*                    />*/}
        {/*                </Form.Field>*/}
        {/*            </Form>*/}
        {/*        </div>*/}

        {/*        <Button onClick={() => GenerateTokensHandler()}>Сгенерировать</Button>*/}

        {/*        <div className="test_page__block__content__result">*/}
        {/*            <div className="test_page__block__content__result__title">Результат</div>*/}
        {/*            <div className="test_page__block__content__result__data">*/}
        {/*                {tokensResult.map((value, index) => {*/}
        {/*                    return <div key={index} onClick={event => {*/}
        {/*                        navigator.clipboard.writeText(value)*/}
        {/*                            .then(() => {*/}
        {/*                                addToast('Токен скопирован', {*/}
        {/*                                    appearance: 'success',*/}
        {/*                                    autoDismiss: true*/}
        {/*                                })*/}
        {/*                            })*/}
        {/*                            .catch(err => {*/}
        {/*                                console.log('Something went wrong', err);*/}
        {/*                            });*/}
        {/*                    }*/}
        {/*                    }>{value}</div>;*/}
        {/*                })}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="test_page__block" style={{height: 400, width: 1200}}>*/}
        {/*    <Scrollbars autoHide autoHeight={false}>*/}
        {/*        <Table celled>*/}
        {/*            <Table.Header>*/}
        {/*                <Table.Row>*/}
        {/*                    <Table.HeaderCell>Создан</Table.HeaderCell>*/}
        {/*                    <Table.HeaderCell>Использован?</Table.HeaderCell>*/}
        {/*                    <Table.HeaderCell>Токен</Table.HeaderCell>*/}
        {/*                </Table.Row>*/}
        {/*            </Table.Header>*/}

        {/*            <Table.Body>*/}
        {/*                {allTokens.map((dto: ITokenInfoDto, index) => {*/}
        {/*                    return <Table.Row key={index}>*/}
        {/*                        <Table.Cell>*/}
        {/*                            {moment(dto.createdTime).fromNow()}*/}
        {/*                        </Table.Cell>*/}
        {/*                        <Table.Cell>{dto.isUsed ? `Использован ${moment(dto.activateTime).fromNow()}` : 'Не использован'} </Table.Cell>*/}
        {/*                        <Table.Cell>{dto.tokenString}</Table.Cell>*/}
        {/*                    </Table.Row>*/}
        {/*                })}*/}
        {/*            </Table.Body>*/}
        {/*        </Table>*/}
        {/*    </Scrollbars>*/}
        {/*</div>*/}
    </div>
}

export default TestPage;
