import useAuth from "./useAuth";
import useAccount from "./useAccount";
import React, {useContext, useEffect} from "react";
import {LanguageContext} from "../Context/LanguageContext";
import axios from "axios";
import Constants from "../Configuration";
import useProgress from "./useProgress";
import {RequestType} from "../Services/ApiService";
import {AuthContext} from "../Context/RootContext";

export type IPriceInfo = {
    id: number,
    days: number,
    price: number
    usdPrice: number
    discount: number
    digUrl: string
    isCis: boolean
}

export type IPayment = {
    price: string;
    daysEnum: number;
    createdTime: string;
}

export type IToken = {
    createReason: 1
    lifeTime: 1
}

export enum AddSubReasonEnum
{
    Unset,
    VkPromo,
    DiscordPromo,
    VkPay,
    Digseller,
    DigsellerReseller,
    ResellerChina,
    FreeKassa,
    Paymaster,
    JustToken,
    JustPayment
}

export type ISubReasonObject = {
    payment?: IPayment;
    token?: IToken;
    subReason: AddSubReasonEnum;
    createdTime: string;
    days?: number;
}

export const usePayment = () => {
    // const {user} = useAuth();
    // const {userInfo, loadUserInfoAndSave} = useAccount();
    // const userId = user?.profile?.sub;
    const state = React.useContext(AuthContext)
    const {lang} = useContext(LanguageContext);
    const {error, isLoading, BeginLoading} = useProgress();
    useEffect(() => {
        // loadUserInfoAndSave();
    }, [])


    const getPaymentLink = async (priceId: number, currency: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `payment/GetPaymentModel/${priceId}/${currency}`);
            return data.data;
        })
    }
    const getPrimePaymentsPaymentLink = async (priceId: number, currency: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `payment/PrimePayments/GetPaymentModel/${priceId}/${currency}`);
            return data.data;
        })
    }
    const getPaymaxPaymentLink = async (priceId: number, currency: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Payment/Paymax/GetPaymentForm/${priceId}/${currency}`);
            return data.data;
        })
    }
    const getPaymentLinkForPaymaster = async (priceId: number, currency: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `payment/Paymaster/GetPaymentModel/${priceId}/${currency}`);
            return data.data;
        })
    }
    const getPrices = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `info/prices`);
            return data.data as IPriceInfo[];
        })
    }
    const getPaymentByUser = async (userId: string): Promise<ISubReasonObject[]> => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `payment/GetAllPayments/db/${userId}`);
            return data.data as ISubReasonObject[];
        })
    }

    return {
        getPaymentLink,
        getPaymentByUser,
        getPaymentLinkForPaymaster,
        getPaymaxPaymentLink,
        getPrimePaymentsPaymentLink,
        isLoading,
        getPrices
    }
}
