import TextInput from "../Components/TextInput";
import {Button, Input} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import useScripts, {ITogglePlugin} from "../Hooks/useScripts";
import Scrollbars from "rc-scrollbars";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import keys from "../translations/keys";
import {useTranslation} from "react-i18next";

export type ILocalPluginCreateDto = {
    name: string;
    url: string;
}

export type ILocalPluginReadDto = {
    pluginName: string;
    path: string;
    id: number;
}

const LocalPluginsPage = () => {
    const [localPluginCreateDto, setLocalPluginCreateDto] = useState({
        name: '',
        url: ''
    } as ILocalPluginCreateDto);
    const [localPlugins, setLocalPlugins] = useState([] as ITogglePlugin[]);
    const {t} = useTranslation();
    const {
        CreateLocalScript,
        GetLocalScripts,
        DeleteLocalScript,
        TogglePlugin,
        ToggleAutoCompilePlugin,
        ForceCompilePlugin
    } = useScripts();
    const [search, setSearch] = useState('')
    // const ref = useRef<HTMLInputElement>(null)
    // const [file, setFiles] = useState(null as File | any)
    const updateValue = (key: string, value: any) => {
        setLocalPluginCreateDto(prevState => {
            return {...prevState, [key]: value};
        })
    }

    // const clickHandler = () => {
    //     if (ref && ref.current) {
    //         ref.current.click();
    //     }
    // }

    // useEffect(() => {
    //     if (file) {
    //         const reader = new FileReader();
    //
    //         reader.onload = function (e) {
    //             // $('#blah').attr('src', e.target.result);
    //             console.log(e.target?.result)
    //         }
    //         reader.readAsDataURL(file); // convert to base64 string
    //         updateValue('path', file.name);
    //     }
    // }, [file])

    const getLocalPlugins = () => {
        GetLocalScripts().then(value => {
            setLocalPlugins(value)
        })
    }

    useEffect(() => {
        getLocalPlugins();
    }, [])

    // const onChangeHandler = () => {
    //     if (ref && ref.current && ref.current.files && ref.current.files.length > 0) {
    //         const file = ref.current.files[0];
    //         setFiles(file)
    //     }
    // }

    const addHandler = async () => {
        await CreateLocalScript(localPluginCreateDto);
        setLocalPluginCreateDto({
            name: '',
            url: ''
        })
        getLocalPlugins();
    }

    const deleteHandler = async (id: number) => {
        await DeleteLocalScript(id);
        getLocalPlugins();
    }
    const toggleHandler = async (id: number, value: boolean) => {
        await TogglePlugin(id, value)
        getLocalPlugins();
    }
    const onAutoCompileHandler = async (id: number, value: boolean) => {
        await ToggleAutoCompilePlugin(id, value)
        getLocalPlugins();
    }

    const isCanBeAdded = () => {
        return localPluginCreateDto.url.endsWith('.csproj') && localPluginCreateDto.name.length > 0
    }

    return <div className="local-plugins-page-container">
        <div className="left-panel">
            {/*<SearchPanel search={search} setSearch={setSearch} setSelectedFilter={setSelectedFilter}/>*/}
            <Input value={search} icon='search' placeholder='Search...'
                   onChange={(event, data) => setSearch(data.value)}/>
            <NavLink className="link" exact to='/plugins'>{t(keys.lefPanel.dashboard.plugins)}</NavLink>
            <NavLink className="link" exact to='/local-plugins'>{t(keys.lefPanel.dashboard.localPlugin)}</NavLink>
            <NavLink className="link" exact to='/add-plugin'>{t(keys.lefPanel.dashboard.addPlugin)}</NavLink>
        </div>
        <div className="local-plugins-page-container--content">
            {/*<div className="header">Add your own local plugin</div>*/}
            <div className="local-plugins-page-container--content--area">
                <div className="local-plugins-page-container--content--col">
                    <TextInput id={'name'} value={localPluginCreateDto.name} onChange={value => {
                        updateValue('name', value)
                    }} placeholder={'Plugin name'}/>
                    <TextInput id={'pluginPath'} value={localPluginCreateDto.url} onChange={value => {
                        updateValue('url', value)
                    }} placeholder={'Path to .csproj file'} /*onClick={clickHandler}*//>
                </div>
                <div className="local-plugins-page-container--content--col">
                    <Button circular className={'add-btn'} content={'Add'} onClick={addHandler}
                            disabled={!isCanBeAdded()}/>
                </div>
            </div>

            {/*<input type="file" accept={'.csproj'} ref={ref} hidden={false}*/}
            {/*    // onChange={value => {updateValue('path', value.target.value)}}*/}
            {/*       onChange={onChangeHandler}/>*/}



            <Scrollbars autoHide>
                <div className="local-plugin-item-container">
                    {localPlugins && localPlugins.map((local, index) => {
                        return <div key={index} className={classNames("local-plugin-item", {
                            "local-plugin-item--disabled": !local.isEnabled,
                        })}>
                            <div className="header">{local.pluginName}</div>

                            <div className="action">
                                <Button className="compile" fluid toggle={local.isAutoCompile}
                                        active={local.isAutoCompile}
                                        onClick={() => onAutoCompileHandler(local.id, !local.isAutoCompile)}>
                                    Auto Compile
                                </Button>
                            </div>

                            <div className="action">
                                <Button.Group>
                                    <Button onClick={() => toggleHandler(local.id, !local.isEnabled)}
                                            toggle
                                            active={local.isEnabled}>{local.isEnabled ? 'Enabled' : 'Disabled'}</Button>
                                    <Button primary={true}
                                            onClick={() => ForceCompilePlugin(local.id)}>Update</Button>
                                    <Button negative onClick={() => deleteHandler(local.id)}>Delete</Button>
                                </Button.Group>
                            </div>


                        </div>
                    })}
                </div>
            </Scrollbars>
        </div>
    </div>
}

export default LocalPluginsPage;
