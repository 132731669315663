import React, {useEffect, useState} from "react";
import {Button, Dimmer, Icon, Loader, Segment, Table} from "semantic-ui-react";
import useScripts, {IPlugin} from "../Hooks/useScripts";
import PluginEditModal from "./PluginCreateModal";

export type FileInfo = {
    name: string
    size: number
    fileId: number
}

const ScriptUploadView = () => {
    const [isPluginCreate, setPluginCreate] = useState(false);
    const [isPluginEdit, setPluginEdit] = useState(undefined as (IPlugin | undefined));

    const {GetScripts, isLoading, MessagePanel, UpdateScript, DeleteScript, DownloadFile} = useScripts();
    const [plugins, setPlugins] = useState([] as IPlugin[]);

    const fetch = async () => {
        const newPlugins = await GetScripts();
        if (newPlugins) {
            setPlugins(newPlugins)
        }
    }
    //
    // useEffect(() => {
    //     fetch()
    // }, [])

    useEffect(() => {
        if (!isPluginCreate || !isPluginEdit) {
            fetch()
        }
    }, [isPluginCreate, isPluginEdit])


    const deletePluginHandler = async (id: number) => {
        await DeleteScript(id);
        await fetch();
    }

    const downloadPluginHandler = async (id: number, name: string) => {
        await DownloadFile(id, name);
    }

    const editPluginHandler = (plugin: IPlugin) => {
        setPluginEdit(plugin);
    }

    return (
        <>
            <MessagePanel showOnlyError={true}/>
            <Segment>
                <Dimmer active={isLoading}>
                    <Loader/>
                </Dimmer>
                <Table compact selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>Код</Table.HeaderCell>
                            <Table.HeaderCell>Имя</Table.HeaderCell>
                            <Table.HeaderCell>Автор</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Описание</Table.HeaderCell>
                            {/*<Table.HeaderCell width={1}>Признак актуальности</Table.HeaderCell>*/}
                            <Table.HeaderCell>Действие</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {plugins && plugins.map && plugins.map((plugin: any, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{plugin.id}</Table.Cell>
                                    <Table.Cell>{plugin.name}</Table.Cell>
                                    <Table.Cell>{plugin.author}</Table.Cell>
                                    <Table.Cell>{plugin.shortDescription}</Table.Cell>
                                    {/*<Table.Cell>{plugin.deleted}</Table.Cell>*/}
                                    <Table.Cell>
                                        <Button floated={"right"} circular color={"red"} icon='delete'
                                                onClick={() => deletePluginHandler(plugin.id)}
                                        />
                                        <Button floated={"right"} circular color={"yellow"} icon='settings'
                                                tooltip='Settings'
                                                onClick={() => editPluginHandler(plugin)}
                                        />
                                        <Button floated={"right"} circular color={"brown"} icon='download'
                                                tooltip='Download file'
                                                onClick={() => downloadPluginHandler(plugin.id, plugin.name)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                <Button
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    primary
                                    size='small'
                                    onClick={() => setPluginCreate(true)}
                                >
                                    <Icon name='user'/> Добавить плагин
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Segment>
            <PluginEditModal isCreate={true} open={isPluginCreate} setOpen={setPluginCreate}/>
            <PluginEditModal isCreate={false} open={!!isPluginEdit} setOpen={setPluginEdit} pluginData={isPluginEdit}/>
        </>
    )
}

export default ScriptUploadView;
