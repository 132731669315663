import {Button, Dimmer, Dropdown, Header, Icon, Loader, Modal, Pagination, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import React, {useState} from "react";
import {LogFilters} from "../Hooks/useLogs";

export type DropDownOption = {
    key: string;
    text: string;
    value: string;
}

export type FiltersOptions = {
    "logLevels": Array<DropDownOption>,
    "plugins": Array<DropDownOption>,
    "users": Array<DropDownOption>,
}

export type ILogsViewProps = {
    onDescriptionClick: (logObject: any) => void,
    onPageChange: (page: number) => void,
    onAccept: (selectedFilters: LogFilters) => void,
    onClear: () => void,
    onDeleteAllLogs: () => void,
    logs: Array<any>,
    logsCount: number,
    filters: LogFilters,
    filterOptions: FiltersOptions,
    LogMessagePanel: any,
    isLoading: boolean
}

const LogsView = ({onDescriptionClick, onPageChange, logs, logsCount, LogMessagePanel, isLoading, filterOptions, onAccept, onClear, onDeleteAllLogs}: ILogsViewProps) => {
    const pageSize = 25;

    const pageChange = (a: any, b: any) => {
        onPageChange(b.activePage);
    }

    const cutString = (string: string, length: number = 50) => {
        if (!string)
            return '';
        return string.length > length ?
            string.substring(0, length - 3) + "..." :
            string;
    }

    const isNegative = (log: any) => {
        return log.level === 4 || log.level === 5
    }
    const isWarning = (log: any) => {
        return log.level === 3
    }

    const levels = ['Trace', 'Debug', 'Information', 'Warning', 'Error', 'Critical', 'None']

    const getLogName = (logLevel: number) => {
        return levels[logLevel]
    }

    const [selectedFilters, setSelectedFilters] = useState({logLevels: [], plugins: [], users: []} as LogFilters)

    const onClearHandler = () => {
        setSelectedFilters({logLevels: [], plugins: [], users: []});
        onClear();
    }

    const getFilters = () => {
        const filters = [
            {name: 'Levels', options: filterOptions.logLevels, key: 'logLevels'},
            {name: 'Authors', options: filterOptions.users, key: 'users'},
            {name: 'Plugins', options: filterOptions.plugins, key: 'plugins'},
        ]

        const getValues = (key: string) => {
            return selectedFilters[key];
        }

        return filters.map((value, index) => {
            return <Dropdown key={index} placeholder={value.name} scrolling multiple selection search clearable
                             options={value.options} loading={isLoading} disabled={isLoading}
                             onChange={(event, data) => {
                                 setSelectedFilters((prevState: any) => {
                                     return {...prevState, [value.key]: data.value}
                                 })
                             }}
                             value={getValues(value.key)}
                             onClose={_ => {
                                 onAccept(selectedFilters)
                             }}
                             fluid
            />
        });
    }
    const [open, setOpen] = React.useState(false)
    return (
        <>
            <LogMessagePanel showOnlyError={true}/>
            <Pagination defaultActivePage={1} totalPages={Math.ceil(logsCount / pageSize)}
                        onPageChange={pageChange}/>
            <div className="filter-panel">
                {getFilters()}
            </div>
            <div className="filter-panel">
                <Button.Group>
                    <Button basic positive={true} content={'Применить фильтры'} loading={isLoading}
                            onClick={() => onAccept(selectedFilters)}/>
                    <Button basic primary content={'Очистить фильтры'} loading={isLoading} onClick={onClearHandler}/>
                    {/*<Button basic color='red' content={'Очистить базу от логов'} loading={isLoading}*/}
                    {/*        onClick={onDeleteAllLogs}/>*/}
                    <Modal
                        basic
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        size='small'
                        trigger={<Button basic color='red' content={'Очистить базу от логов'} loading={isLoading}
                            /*onClick={onDeleteAllLogs}*//>}
                    >
                        <Header icon>
                            <Icon name='archive'/>
                            Удалить все сообщения из базы
                        </Header>
                        <Modal.Content>
                            <p>
                                Вы уверены, что хотите удалить все логи из базы? Их нельзя будет восстановить в
                                дальнейшем
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                <Icon name='remove'/> Нет
                            </Button>
                            <Button color='green' inverted onClick={() => {
                                setOpen(false)
                                onDeleteAllLogs();
                            }}>
                                <Icon name='checkmark'/> да
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Button.Group>
            </div>
            <Segment>
                <Dimmer active={isLoading}>
                    <Loader/>
                </Dimmer>
                <Table selectable celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell width={'2'}>Time</Table.HeaderCell>
                            <Table.HeaderCell>Level</Table.HeaderCell>
                            <Table.HeaderCell>Author</Table.HeaderCell>
                            <Table.HeaderCell>Plugin</Table.HeaderCell>
                            <Table.HeaderCell textAlign={"center"}>Message</Table.HeaderCell>
                            {/*<Table.HeaderCell textAlign={"center"}>Message</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell>Action</Table.HeaderCell>*/}
                        </Table.Row>

                    </Table.Header>
                    <Table.Body>

                        {logs && logs.map && logs.map((log: any, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell negative={isNegative(log)}
                                                warning={isWarning(log)}>{log.id}</Table.Cell>
                                    <Table.Cell negative={isNegative(log)}
                                                warning={isWarning(log)}>{moment(log.logTime).format('L LT')}</Table.Cell>
                                    <Table.Cell negative={isNegative(log)}
                                                warning={isWarning(log)}>{getLogName(log.level)}</Table.Cell>
                                    <Table.Cell negative={isNegative(log)}
                                                warning={isWarning(log)}>{log.user}</Table.Cell>
                                    <Table.Cell negative={isNegative(log)}
                                                warning={isWarning(log)}>{log.assembly}</Table.Cell>
                                    <Table.Cell negative={isNegative(log)} warning={isWarning(log)}
                                                className={'log-message'}
                                                onClick={() => onDescriptionClick(log)}>{cutString(log.message, 250)}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </Segment>
            <Pagination defaultActivePage={1} totalPages={Math.ceil(logsCount / pageSize)}
                        onPageChange={pageChange}/>
        </>
    )
}

export default LogsView;
