import React, {useEffect, useState} from "react";
// @ts-ignore
import Expand from 'react-expand-animated';

export type IDropdownOption = {
    id: number;
    text: string;
}

export type IProps = {
    text?: string
    items: IDropdownOption[],
    onSelect?: (value: IDropdownOption) => void;
    hideSelected?: boolean;
    children?: any;
    dontSelectedFirstItemOnDefault?: boolean;
}

const Dropdown = ({text = '', items, onSelect, hideSelected, children, dontSelectedFirstItemOnDefault}: IProps) => {
    const [isOpen, setOpen] = useState(false)
    const [selected, setSelected] = useState((dontSelectedFirstItemOnDefault ? undefined : items[0]) as IDropdownOption | undefined)
    const transitions = ["opacity", "background"];

    useEffect(() => {
        if (onSelect && selected) {
            onSelect(selected);
        }
    }, [selected])

    const selectHandler = (selected: IDropdownOption) => {
        setSelected(selected);

        setOpen(false)
    }

    return <div className="dropdown">
        <div className="dropdown-btn" onClick={() => setOpen(!isOpen)}>
            {hideSelected ? (children ? children : text) : selected ? selected.text : (children ? children : text)}
        </div>

        <div className="dropdown-content">
            <Expand
                open={isOpen}
                duration={50}
                transitions={transitions}
            >
                {items.map((value: IDropdownOption) => {
                    return <div className="dropdown-item" onClick={() => selectHandler(value)}
                                key={value.id}>
                        <div className="dropdown-item-content">
                            <div className="text">{value.text}</div>
                            <div className="icon">
                                &#10006;
                            </div>
                        </div>
                    </div>
                })}
            </Expand>
        </div>
    </div>
}

export default Dropdown;
