import React from "react";
import {useTranslation} from "react-i18next";
import keys from "../../translations/keys";
import Image from "react-image-webp";
import classNames from "classnames";

const PaymentsList = () => {
    const {t} = useTranslation();
    const prefix = '/logos/'
    const images = [
        {img: 'brs.png', webp: 'rsb.webp'},
        {img: 'alfa.png', webp: 'alfabank.webp'},
        // 'beeline.png',
        // 'card3.jpg',
        // 'evroset.png',
        // {img: 'logo-iomoney.png', webp: 'iomoney.webp'},
        {img: 'logo-sbp.png', webp: 'sbp.webp'},
        // 'mc.png',
        {img: 'postrf_1.jpg', webp: 'russianpost.webp'},
        // 'mts.png',
        {img: 'psb-paymaster.png', webp: 'psb.webp'},
        {img: 'qiwi.png', webp: 'qiwi.webp'},
        // 'visa.png',
        // 'vtb.png',
        {img: 'vtb.png', webp: 'vtb.webp'},
        // {img: 'wm.png', webp: 'wm.png'},
        {img: 'paypal.png', webp: 'paypal.webp'},
        {img: 'webmoney.png', webp: 'webmoney.webp'},
    ]
    return <div className="payments-list">
        {/*<div className="payments-list__title">*/}
        {/*    {t(keys.acceptPayments)}*/}
        {/*</div>*/}
        <div className="payments-list__content">
            {images.map((imageUrl: { img: string, webp: string } | string, index: number) => {
                const providerCls = classNames('provider',
                    {'provider--webp': typeof imageUrl !== "string" && imageUrl?.webp}
                )
                return <div className={providerCls}>
                    <div className="wrapper">
                        {/*    <div className="container">*/}
                        {/*        <div className="imgage">*/}
                        {(typeof imageUrl !== "string" && imageUrl?.webp) ?
                            <Image src={prefix + imageUrl.img} webp={prefix + imageUrl.webp}/> :
                            <img
                                src={prefix + imageUrl} alt={'payment'}/>
                        }
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            })}
            <div className="provider provider--webp">
                <a href="https://info.paymaster.ru/" className={'wrapper'}>
                    <img
                        src="/logos/paymaster_logo.png"
                        title="Приём оплаты на сайте картами" alt={'Прием электронных платежей'}/>
                </a>
            </div>
            <div className="provider provider--webp">
                <a href="//freekassa.ru/" target="_blank" rel="noopener noreferrer" className={'wrapper'}>
                    <img src="/logos/free_kassa.png" title="Прием платежей" alt={'Прием платежей'}/>
                </a>
            </div>

        </div>
    </div>
}

export default PaymentsList;
