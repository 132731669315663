import {useParams} from "react-router-dom";

export type IResetPasswordErrorPage = {

}

const ResetPasswordErrorPage = ({}: IResetPasswordErrorPage) => {
    return <div>Something go wrong</div>
}

export default ResetPasswordErrorPage;
