import React from "react";
import BasicTopNavBar from "../Components/BasicTopNavBar";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import keys from "../translations/keys";
import Image from "react-image-webp";

const AboutPage = () => {
    const {t} = useTranslation();
    return (
        <div className="about-page">
            <BasicTopNavBar show={true} headerText={t(keys.aboutUs.title)}/>
            <svg className="gradient" width="844" height="843" viewBox="0 0 844 843" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="843.5" cy="-0.5" r="843.5" fill="url(#paint0_radial)"/>
                <defs>
                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(843.5 -0.50001) rotate(90) scale(704.551)">
                        <stop stopColor="#096BFF"/>
                        <stop offset="1" stopColor="#4200FF" stopOpacity="0"/>
                    </radialGradient>
                </defs>
            </svg>
            {/*<img src="/pudge.png" alt="" className="pudge"/>*/}
            <Image src='/pudge.png' webp='/pudge.webp' className='pudge'/>
            {/*<img src="/blueShit.png" alt="" className="blue"/>*/}
            <Image src='/blueShit.png' webp='/blueShit.webp' className='blue'/>
            <div className="text">
                {t(keys.aboutUs.text)}
            </div>
            {/*<div className="back-to-main-btn">*/}
                <NavLink className="back-to-main-btn" exact to='/'>{t(keys.aboutUs.back)}</NavLink>
            {/*</div>*/}
        </div>
    )
}

export default AboutPage;
