import React, {useEffect, useMemo} from "react";
import {AuthService} from "../Services/AuthService";
import {ApiService} from "../Services/ApiService";
import {User} from "oidc-client-ts";
import {useHistory} from "react-router-dom";
import axios from "axios";
import decode from "jwt-decode";
import _ from "underscore";

export enum IAuthTypeStatus {
    Pending,
    LoggedIn,
    LoggedOut,
    InError
}

export type IAuthType = {
    status: IAuthTypeStatus,
    error: Error | null,
    user: User | null,
    apiService: any,
    authService: AuthService | null,
    LoginAction: Function,
    LogoutAction: Function,
    claims: string[],
}

export const AuthContext = React.createContext({
    status: IAuthTypeStatus.Pending,
    error: null,
    user: null,
    apiService: null,
    authService: null,
    LoginAction: Function,
    LogoutAction: Function,
    claims: [],
    notifications: {
        visited: false,
        data: [],
        setVisited: undefined
    },

} as IAuthType);

const AuthProvider = ({children}: any) => {
    const authService = useMemo(() => new AuthService(), [])
    const apiService = useMemo(() => new ApiService(authService), [authService])
    const history = useHistory();
    // const [claims, setClaims] = useState([] as string[])
    const LoginAction = async (): Promise<User | null> => {
        await authService.login();
        const user = await authService.getUser();
        if (!!user) {
            setState((prevState: IAuthType) => {
                return {...prevState, user: user, status: IAuthTypeStatus.LoggedIn}
            })
        }
        return user
    }

    const LogoutAction = async () => {
        await authService.logout();
        // setState((prevState: IAuthType) => {
        //     return {...prevState, user: null, status: IAuthTypeStatus.LoggedOut}
        // })
    }


    const [state, setState] = React.useState({
        status: IAuthTypeStatus.Pending,
        error: null,
        user: null,
        LoginAction,
        LogoutAction,
        authService,
        apiService,
        claims: [],

    } as IAuthType)
    useEffect(() => {
        const saveAccessToken = (user: User | null) => {
            const accessToken = user?.access_token;

            if (accessToken) {
                axios.defaults.headers = {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + accessToken
                };
                const {claim}: { claim: string | string[] } = decode(accessToken);
                let clms: string[] = []
                if (Array.isArray(claim)) {
                    clms = (claim);
                } else {
                    clms = ([claim]);
                }
                setState((prevState: IAuthType) => {
                    return {...prevState, user: user, status: IAuthTypeStatus.LoggedIn, claims: clms}
                })
            } else {
                setState((prevState: IAuthType) => {
                    return {
                        ...prevState,
                        user: null,
                        status: IAuthTypeStatus.InError,
                        error: new Error('Cant get access token')
                    }
                })
            }
        }
        const fetch = async () => {
            if (!authService)
                return;
            const token = authService.getToken();
            const isExpired = authService.isExpired(token);
            if (token) {
                if (isExpired) {
                    await authService.userManager.signinSilent();
                }
            } else {
                // await authService.userManager.clearStaleState();
                // await authService.userManager.removeUser();
            }
            if (state.status === IAuthTypeStatus.LoggedIn) {
                return;
            }

            const location = history.location.pathname;
            if (location === '/' || location === '/about' || location === '/agreement' || location === '/privacy' || location === '/contacts'
                || location === '/authorize' || location.startsWith('/reset-password') || location === '/reset-password-error'|| location === '/forgot-password'|| location === '/reset-password-confirm') {
                const user = await authService.getUser();
                if (!!user) {
                    saveAccessToken(user);
                } else {
                    setState((prevState: IAuthType) => {
                        return {...prevState, user: null, status: IAuthTypeStatus.LoggedOut}
                    })
                }
                return;
            }

            const user = await authService.getUser();
            if (!!user) {
                saveAccessToken(user);
            } else {
                const newUser = await LoginAction();
                if (!!newUser) {
                    setState((prevState: IAuthType) => {
                        return {...prevState, user: null, status: IAuthTypeStatus.LoggedOut}
                    })
                }
            }
        }
        fetch();
    }, [history.location.pathname])

    return (
        <AuthContext.Provider value={state}>
            {state.status === IAuthTypeStatus.Pending ? (
                'Loading...'
            ) : state.status === IAuthTypeStatus.InError ? (
                <div>
                    Oh no
                    <div>
                        <pre>{_.isString(state.error) ? state.error : state?.error?.message}</pre>
                    </div>
                </div>
            ) : (
                children
            )}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
