import React, {useEffect, useState} from "react";
import i18n from "../i18n";
import {Languages} from "../Components/translations/types";
import moment from "moment";

export type ILanguageContext = {
    lang: number;
    changeLanguage: (lang: number) => void;
}

const i18nLang = i18n.language;
const lang: any = i18nLang.toUpperCase().substring(0, 2);

const initialValue = {
    lang: lang ? Languages[lang] : Languages.RU,
    // changeLanguage: undefined
} as ILanguageContext
// const targetLangCode = Languages[lang].toLowerCase();
moment.locale('en');
export const LanguageContext = React.createContext(initialValue);


const LanguageProvider = ({children}: any) => {
    const changeLanguage = (lang: number) => {
        const targetLangCode = Languages[lang].toLowerCase();
        i18n.changeLanguage(targetLangCode);
        moment.locale(targetLangCode);
        setContext(prevState => {
            return {...prevState, lang}
        })
    }
    const [context, setContext] = useState({
        ...initialValue,
        changeLanguage: changeLanguage
    });

    return (
        <LanguageContext.Provider value={context}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider;
