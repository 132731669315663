import React, {useEffect, useState} from 'react'
import {Button, Form, Message, Modal, Radio, TextArea} from 'semantic-ui-react'
import useAuth from "../Hooks/useAuth";
import DropZone from "../Components/DropZone";
import useScripts, {CreateScriptDto, IPlugin, PluginType} from "../Hooks/useScripts";
import {FormProps} from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {FileInfo} from "./ScriptUploadView";

export type IPluginEditModal = {
    open: boolean
    setOpen: any
    isCreate: boolean
    pluginData?: IPlugin;
}

const PluginEditModal = ({open, setOpen, isCreate, pluginData}: IPluginEditModal) => {
    const {state, user} = useAuth();

    const [pluginType, setPluginType] = useState(1 as PluginType);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    // const [dllInfo, setDllInfo] = useState({} as FileInfo);
    const [activeTab, setActiveTab] = useState(0);
    const [formData, setFormData] = useState({Author: user?.profile.nickname} as CreateScriptDto)

    const {CreateScript, isLoading, MessagePanel, UpdateScript} = useScripts();

    useEffect(() => {
        if (pluginData) {
            setFormData({
                // FileId: 0,
                Name: pluginData.pluginName,
                Author: pluginData.authorName,
                FullDescription: pluginData.description,
                PluginType: pluginData.type,
                GithubUrl: pluginData.path,
            })
            setPluginType(pluginData.type)
        }
    }, [pluginData])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, data: FormProps) => {

    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setFormData(prevState => {
            return {...prevState, [data.name]: data.value}
        })
    }

    // useEffect(() => {
    //     setDllInfo({} as FileInfo);
    // }, [activeTab])


    // const fileLoadedHandler = (fileData: FileInfo) => {
    //     setDllInfo(fileData)
    // }


    const save = async () => {
        // if (dllInfo && Object.keys(dllInfo).length > 0){
        //     if (dllInfo.name !== formData.Name+'.dll') {
        //         setError(true);
        //         return
        //     }
        // }
        setError(false);
        try {
            // if (dllInfo.fileId && isCreate) {
                if (isCreate) {
                    await CreateScript({
                        ...formData,
                        PluginType: pluginType,
                        // FileId: dllInfo.fileId,
                    });
                    setSuccess(true);
                }
            // }
            if (!isCreate) {
                await UpdateScript({
                    ...formData,
                    PluginType: pluginType,
                    // FileId: dllInfo.fileId,
                    Id: pluginData?.id ? pluginData?.id : 0
                });
                setSuccess(true);
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size={"mini"}
        >
            <Modal.Header>{isCreate ? 'Добавление нового плагина' : 'Редактирование плагина'}</Modal.Header>
            <Modal.Content scrolling>
                <MessagePanel showOnlyError={true}/>
                <Modal.Description>
                    <div className="dropZone">
                        <Form success={success} error={error} onSubmit={handleSubmit}>
                            <Form.Input label='Имя' name='Name' required value={formData.Name} onChange={onChange}/>
                            <Form.Input label='Автор' name={'Author'} required value={formData.Author}
                                        onChange={onChange}/>
                            <Form.Input label='GithubUrl' name={'GithubUrl'} required value={formData.GithubUrl}
                                        onChange={onChange}/>
                            <Form.Field
                                name={'FullDescription'}
                                control={TextArea}
                                required
                                label='Описание'
                                placeholder='Расскажи нам о своем плагине...'
                                value={formData.FullDescription}
                                onChange={onChange}
                            />
                            <Form.Group inline>
                                <label>Тип плагина</label>
                                <Form.Field
                                    control={Radio}
                                    label='Герой'
                                    value={PluginType.Hero}
                                    checked={pluginType === PluginType.Hero}
                                    onChange={(e: any, {value}: { value: PluginType }) => setPluginType(value)}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Фича'
                                    value={PluginType.Utility}
                                    checked={pluginType === PluginType.Utility}
                                    onChange={(e: any, {value}: { value: PluginType }) => setPluginType(value)}
                                />
                                <Form.Field
                                    control={Radio}
                                    label='Библиотека'
                                    value={PluginType.Library}
                                    checked={pluginType === PluginType.Library}
                                    onChange={(e: any, {value}: { value: PluginType }) => setPluginType(value)}
                                />
                            </Form.Group>
                            {/*<DropZone onFileLoaded={fileLoadedHandler}/>*/}
                            <Message
                                success
                                header='Форму завершена'
                                content="Загрузка плагина прошла успешно"
                            />
                            <Message
                                error
                                header='Ошибка'
                                content="Имя плагина и имя файла не совпадают"
                            />
                            {/*<Button className={'btn-submit'} disabled={!dllInfo.fileId && false}>Сохранить</Button>*/}
                        </Form>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' loading={isLoading} onClick={() => setOpen(false)}>
                    Назад
                </Button>
                <Button
                    content={isCreate ? 'Создать' : 'Обновить'}
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => save()}
                    positive
                    loading={isLoading}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default PluginEditModal;
