import React from "react";
import classNames from "classnames";

export type IProps = {
    onChange: (value: string) => void;
    placeholder: string;
    id: string;
    value: string | undefined
    onClick?: any
    error?: boolean;
    type?: string;
}

const TextInput = ({onChange, placeholder, id, value, onClick, error, type}: IProps) => {
    const onChangeHandler = (event: any) => {
        onChange(event.target.value);
    }

    const className = classNames('form__group', 'field', {
        'form__group--error': error
    })

    return <div className={className}>
        <input type={type || "input" } className="form__field" onChange={onChangeHandler} placeholder={placeholder} name={id}
               id={id} value={value} onDoubleClick={() => onClick && onClick()}/>
        <label htmlFor={id} className="form__label">{placeholder}</label>
    </div>
}

export default TextInput;
