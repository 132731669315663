import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TextInput from "../Components/TextInput";
import {useState} from "react";
import {Button} from "semantic-ui-react";
import useResetPassword from "../Hooks/useResetPassword";
import keys from "../translations/keys";

export type IResetPasswordPage = {}

const ResetPasswordPage = ({}: IResetPasswordPage) => {
    const {t} = useTranslation();
    const href =window.location.href;
    const searchText = 'reset-password/';
    const pos = href.indexOf(searchText);
    const urlData = href.substring(pos + searchText.length);

    const {setPassword, password, passwordConfirm, setPasswordConfirm, changePassword, isLoading, email, setEmail}
        = useResetPassword(urlData);

    return <div className="reset-password-page">
        <div className="reset-password-page__title">Reset password page</div>
        <div className="reset-password-page__content">
            <TextInput type={'email'} id={'email'} onChange={value => setEmail(value)}
                       value={email} placeholder={t(keys.account.email)}/>
            <TextInput type={'password'} id={'password'} onChange={value => setPassword(value)}
                       value={password} placeholder={t(keys.resetPassword.resetPage.newPassword)}/>
            <TextInput type={'password'} id={'passwordConfirm'} onChange={value => setPasswordConfirm(value)}
                       value={passwordConfirm} placeholder={t(keys.resetPassword.resetPage.newPasswordConfirm)}/>
        </div>
        <div className="reset-password-page__action">
            <Button loading={isLoading} primary={true} fluid={true} onClick={changePassword}>{t(keys.resetPassword.resetPage.setNewPassword)}</Button>
        </div>
    </div>
}

export default ResetPasswordPage;
