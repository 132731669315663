import React, {useContext, useEffect} from "react";
import {AuthContext} from "../Context/RootContext";
import {LanguageContext} from "../Context/LanguageContext";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {IPriceInfo} from "./usePayment";
import {ITogglePlugin} from "./useScripts";

export type IMainData = {
    email?: string;
    isEmailConfirmed: boolean;
    totalGamePlayed: number;
    subEnd: Date | null;
    crasherEnd: Date | null;
    isDiscordLinked: boolean;
    isVkConfirmed: boolean;
    isOldCostumer: boolean;
    prices: IPriceInfo[];
    pluginToggleGetDtos: ITogglePlugin[];
}

export enum LoadMainDataEnum {
    prices,
    main,
    plugins
}

const useMainData = () => {
    const state = React.useContext(AuthContext)
    const {error, isLoading, BeginLoading} = useProgress();

    const loadData = async (type: LoadMainDataEnum) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `MainPage/${type}`);
            return data.data;
        })
    }

    return {
        loadData
    }
}

export default useMainData

