import keys from '../keys';

const translations = {
    [keys.regularUser.title]: 'Congratulations! You are our regular customer. The old prices are valid for you!',
    [keys.regularUser.description]: 'In order not to lose this status, you need to replenish your subscription in advance, before it ends',
    [keys.invalidTime]: 'The time is set incorrectly on your computer, for security reasons, you need to set automatic time synchronization in the operating system settings.',
    [keys.confirmEmailPage.title]: 'Confirm your email!',
    [keys.confirmEmailPage.description]: 'In order to use the site, you must confirm your email. Please check the email that you specified during registration, an account confirmation email has been sent to you, if the email has not arrived, then please check the Spam folder in your mail service, or request the email again.',
    [keys.confirmEmailPage.urEmail]: 'Your mail',
    [keys.account.langSelector]: 'Select language',
    [keys.account.activateToken]: 'Type token',
    [keys.account.acceptToken]: 'Activate',
    [keys.account.changePassword]: 'Change password',
    [keys.account.resetHwid]: 'Reset HWID',
    [keys.account.email]: 'Email',
    [keys.account.confirmEmail]: 'Confirm email',
    [keys.account.confirmedEmail]: 'Email confirmed',
    [keys.account.newEmail]: 'Type new email',
    [keys.account.changeEmail]: 'Change email',

    [keys.welcomeHi]: 'Hey, ',
    [keys.welcomeInfo]: 'On this page you can view all the information, download the client and run it.',
    [keys.cancel]: '2Отмена',
    [keys.save]: '2Сохранить',
    [keys.showOnMap]: '2Показать на карте',
    [keys.download]: 'Download',
    [keys.downloadInfo]: 'Here you can download the client and launch it',
    [keys.lefPanel.dashboard.title]: 'Dashboard',
    [keys.lefPanel.dashboard.main]: 'Main',
    [keys.lefPanel.dashboard.forum]: 'Forum',
    [keys.lefPanel.dashboard.buy]: 'Purchase',
    [keys.lefPanel.dashboard.plugins]: 'Plugins',
    [keys.lefPanel.dashboard.addPlugin]: 'Add plugin',
    [keys.lefPanel.dashboard.localPlugin]: 'Local plugins',
    [keys.lefPanel.dashboard.cloudConfig]: 'Config',
    [keys.lefPanel.dashboard.account]: 'Account',
    [keys.lefPanel.admin.title]: 'Admin',
    [keys.lefPanel.admin.users]: 'Users',
    [keys.lefPanel.admin.logs]: 'Logs',
    [keys.lefPanel.admin.loadLibrary]: 'Load libraries',
    [keys.lefPanel.admin.pluginManager]: 'Plugins Manager',
    [keys.lefPanel.admin.oldMenu]: 'Old menu',
    [keys.lefPanel.admin.statistics]: 'Statistics',
    [keys.lefPanel.hide]: 'Hide panel',

    [keys.mainPage.downloadClient]: 'Download client',
    [keys.mainPage.downloadingResources]: 'Please wait, resources are being updated, the start button will be available only after they are fully loaded',
    [keys.mainPage.downloadingClient]: 'Please wait, downloading client',
    [keys.mainPage.lastPluginsUpdate]: 'Last updates',
    [keys.mainPage.protection]: 'Protection 99.9%',
    [keys.mainPage.showAll]: 'Show all',
    [keys.mainPage.startDivineClient]: 'Start divine client',
    [keys.mainPage.startDota]: 'Start dota',
    [keys.mainPage.startingClient]: 'Client is starting...',
    [keys.mainPage.systemStatus]: 'System status',
    [keys.mainPage.uptime]: 'Uptime 99.9%*',
    [keys.mainPage.working]: 'Working',
    [keys.mainPage.paymentBlock.amount]: 'Amount',
    [keys.mainPage.paymentBlock.discount]: 'Discount',
    [keys.mainPage.paymentBlock.freeKassa.btn]: 'Free-kassa',
    [keys.mainPage.paymentBlock.freeKassa.tooltip]: 'Working only for CIS users',
    [keys.mainPage.paymentBlock.sellix.btn]: 'Sellix',
    [keys.mainPage.paymentBlock.sellix.tooltip]: 'This payment works worldwide.\nAfter payment, you get a Serial/Token code, you can activate on Main page.\nThe price on this payment is 20% more!',
    [keys.mainPage.paymentBlock.paymaster.btn]: 'PayMaster',
    [keys.mainPage.paymentBlock.paymaster.tooltip]: 'Visa, MasterCard, Qiwi, WebMoney, Alipay, WeChatPay, etc',
    [keys.mainPage.paymentBlock.payDigi.btn]: 'Digiseller',
    [keys.mainPage.paymentBlock.payDigi.tooltip]: 'Alipay, Btc, Wmz, Qiwi, Steam skins',
    [keys.mainPage.paymentBlock.primePayments.btn]: 'PrimePayments',
    [keys.mainPage.paymentBlock.primePayments.tooltip]: 'Card for ru and some eu',
    [keys.mainPage.paymentBlock.paymax.btn]: 'Vallet',
    [keys.mainPage.paymentBlock.paymax.tooltip]: 'US, EU and any countries except Russian',
    [keys.mainPage.paymentBlock.paypal.btn]: 'Paypal',
    [keys.mainPage.paymentBlock.paypal.tooltip]: 'At this time you need to create ticket on discord send to paypal and then we give code to you.\nThe price on this payment is 20% more!',
    [keys.mainPage.paymentBlock.reseller.btn]: 'Resellers',
    [keys.mainPage.paymentBlock.reseller.tooltip]: 'If none of the presented payment methods is suitable for you, please contact our resellers in discord. ivan2301#1488',
    [keys.mainPage.paymentBlock.chineseReseller.btn]: 'Chinese Reseller',
    [keys.mainPage.paymentBlock.chineseReseller.tooltip]: 'Click on the link to join the group chat [Holy DOTA2 exchange black]',

    [keys.mainPage.paymentBlock.title]: 'Purchase a subscription',
    [keys.mainPage.transactionBlock.title]: 'Transaction history',
    [keys.mainPage.subLifeTimeBlock.title]: 'Subscribe lifetime',
    [keys.mainPage.paymentBlock.tooltip.title]: 'What does a subscription give you?',
    [keys.mainPage.paymentBlock.tooltip.row1]: 'Access to all the functionality',
    [keys.mainPage.paymentBlock.tooltip.row2]: 'Quick help with support',
    [keys.mainPage.paymentBlock.tooltip.row3]: 'Allows you to play more than 1 game per week',
    [keys.mainPage.paymentBlock.tooltip.extra]: 'The subscription is activated within 5 minutes after payment',

    // ------------ othersBlock -----------
    [keys.mainPage.othersBlock.activeToken.title]: 'Activate token',
    [keys.mainPage.othersBlock.activeToken.description]: 'Insert the subscription activation token in the field',
    [keys.mainPage.othersBlock.activeToken.btnText]: 'Activate',

    [keys.mainPage.othersBlock.hwid.title]: 'Reset  PC  HWID',
    [keys.mainPage.othersBlock.hwid.description]: 'You can reset the HWID every 8 hours',
    [keys.mainPage.othersBlock.hwid.btnText]: 'Reset',

    [keys.mainPage.othersBlock.discord.title]: 'Discord',
    [keys.mainPage.othersBlock.discord.description]: 'You can link your Discord account and get 3 free sub days',
    [keys.mainPage.othersBlock.discord.btnText]: 'Log in with your discord account',

    [keys.mainPage.othersBlock.vk.title]: 'VKontakte',
    [keys.mainPage.othersBlock.vk.description]: 'You can link your VK account and get 3 free sub days',
    [keys.mainPage.othersBlock.vk.btnText]: 'Log in with your VK account',

    [keys.mainPage.othersBlock.password.title]: 'Change Password',
    [keys.mainPage.othersBlock.password.description]: 'You can change the password of your account',
    [keys.mainPage.othersBlock.password.btnText]: 'Type new password',

    [keys.mainPage.othersBlock.email.description]: 'To use our product, you need to confirm your email address',

    [keys.mainPage.userInfoBlock.userId.title]: 'Your ID for contacting technical support',
    [keys.mainPage.userInfoBlock.email.title]: 'Your E-mail',
    [keys.mainPage.userInfoBlock.playedGamesCount.title]: 'Played games with Divine',
    [keys.mainPage.userInfoBlock.subTime.title]: 'Remaining subscription time',
    [keys.mainPage.userInfoBlock.subTime.infinity]: 'Unlimited',
    [keys.mainPage.userInfoBlock.crashTime.title]: 'Remaining Crasher subscription time',

    [keys.buySubBlock.welcomeText]: 'You are making the right choice by joining our DIVINE community in Dota 2',
    [keys.buySubBlock.saleText]: 'Your cumulative discount',
    [keys.buySubBlock.colOne.title]: 'cumulative discount for regular customers',
    [keys.buySubBlock.colOne.description]: 'Discount for regular customers up to 15 percent, as well as various and other discounts on purchases!',
    [keys.buySubBlock.colTwo.title]: 'get it instantly',
    [keys.buySubBlock.colTwo.description]: 'Payment via QIWI, MasterCard, Visa, MIR, Apple Pay, WebMoney, as well as various cryptocurrencies and Steam skins',

    [keys.forum.create]: 'Create',
    [keys.forum.hidden]: 'Hidden',
    [keys.forum.delete]: 'Delete',
    [keys.forum.createPage]: 'Create page',
    [keys.forum.createTheme]: 'Create theme',
    [keys.forum.sendMessage]: 'Send message',
    [keys.forum.created]: 'Created',
    [keys.forum.updated]: 'Updated',
    [keys.forum.startedBy]: 'Started by',
    [keys.forum.comment_base.comment]: '{{count}} comment',
    [keys.forum.comment_base.comment_plural]: '{{count}} comments',


    // [keys.daysBefore]: `$t(day.day, {"count": {{days}} }) ago`,
    // [keys.day.day]: '{{count}} day',
    // [keys.day.day_0]: '{{count}} days',
    // [keys.day.day_1]: '{{count}} day',
    // [keys.day.day_2]: '{{count}} days',
    // [keys.day.day_3]: '{{count}} days',
    // [keys.day.day_4]: '{{count}} days',
    // [keys.day.day_5]: '{{count}} days',
    // [keys.day.day_plural]: '{{count}} days',

    [keys.daysBefore]: `$t(day.day, {"count": {{days}} }) ago`,
    [keys.day.day]: '{{count}} day',
    [keys.day.day_plural]: '{{count}} days',

    [keys.pluginsPage.developer]: 'Developer',
    [keys.pluginsPage.enablePlugin]: 'Enable',
    [keys.pluginsPage.disablePlugin]: 'Disable',
    [keys.pluginsPage.info]: 'Information',
    [keys.pluginsPage.installed]: 'Installed',
    [keys.pluginsPage.deletePlugin]: 'Delete',
    [keys.pluginsPage.installPlugin]: 'Install',
    [keys.pluginsPage.overview]: 'Overview',
    [keys.pluginsPage.close]: 'Close',
    [keys.pluginsPage.description]: 'Description',
    [keys.pluginsPage.video_overview]: 'Video review of the plugin',

    [keys.resetPassword.resetPage.title]: 'Reset password page',
    [keys.resetPassword.resetPage.setNewPassword]: 'Set a new password',
    [keys.resetPassword.resetPage.newPassword]: 'New password',
    [keys.resetPassword.resetPage.newPasswordConfirm]: 'New password confirm',

    [keys.resetPassword.confirmPage.title]: 'A confirmation has been sent to your email address',
    [keys.resetPassword.confirmPage.text1]: 'A message has been sent to your mailbox, contains a link to confirm password reset. Please follow the link for completion of the operation.',
    [keys.resetPassword.confirmPage.text2]: 'If the email doesn\'t arrive within 15 minutes, check the Spam folder. If the email suddenly got into this folder, open the email, click "Not spam" and click on the confirmation link. If the message is not in the folder "Spam", try subscribing again. You may have entered the wrong address.',

    [keys.resetPassword.forgotPasswordPage.title]: 'Forgot password?',
    [keys.resetPassword.forgotPasswordPage.reset]: 'Reset password',

    [keys.welcomePage.tryCheat]: 'Try the best Dota 2 scripts, plugins and feel its power right now!',
    [keys.welcomePage.dontOpenMouths]: 'Don\'t open your mouths - the admiral is on board!',
    [keys.welcomePage.registerNow]: 'Register now',
    [keys.welcomePage.registration]: 'Registration',
    [keys.welcomePage.optimized]: 'Our scripts and plugins is the most optimized!',
    [keys.welcomePage.littleFps]: 'So forget about the little fps with scripts!',
    [keys.welcomePage.cool]: 'Scripting is an incredible advantage! You can see all the movement of enemies, highlighting of stuns and targeted abilities! In a word - Cool.',
    [keys.welcomePage.login]: 'Login',
    [keys.welcomePage.signUp]: 'Log in to the system',
    [keys.welcomePage.aboutUs]: 'About us',
    [keys.welcomePage.agreement]: 'Agreement',
    [keys.welcomePage.privacy]: 'Privacy',
    [keys.welcomePage.contacts]: 'Contacts',
    [keys.welcomePage.feature]: 'Features',

    [keys.welcomePage.top.stream]: 'Live stream',

    [keys.welcomePage.carousel.one.title]: 'A PROVEN AND CONFIDENT LEADER IN THIS FIELD!',
    [keys.welcomePage.carousel.one.text]: 'PERHAPS THE BEST PRIVATE SCRIPTS AND PLUGINS FOR THE GAME',
    [keys.welcomePage.carousel.one.action]: 'Learn more',
    [keys.welcomePage.carousel.one.action2]: 'Purchase',

    [keys.welcomePage.carousel.two.title]: 'A NEW ERA IN THE FIELD OF SCRIPTS FOR Dota 2!',
    [keys.welcomePage.carousel.two.text]: 'JOIN OUR SERVER',
    [keys.welcomePage.carousel.two.text2]: 'GET GIFTS!',
    [keys.welcomePage.carousel.two.action]: 'About Us',
    [keys.welcomePage.carousel.two.action2]: 'Join',

    [keys.welcomePage.carousel.three.title]: 'THE FUTURE IS CLOSER THAN YOU THINK! © Divine',
    [keys.welcomePage.carousel.three.text]: 'FORGET ABOUT LOSING WITH ECO SYSTEM',
    [keys.welcomePage.carousel.three.text2]: `'THE WORLD'S FIRST PLUGIN WITH A PERSONAL CORE TO WORK WITH! 0% ERRORS'`,
    [keys.welcomePage.carousel.three.action]: 'Watch the review',
    [keys.welcomePage.carousel.three.action2]: 'Purchase',

    [keys.welcomePage.carousel.four.title]: 'A NEW ERA IN THE FIELD OF SCRIPTS FOR Dota 2!',
    [keys.welcomePage.carousel.four.text]: 'JOIN',
    [keys.welcomePage.carousel.four.text2]: 'COMMUNITY',
    [keys.welcomePage.carousel.four.text3]: 'IN VKONTAKTE AND GET GIFTS!',
    [keys.welcomePage.carousel.four.action]: 'About us',
    [keys.welcomePage.carousel.four.action2]: 'Join',

    [keys.welcomePage.carousel.five.title]: 'Winning with Divine is Easy!',
    [keys.welcomePage.carousel.five.text]: 'NEW INTELLIGENT',
    [keys.welcomePage.carousel.five.text2]: 'HEX LION IS NOT A PROBLEM!',
    [keys.welcomePage.carousel.five.action]: 'Watch the review',
    [keys.welcomePage.carousel.five.action2]: 'Purchase',

    [keys.welcomePage.welcomeText.title]: 'Welcome to Divine!',
    [keys.welcomePage.welcomeText.text]: 'Enter the Dota 2 scripts game zone - Together with Divine!',

    [keys.welcomePage.description]: 'Our unique scripts opens up incredible opportunities \n'+
' to raise your rating! Functions camera distance, \n' +
'highlighting enemies through the fog of war, automatic uport \n'+
' and finishing off creeps are only a small part of our functions. \n'+
'We provide new users with a trial period \n'+
' for subscribing to our communities and linking VK or Discord.',

    [keys.welcomePage.halloween.text]: 'Discount weekend',
    [keys.welcomePage.halloween.text2]: '25% discount on subscription',
    [keys.welcomePage.halloween.text3]: 'From October 31st to November 2nd',
    [keys.welcomePage.halloween.action]: 'Go to play',

    [keys.welcomePage.crasher.text]: 'The new crasher is already available',
    [keys.welcomePage.crasher.text2]: 'Available by a separate subscription for each user',
    [keys.welcomePage.crasher.text3]: 'Watch video',
    [keys.welcomePage.crasher.text4]: 'Please use with caution. Non-refundable (considering the fix from Valve).',
    [keys.welcomePage.crasher.action]: 'Purchase',

    [keys.welcomePage.youtube.title]: 'Watch the reviews right now!',
    [keys.welcomePage.youtube.text]: 'Immerse yourself in your favorite game with Divine - Dota 2!',
    [keys.welcomePage.youtube.action]: 'Watch Divine reviews',

    [keys.welcomePage.buy.title]: 'Get a subscription at juicy prices!',
    [keys.welcomePage.buy.action]: 'MAKE A SUCCESSFUL PURCHASE',
    [keys.welcomePage.buy.price]: 'Price',
    [keys.welcomePage.buy.client]: 'Client',

    [keys.welcomePage.buy.item1.pre1]: 'SALES LEADER',
    [keys.welcomePage.buy.item1.pre2]: 'IN THE PRODUCT OF THE MONTH SEGMENT',
    [keys.welcomePage.buy.item1.name ]: '7 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item1.price]: '$ 3.01',

    [keys.welcomePage.buy.item2.pre1]: 'SALES LEADER',
    [keys.welcomePage.buy.item2.pre2]: 'IN THE PRODUCT OF THE YEAR SEGMENT',
    [keys.welcomePage.buy.item2.name ]: 'SUBSCRIPTION FOR 14 DAYS',
    [keys.welcomePage.buy.item2.price]: '$ 5.32',

    [keys.welcomePage.buy.item3.pre1]: 'IN THE PRODUCT OF THE YEAR SEGMENT',
    [keys.welcomePage.buy.item3.pre2]: 'POPULAR CHOICE',
    [keys.welcomePage.buy.item3.name ]: '30 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item3.price]: '$ 10.08',

    [keys.welcomePage.buy.item4.pre1]: 'BEST PRICE',
    [keys.welcomePage.buy.item4.pre2]: 'THE BEST CHOICE TO BUY',
    [keys.welcomePage.buy.item4.name ]: '90 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item4.price]: '$ 28.80',

    [keys.welcomePage.trialGamesBlock.title]: 'Win and up MMR',
    [keys.welcomePage.trialGamesBlock.title2]: 'Together with DIVINE!',
    [keys.welcomePage.trialGamesBlock.description]: 'DOTA 2 SCRIPTS',
    [keys.welcomePage.trialGamesBlock.tryToFree]: 'Try it for free',
    [keys.welcomePage.trialGamesBlock.modal.title]: 'GETTING A TRIAL PERIOD',
    [keys.welcomePage.trialGamesBlock.modal.title2]: 'We provide a trial period to all our users.',
    [keys.welcomePage.trialGamesBlock.modal.p]: '1. We provide a trial period to all our users who have subscribed to our social networks ',
    [keys.welcomePage.trialGamesBlock.modal.p1]: 'or',
    [keys.welcomePage.trialGamesBlock.modal.p2]: '2. Register on the website and create your account',
    [keys.welcomePage.trialGamesBlock.modal.p22]: 'in your personal account.',
    [keys.welcomePage.trialGamesBlock.modal.p3]: '3. Download and run the client.',
    [keys.welcomePage.trialGamesBlock.modal.p4]: '4. You can enter the game.',
    [keys.welcomePage.trialGamesBlock.modal.action]: 'Subscribe to VKontakte group',

    [keys.cookies.text]: 'We use cookies for fast and convenient operation of the site. By continuing to use the site, you accept',
    [keys.cookies.text2]: 'personal data processing conditions',

    [keys.aboutUs.title]: 'about us',
    [keys.aboutUs.text]: 'We are the former writers of the famous Ensage project for Dota 2, at the moment we are making our own project called Divine, with the same cool scripts and functionality!',
    [keys.aboutUs.back]: 'Go back to the main page!',

    [keys.notifications.payment]: 'The payment was completed successfully. Added ',
    [keys.notifications.added]: 'Added ',
    [keys.notifications.successDone]: 'Payment successful',
    [keys.notifications.processingDescription]: 'The payment is usually received within 5 minutes',
    [keys.notifications.processing]: 'We process payments ',
    [keys.notifications.plugin_updated]: 'Plugin {{name}} just was updated',
    [keys.notifications.client_update_ready]: 'Update for client is ready to download',

    [keys.subInfo.title]: 'Purchase a subscription',
    [keys.subInfo.block.buySub]: 'Purchase a subscription',
    [keys.subInfo.block.subTime.subTime]: 'Subscription for {{count}} day',
    [keys.subInfo.block.subTime.day_plural]: 'Subscription for {{count}} days',
    [keys.subInfo.block.feature1]: 'Access to all functionality',
    [keys.subInfo.block.feature2]: 'Quick help in support',
    [keys.subInfo.block.feature3]: 'Getting an undeniable advantage',
    [keys.subInfo.block.feature4]: 'Unlimited number of games per day',
    [keys.subInfo.extra]: 'or get 1 free game in rating mode',
    [keys.subInfo.extra_color]: 'for registration',

    [keys.configPage.author]: 'Author',
    [keys.configPage.all.title]: 'Users configs',
    [keys.configPage.all.install]: 'Install',
    [keys.configPage.myConfigs.enabled]: 'Enabled',
    [keys.configPage.myConfigs.disabled]: 'Disabled',
    [keys.configPage.myConfigs.title]: 'Your configs',
    [keys.acceptPayments]: 'We accept payments in',
};

export default translations;
