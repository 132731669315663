import React, {useEffect} from "react";
import useAuth from "../Hooks/useAuth";
import { NavLink } from "react-router-dom";
import Image from "react-image-webp";

const LoginPage = () => {
    const {state, isAuthenticated} = useAuth()
    const onLogin = async () => {
        await state.LoginAction();
    };

    const logout = async () => {
        await state.LogoutAction();
    }

    useEffect(() => {
        // setTimeout(() => {
        //     if (!isAuthenticated)
        //         onLogin();
        // }, 500)
    }, [])

    return (
        <>
            <div className="login-page">
                <div className="login-navigation">
                    <a href="https://divine.wtf/" className="link">Main</a>
                    <a href="https://divine.wtf/about" className="link">About us</a>
                    <a href="https://vk.com/divinecheat" className="link">VK</a>
                    <a href="https://discord.gg/YvFuC6H" className="link">Discord</a>
                    <Image className="icon" src='/divine-main.png' webp='/divine-main.webp' alt={'DIVINE'}/>
                    <div className="label">DIVINE</div>
                </div>
                <div className="login-view">
                    <div className="centered">
                        <div className="title">Access denied!</div>
                        <a className="border-animation" href="#">
                            <div className="border-animation__inner" onClick={onLogin}>Click to authorize</div>
                        </a>
                        {/*<NavLink className="btn" to="/">back to main page</NavLink>*/}
                        {/*<div className="btn">back</div>*/}
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <div className="login-page">
            <div className="login-navigation">
                <a href="https://divine.wtf/" className="link">Main</a>
                <a href="https://divine.wtf/about" className="link">About us</a>
                <a href="https://vk.com/divinecheat" className="link">VK</a>
                <a href="https://discord.gg/YvFuC6H" className="link">Discord</a>
                <Image className="icon" src='/divine-main.png' webp='/divine-main.webp' alt={'DIVINE'}/>
                <div className="label">DIVINE</div>
            </div>
            <div className="login-view">
                <div className="centered">
                    <div className="title">Sign in</div>
                    <input className="input input-rounded"/>
                    <input type='password' className="input input-rounded"/>
                    <div className="btn">Login</div>
                    <div className="btn tooltip">New? Sign up</div>

                </div>
            </div>
            {/*<div className="reg-view">*/}
            {/*    /!*<img className="img" src="/timber.png"/>*!/*/}
            {/*</div>*/}
            {/*<div className="gradient">*/}
            {/*<div className="gradient">*/}

            {/*</div>*/}
            <svg className="gradient" width="1687" height="733" viewBox="0 0 1687 733" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <circle cx="843.5" cy="843.5" r="843.5" fill="url(#paint0_radial)"/>
                <defs>
                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(843.5 843.5) rotate(90) scale(704.551)">
                        <stop offset="0.296875" stopColor="#FF0909"/>
                        <stop offset="1" stopColor="#2400FF" stopOpacity="0"/>
                    </radialGradient>
                </defs>
            </svg>

            {/*</div>*/}
        </div>
    )
}

export default LoginPage;
