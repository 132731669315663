import keys from '../keys';

const translations = {
    [keys.regularUser.title]: 'Поздравляю! Вы наш постоянный пользователь. Для вас действуют старые цены!',
    [keys.regularUser.description]: 'Чтобы не потерять данный статус необходимо пополнять подписку заблаговременно, до ее окончания',
    [keys.confirmEmailPage.title]: 'Подтвердите почту!',
    [keys.confirmEmailPage.title]: 'Подтвердите почту!',
    [keys.confirmEmailPage.urEmail]: 'Ваша почта',
    [keys.confirmEmailPage.description]: 'Что бы пользоваться сайтом вы должны подтвердить почту.\n' +
    '                    Пожалуйста проверьте почту которую вы указывали при регистрации, вам высланно письмо с\n' +
    '                    подтверждением аккаунта,\n' +
    '                    если письмо не пришло то пожалуйста проверьте папку “Спам” в вашем почтовом сервисе, или запросите\n' +
    '                    письмо повторно.',
    [keys.invalidTime]: 'На вашем компьютере неверно установлено время, в целях безопасности необходимо установить автоматическую синхронизацию времени внастройках операционной системы.',
    [keys.account.langSelector]: 'Выбор языка',
    [keys.account.activateToken]: 'Ввести ключ',
    [keys.account.acceptToken]: 'Активировать ключ',
    [keys.account.changePassword]: 'Изменить пароль',
    [keys.account.resetHwid]: 'Сбросить HWID',
    [keys.account.email]: 'Почта',
    [keys.account.newEmail]: 'Введите новую почту',
    [keys.account.confirmEmail]: 'Подтвердить почту',
    [keys.account.confirmedEmail]: 'Почта подтвердждена',
    [keys.account.changeEmail]: 'Сменить почту',
    [keys.cancel]: '1Отмена',
    [keys.save]: '1Сохранить',
    [keys.showOnMap]: '1Показать на карте',
    [keys.download]: 'Скачать',
    [keys.downloadInfo]: 'Здесь вы можете скачать клиент, и совершить его запуск',
    [keys.welcomeHi]: 'Привет, ',
    [keys.welcomeInfo]: 'На данной странице вы можете посмотреть всю информацию, скачать клиент и запустить его.',
    [keys.lefPanel.dashboard.title]: 'Панель',
    [keys.lefPanel.dashboard.main]: 'Главная',
    [keys.lefPanel.dashboard.forum]: 'Форум',
    [keys.lefPanel.dashboard.buy]: 'Купить',
    [keys.lefPanel.dashboard.plugins]: 'Плагины',
    [keys.lefPanel.dashboard.addPlugin]: 'Добавить плагин',
    [keys.lefPanel.dashboard.localPlugin]: 'Локальные плагины',
    [keys.lefPanel.dashboard.cloudConfig]: 'Конфиг',
    [keys.lefPanel.dashboard.account]: 'Аккаунт',
    [keys.lefPanel.admin.title]: 'Админ',
    [keys.lefPanel.admin.users]: 'Пользователи',
    [keys.lefPanel.admin.logs]: 'Логи',
    [keys.lefPanel.admin.loadLibrary]: 'Загрузку библиотек',
    [keys.lefPanel.admin.pluginManager]: 'Управление плагинами',
    [keys.lefPanel.admin.oldMenu]: 'Старое админ меню',
    [keys.lefPanel.admin.statistics]: 'Статистика',
    [keys.lefPanel.hide]: 'Свернуть панель',

    [keys.mainPage.downloadClient]: 'Скачать клиент',
    [keys.mainPage.downloadingResources]: 'Пожалуйста ожидайте, идёт обновление ресурсов, кнопка запуска будет доступна только после их полной загрузки',
    [keys.mainPage.downloadingClient]: 'Пожалуйста ожидайте, идет скачивание клиента',
    [keys.mainPage.lastPluginsUpdate]: 'Последние обновления',
    [keys.mainPage.protection]: 'Защита 99.9%',
    [keys.mainPage.showAll]: 'Показать все',
    [keys.mainPage.startDivineClient]: 'Запустить клиент',
    [keys.mainPage.startDota]: 'Запустить доту',
    [keys.mainPage.startingClient]: 'Клиент запускается...',
    [keys.mainPage.systemStatus]: 'Статус системы',
    [keys.mainPage.uptime]: 'Аптайм 99.9%*',
    [keys.mainPage.working]: 'Работает',
    [keys.mainPage.paymentBlock.amount]: 'Сумма',
    [keys.mainPage.paymentBlock.discount]: 'Скидка',
    [keys.mainPage.paymentBlock.freeKassa.btn]: 'Free-kassa',
    [keys.mainPage.paymentBlock.freeKassa.tooltip]: 'Работает только для снг пользователей',
    [keys.mainPage.paymentBlock.paymaster.btn]: 'PayMaster',
    [keys.mainPage.paymentBlock.paymaster.tooltip]: 'Visa, MasterCard, Qiwi, WebMoney, Alipay, WeChatPay, etc',
    [keys.mainPage.paymentBlock.payDigi.btn]: 'Digiseller',
    [keys.mainPage.paymentBlock.payDigi.tooltip]: 'Alipay, Btc, Вебмани, Киви, скины из стима',
    [keys.mainPage.paymentBlock.paymax.btn]: 'Vallet',
    [keys.mainPage.paymentBlock.paymax.tooltip]: 'США, ЕС и любых стран кроме России',
    [keys.mainPage.paymentBlock.paypal.btn]: 'Paypal',
    [keys.mainPage.paymentBlock.paypal.tooltip]: 'Сейчас для оплаты через пейпал необходимо отправить мне деньги и я отправлю вам код',
    [keys.mainPage.paymentBlock.reseller.btn]: 'Реселлеры',
    [keys.mainPage.paymentBlock.reseller.tooltip]: 'Если ни одна из представленных способов оплаты вам не подходит, свяжитесь с нашими реселлерами в дискорде. ivan2301#1488',
    [keys.mainPage.paymentBlock.chineseReseller.btn]: 'Китайский реселлер',
    [keys.mainPage.paymentBlock.chineseReseller.tooltip]: 'Переход на его сайт',
    [keys.mainPage.paymentBlock.title]: 'Покупка подписки',
    [keys.mainPage.paymentBlock.tooltip.title]: 'Что дает подписка',
    [keys.mainPage.paymentBlock.tooltip.row1]: 'Доступ ко всему функционалу',
    [keys.mainPage.paymentBlock.tooltip.row2]: 'Быстрая помощь в поддержке',
    [keys.mainPage.paymentBlock.tooltip.row3]: 'Позволяет играть больше чем 1 игру в неделю',
    [keys.mainPage.paymentBlock.tooltip.extra]: 'Подписка активируется в течении 5 минут после оплаты',
    // ------------ othersBlock -----------
    [keys.mainPage.othersBlock.activeToken.title]: 'Применить токен',
    [keys.mainPage.othersBlock.activeToken.description]: 'Вставьте в поле токен для активации подписки',
    [keys.mainPage.othersBlock.activeToken.btnText]: 'Активировать',

    [keys.mainPage.othersBlock.hwid.title]: 'Сбросить PC HWID',
    [keys.mainPage.othersBlock.hwid.description]: 'Вы можете сбрасывать HWID каждые 8 часов',
    [keys.mainPage.othersBlock.hwid.btnText]: 'Сбросить',

    [keys.mainPage.othersBlock.discord.title]: 'Дискорд',
    [keys.mainPage.othersBlock.discord.description]: 'Вы можете привязать свою учетную запись Discord и получить 3 дня подписки',
    [keys.mainPage.othersBlock.discord.btnText]: 'Авторизоваться в дискорде у нашего бота',

    [keys.mainPage.othersBlock.vk.title]: 'ВКонтакте',
    [keys.mainPage.othersBlock.vk.description]: 'Вы можете привязать свою учетную запись Vk и получить 3 дня подписки',
    [keys.mainPage.othersBlock.vk.btnText]: 'Авторизоваться в Вконтакте у нашего бота',

    [keys.mainPage.othersBlock.password.title]: 'Изменить пароль',
    [keys.mainPage.othersBlock.password.description]: 'Вы можете изменить пароль от своей учётной записи',
    [keys.mainPage.othersBlock.password.btnText]: 'Введите новый пароль',

    [keys.mainPage.othersBlock.email.description]: 'Для использования нашего продукта необходимо подтвердить почту',

    // ------- userInfoBlock -----------
    [keys.mainPage.userInfoBlock.userId.title]: 'Твой ID для обращения в тех.поддержку',
    [keys.mainPage.userInfoBlock.email.title]: 'Твой E-mail',
    [keys.mainPage.userInfoBlock.playedGamesCount.title]: 'Сыграно игр с Divine',
    [keys.mainPage.userInfoBlock.subTime.title]: 'Оставшееся время подписки',
    [keys.mainPage.userInfoBlock.subTime.infinity]: 'Неограниченная',
    [keys.mainPage.userInfoBlock.crashTime.title]: 'Оставшееся время подписки на крашер',


    [keys.buySubBlock.welcomeText]: 'Вы делаете верный выбор, присоединяясь к нашему сообществу DIVINE в Dota 2',
    [keys.buySubBlock.saleText]: 'Ваша накопительная скидка',
    [keys.buySubBlock.colOne.title]: 'накопительная скидка постоянным',
    [keys.buySubBlock.colOne.description]: 'Скидка постоянным покупателям до 15 процентов, а так же различные и другие скидки на покупки!',
    [keys.buySubBlock.colTwo.title]: 'получай мгновенно',
    [keys.buySubBlock.colTwo.description]: 'Оплата через QIWI, MasterCard, Visa, МИР, Apple Pay, WebMoney, а так же различные криптовалюты и скины Steam',


    [keys.mainPage.transactionBlock.title]: 'История покупок',
    [keys.mainPage.subLifeTimeBlock.title]: 'Осталось подписки',

    [keys.forum.create]: 'Создать',
    [keys.forum.hidden]: 'Скрыт',
    [keys.forum.delete]: 'Удалить',
    [keys.forum.createPage]: 'Создать страницу',
    [keys.forum.createTheme]: 'Создать тему',
    [keys.forum.sendMessage]: 'Отправить сообщение',
    [keys.forum.created]: 'Создан',
    [keys.forum.updated]: 'Обновлен',
    [keys.forum.startedBy]: 'Тема создана',

    [keys.forum.comment_base.comment_0]: '{{count}} комментарий',
    [keys.forum.comment_base.comment_1]: '{{count}} комментария',
    [keys.forum.comment_base.comment_2]: '{{count}} комментариев',

    [keys.forum.vehicle.vehicle_0]: '{{count}} транспортное средство',
    [keys.forum.vehicle.vehicle_1]: '{{count}} транспортных средства',
    [keys.forum.vehicle.vehicle_2]: '{{count}} транспортных средств',

    [keys.daysBefore]: `$t(day.day, {"count": {{days}} }) назад`,
    [keys.day.day_0]: '{{count}} день',
    [keys.day.day_1]: '{{count}} дня',
    [keys.day.day_2]: '{{count}} дней',
    [keys.notifications.payment]: 'Оплата успешно завершена. Зачислено ',
    [keys.notifications.added]: 'Зачислено ',
    [keys.notifications.processing]: 'Обрабатываем платеж ',
    [keys.notifications.successDone]: 'Платеж успешно завершен',
    [keys.notifications.processingDescription]: 'Платеж обычно поступает в течении 5 минут',
    [keys.notifications.plugin_updated]: 'Плагин {{name}} получил обновление',
    [keys.notifications.client_update_ready]: 'Обновления для лоадера доступно для загрузки',

    // [keys.day.day_3]: '{{count}} 3',
    // [keys.day.day_4]: '{{count}} 4',
    // [keys.day.day_5]: '{{count}} 5',
    // [keys.day.day_plural]: '{{count}} 6',


    [keys.pluginsPage.developer]: 'Разработчик',
    [keys.pluginsPage.enablePlugin]: 'Вкл',
    [keys.pluginsPage.disablePlugin]: 'Выкл',
    [keys.pluginsPage.info]: 'Информация',
    [keys.pluginsPage.installed]: 'Установлен',
    [keys.pluginsPage.deletePlugin]: 'Удалить',
    [keys.pluginsPage.installPlugin]: 'Установить',
    [keys.pluginsPage.overview]: 'Обзор',
    [keys.pluginsPage.close]: 'Закрыть',
    [keys.pluginsPage.description]: 'Описание',
    [keys.pluginsPage.video_overview]: 'Видео обзор плагина',


    [keys.resetPassword.resetPage.title]: 'Страница сброса пароля',
    [keys.resetPassword.resetPage.setNewPassword]: 'Установить новый пароль',
    [keys.resetPassword.resetPage.newPassword]: 'Новый пароль',
    [keys.resetPassword.resetPage.newPasswordConfirm]: 'Новый пароль подтверждение',


    [keys.resetPassword.confirmPage.title]: 'На вашу почту отправлено подтверждение',
    [keys.resetPassword.confirmPage.text1]: 'На Ваш почтовый ящик отправлено сообщение, содержащее ссылку для подтверждения сброса пароля. Пожалуйста, перейдите по ссылке для завершения операции.',
    [keys.resetPassword.confirmPage.text2]: 'Если письмо не пришло в течение 15 минут, проверьте папку «Спам». Если письмо вдруг попало в эту папку, откройте письмо, нажмите кнопку «Не спам» и перейдите по ссылке подтверждения. Если же письма нет и в папке «Спам», попробуйте подписаться ещё раз. Возможно, вы ошиблись при вводе адреса.',

    [keys.resetPassword.forgotPasswordPage.title]: 'Забыли пароль?',
    [keys.resetPassword.forgotPasswordPage.reset]: 'Сбросить пароль',


    [keys.welcomePage.tryCheat]: 'Попробуйте лучший чит для игры Dota 2. Оцените его силу прямо сейчас!',
    [keys.welcomePage.dontOpenMouths]: 'А ну рты не разевать - адмирал на борту!',
    [keys.welcomePage.registerNow]: 'Прямо сейчас!',
    [keys.welcomePage.registration]: 'Регистрация',
    [keys.welcomePage.optimized]: 'Наше программное обеспечение не использует устаревшие LUA, JS, которые во много раз медленнее, чем C#, мы делаем все для вас и вашей легкой игры!',
    [keys.welcomePage.littleFps]: '',
    [keys.welcomePage.cool]: 'Профессионалы работают во всех областях проекта, поэтому вы получаете качественный чит',
    [keys.welcomePage.login]: 'Войти',
    [keys.welcomePage.signUp]: 'Войти в систему',
    [keys.welcomePage.aboutUs]: 'О нас',
    [keys.welcomePage.agreement]: 'Соглашение',
    [keys.welcomePage.privacy]: 'Конфиденциальность',
    [keys.welcomePage.contacts]: 'Конктакты',
    [keys.welcomePage.feature]: 'Особенности',

    [keys.welcomePage.top.stream]: 'Прямая трансляция',

    [keys.welcomePage.carousel.one.title]: 'ПРОВЕРЕННЫЙ И УВЕРЕННЫЙ ЛИДЕР В ДАННОЙ СФЕРЕ!',
    [keys.welcomePage.carousel.one.text]: 'ПОЖАЛУЙ ЛУЧШИЙ ПРИВАТНЫЙ ЧИТ ДЛЯ ИГРЫ',
    [keys.welcomePage.carousel.one.action]: 'Подробнее',
    [keys.welcomePage.carousel.one.action2]: 'Приобрести',

    [keys.welcomePage.carousel.two.title]: 'НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!',
    [keys.welcomePage.carousel.two.text]: 'ВСТУПАЙ В НАШ СЕРВЕР',
    [keys.welcomePage.carousel.two.text2]: 'ПОЛУЧАЙ ПОДАРКИ!',
    [keys.welcomePage.carousel.two.action]: 'О Нас',
    [keys.welcomePage.carousel.two.action2]: 'Вступить',

    [keys.welcomePage.carousel.three.title]: 'БУДУЩЕЕ УЖЕ БЛИЖЕ ЧЕМ ТЕБЕ КАЖЕТСЯ! © Divine',
    [keys.welcomePage.carousel.three.text]: 'ЗАБУДЬ О ПРОИГРЫШАХ С ЭКО СИСТЕМОЙ',
    [keys.welcomePage.carousel.three.text2]: 'ПЕРВЫЙ В МИРЕ ПЛАГИН С ЛИЧНЫМ ЯДРОМ ДЛЯ РАБОТЫ! 0% ОШИБОК',
    [keys.welcomePage.carousel.three.action]: 'Смотреть обзор',
    [keys.welcomePage.carousel.three.action2]: 'Приобрести',

    [keys.welcomePage.carousel.four.title]: 'НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!',
    [keys.welcomePage.carousel.four.text]: 'ВСТУПАЙ В',
    [keys.welcomePage.carousel.four.text2]: 'СООБЩЕСТВО',
    [keys.welcomePage.carousel.four.text3]: 'В ВКОНТАКТЕ И ПОЛУЧАЙ ПОДАРКИ!',
    [keys.welcomePage.carousel.four.action]: 'О нас',
    [keys.welcomePage.carousel.four.action2]: 'Вступить',

    [keys.welcomePage.carousel.five.title]: 'Побеждать с Divine Легко!',
    [keys.welcomePage.carousel.five.text]: 'НОВЫЙ ИНТЕЛЕКТУАЛЬНЫЙ',
    [keys.welcomePage.carousel.five.text2]: 'HEX lion’а не проблема!',
    [keys.welcomePage.carousel.five.action]: 'Смотреть обзор',
    [keys.welcomePage.carousel.five.action2]: 'Приобрести',

    [keys.welcomePage.welcomeText.title]: 'Добро пожаловать в Divine!',
    [keys.welcomePage.welcomeText.text]: 'Входите в читерскую игровую зону Dota 2 - Вместе с Divine!',

    [keys.welcomePage.description]: 'Наш уникальный чит открывает невероятные возможности\n' +
    'для поднятия вашего рейтинга! Функции отдаление камеры, \n' +
    'подсветка врагов сквозь туман войны, автоматический уворт \n' +
    'и добивание крипов — это лишь малая часть наших функций. \n' +
    'Новым пользователям мы предоставляем пробный период \n' +
    'за подписку на наши сообщества и привязку VK или Discord.',

    [keys.welcomePage.halloween.text]: 'Скидочные выходные',
    [keys.welcomePage.halloween.text2]: 'Скидка 25% на подписку',
    [keys.welcomePage.halloween.text3]: 'С 31 октября по 2 ноября',
    [keys.welcomePage.halloween.action]: 'Перейти к покупке',

    [keys.welcomePage.crasher.text]: 'Новый крашер уже доступен',
    [keys.welcomePage.crasher.text2]: 'Доступен по отдельной подписке для каждого пользователя',
    [keys.welcomePage.crasher.text3]: 'Смотреть видео',
    [keys.welcomePage.crasher.text4]: 'Просим использовать с осторожностью. Возврату не подлежит (учитывая фикс от Valve).',
    [keys.welcomePage.crasher.action]: 'Перейти к покупке',

    [keys.welcomePage.youtube.title]: 'Смотри обзоры уже прямо сейчас!',
    [keys.welcomePage.youtube.text]: 'Погрузитесь в любимую игру с Divine - Dota 2!',
    [keys.welcomePage.youtube.action]: 'Смотреть обзоры Divine',

    [keys.welcomePage.buy.title]: 'Приобретай подписку по сочным ценам!',
    [keys.welcomePage.buy.action]: 'СОВЕРШИТЬ УДАЧНУЮ ПОКУПКУ',
    [keys.welcomePage.buy.price]: 'Цена',
    [keys.welcomePage.buy.client]: 'Клиент',

    [keys.welcomePage.buy.item1.pre1]: 'ЛИДЕР ПРОДАЖ',
    [keys.welcomePage.buy.item1.pre2]: 'В СЕГМЕНТЕ ТОВАР МЕСЯЦА',
    [keys.welcomePage.buy.item1.name]: 'ПОДПИСКА 7 ДНЕЙ',
    [keys.welcomePage.buy.item1.price]: '₽ 180.00',

    [keys.welcomePage.buy.item2.pre1]: 'ЛИДЕР ПРОДАЖ',
    [keys.welcomePage.buy.item2.pre2]: 'В СЕГМЕНТЕ ТОВАР ГОДА',
    [keys.welcomePage.buy.item2.name]: 'ПОДПИСКА 14 ДНЕЙ',
    [keys.welcomePage.buy.item2.price]: '₽ 319.00',

    [keys.welcomePage.buy.item3.pre1]: 'В СЕГМЕНТЕ ТОВАР ГОДА',
    [keys.welcomePage.buy.item3.pre2]: 'ПОПУЛЯРНЫЙ ВЫБОР',
    [keys.welcomePage.buy.item3.name]: 'ПОДПИСКА 30 ДНЕЙ',
    [keys.welcomePage.buy.item3.price]: '₽ 605.00',

    [keys.welcomePage.buy.item4.pre1]: 'ЛУЧШАЯ ЦЕНА',
    [keys.welcomePage.buy.item4.pre2]: 'ЛУЧШИЙ ВЫБОР ДЛЯ ПОКУПКИ',
    [keys.welcomePage.buy.item4.name]: 'ПОДПИСКА 90 ДНЕЙ',
    [keys.welcomePage.buy.item4.price]: '₽ 1728.00',

    [keys.welcomePage.trialGamesBlock.title]: 'Побеждай и апай MMR',
    [keys.welcomePage.trialGamesBlock.title2]: 'Вместе с DIVINE!',
    [keys.welcomePage.trialGamesBlock.description]: 'DOTA 2 CHEATS',
    [keys.welcomePage.trialGamesBlock.tryToFree]: 'Попробовать бесплатно',
    [keys.welcomePage.trialGamesBlock.modal.title]: 'ПОЛУЧЕНИЕ ПРОБНОГО ПЕРИОДА',
    [keys.welcomePage.trialGamesBlock.modal.title2]: 'Мы предоставляем пробный период всем нашим пользователям.',
    [keys.welcomePage.trialGamesBlock.modal.p]: '1. Мы предоставляем пробный период всем нашим пользователям которые подписались на наши социальные сети ',
    [keys.welcomePage.trialGamesBlock.modal.p1]: 'или',
    [keys.welcomePage.trialGamesBlock.modal.p2]: '2. Зарегистрируйтесь на сайте и привижите вашу учётную запись',
    [keys.welcomePage.trialGamesBlock.modal.p22]: 'в личном кабинете.',
    [keys.welcomePage.trialGamesBlock.modal.p3]: '3. Скачайте и запустите клиент.',
    [keys.welcomePage.trialGamesBlock.modal.p4]: '4. Можете заходить в игру.',
    [keys.welcomePage.trialGamesBlock.modal.action]: 'Подписаться на группу ВКонтакте',

    [keys.cookies.text]: 'Мы используем cookies для быстрой и удобной работы сайта. Продолжая пользоваться сайтом, вы принимаете',
    [keys.cookies.text2]: 'условия обработки персональных данных',


    [keys.aboutUs.title]: 'О нас',
    [keys.aboutUs.text]: 'Мы бывшие разработчики знаменитого чита Ensage, делаем все такой же качественный продукт!',
    [keys.aboutUs.back]: 'Вернуться на главную страницу!',

    [keys.subInfo.title]: 'Приобрести подписку',
    [keys.subInfo.block.buySub]: 'Приобрести подписку',
    [keys.subInfo.block.subTime.subTime_0]: 'Подписка на {{count}} день',
    [keys.subInfo.block.subTime.subTime_1]: 'Подписка на {{count}} дня',
    [keys.subInfo.block.subTime.subTime_2]: 'Подписка на {{count}} дней',
    [keys.subInfo.block.feature1]: 'Доступ ко всему функционалу',
    [keys.subInfo.block.feature2]: 'Быстрая помощь в поддержке',
    [keys.subInfo.block.feature3]: 'Получение неоспоримого преимущества',
    [keys.subInfo.block.feature4]: 'Неограниченное колличество игр в день',
    [keys.subInfo.extra]: 'или получить 1 бесплатную игру в рейтинговом режиме',
    [keys.subInfo.extra_color]: 'за регистрацию',

    [keys.configPage.author]: 'Автор',
    [keys.configPage.all.title]: 'Пользовательские конфиги',
    [keys.configPage.all.install]: 'Установить',
    [keys.configPage.myConfigs.enabled]: 'Включено',
    [keys.configPage.myConfigs.disabled]: 'Выключено',
    [keys.configPage.myConfigs.title]: 'Ваши конфиги',
    [keys.acceptPayments]: 'Мы принимает платежи в',
};

export default translations;
