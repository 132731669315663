import i18n from 'i18next';
import LngDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './translations/en/translations';
import translationRu from './translations/ru/translations';
import translationCh from './translations/ch/translations';
import _ from 'underscore';

// the translations
// (tip move them in a JSON file and import them)
// console.log('translationRu', translationRu);
const resources = {
    ru: { translation: translationRu },
    en: { translation: translationEn },
    ch: { translation: translationCh }
};

const options: DetectorOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',

    // cache user language on
    caches: ['cookie', 'localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    //cookieMinutes: 10,
    //cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement
};
const langDetector = new LngDetector(null, options);

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(langDetector)
    .init({
        resources,
        //lng: 'ru',
        fallbackLng: 'en',
        //keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
