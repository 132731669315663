import useForum, {
    IForumCommentDto,
    IForumCommentsGetResponse,
    IForumPageDto,
    IForumThemeDto
} from "../../Hooks/useForum";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment";
import {Scrollbars} from "rc-scrollbars";
import {Icon, Input, Pagination, TextArea, TextAreaProps} from "semantic-ui-react";
import Button from "../../Components/Button";
import ReactMarkdown from 'react-markdown';
import _ from "underscore";
import classNames from "classnames";
import MarkdownEditor from '@uiw/react-markdown-editor';
import {useTranslation} from "react-i18next";
import keys from "../../translations/keys";
import HtmlEditor from "./Components/HtmlEditor";
export type IForumThemeContentProps = {
    theme: IForumThemeDto;
    onMessageCreated: () => void;
    onThemeUpdated: () => void;
}

export type IForumCommentComponentProps = {
    comment: IForumCommentDto;
    onCommentUpdated: () => void;
}

export type IVirtualMessagesProps = {
    comments: IForumCommentDto[];
    totalCount: number;
    loadMoreHandler: () => void;
    onRefreshHandler: () => void;
}

const pageSize = 30;
const ForumCommentComponent = ({comment, onCommentUpdated}: IForumCommentComponentProps) => {
    const {updateComment} = useForum();
    const [editModeObject, setEditMode] = useState(undefined as IForumCommentDto | undefined);

    const updateCommentHandler = async () => {
        if (editModeObject) {
            await updateComment(editModeObject);
            setEditMode(undefined);
            onCommentUpdated();
        }

    }
    const hideCommentHandler = async () => {
        await updateComment({...comment, hidden: !comment.hidden});
        setEditMode(undefined);
        onCommentUpdated();
    }

    const onChangeDescriptionHandler = (data: string) => {
        setEditMode({...editModeObject as any, content: data})
    }

    const cls = classNames('forum_page__theme_content__comment_wrapper',
        {'forum_page__theme_content__comment_wrapper--hidden': comment.hidden}
    )

    return <div className={cls}>
        <div className="comment__top">
            <div className="comment__top__user">{comment.authorName}</div>
            <div className="comment__top__date">{moment(comment.createdTime).fromNow()}</div>
            {comment.hidden && <div className="comment__top__hidden">Hidden</div>}
            <div className="comment__top__actions">
                {comment.canEdit && editModeObject &&
                <Icon name={'save'} size={'large'} link onClick={updateCommentHandler}/>}
                {comment.canEdit && <Icon name={'edit'} size={'large'} link
                                          onClick={() => !_.isUndefined(editModeObject) ? setEditMode(undefined) : setEditMode(comment)}/>}
                {comment.canDelete && <Icon name={'delete'} size={'large'} color={comment.hidden ? 'green' : 'red'} link
                                            onClick={hideCommentHandler}/>}
            </div>
        </div>
        <div className="comment__content">
            {editModeObject ? <HtmlEditor onChange={onChangeDescriptionHandler} value={editModeObject.content}/> :
                <Scrollbars autoHeight={true} autoHeightMax={400} autoHide={false}>
                    <HtmlEditor viewMode value={comment.content}/>
                </Scrollbars>}
        </div>
        {/*<div className="comment__content">*/}
        {/*    {editModeObject ? <TextArea className={'comment__content__input'} value={editModeObject.content}*/}
        {/*                                onChange={(event, data) => onChangeDescriptionHandler(data.value as string)}/> :*/}
        {/*        <Scrollbars autoHeight={true} autoHeightMax={400} autoHide={false}>*/}
        {/*            <ReactMarkdown>{comment.content}</ReactMarkdown>*/}
        {/*        </Scrollbars>}*/}
        {/*</div>*/}
    </div>
}

const ForumThemeContent = ({theme, onMessageCreated, onThemeUpdated}: IForumThemeContentProps) => {
    const {createMessage, getThemeComments, updateTheme, isLoading, hideTheme} = useForum();
    const {t} = useTranslation();
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([] as IForumCommentDto[]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [editModeObject, setEditMode] = useState(undefined as IForumThemeDto | undefined);

    const createMessageHandler = () => {
        createMessage(theme.id, message).then(r => {
            setMessage('');
            loadComments();
            onMessageCreated();
        });
    }

    const updateThemeHandler = async () => {
        if (editModeObject) {
            await updateTheme(editModeObject);
            setEditMode(undefined);
            onThemeUpdated();
        }
    }
    const lockThemeHandler = async () => {
        await updateTheme({...theme, isClosed: !theme.isClosed});
        onThemeUpdated();
    }
    const pinkThemeHandler = async () => {
        await updateTheme({...theme, isPinned: !theme.isPinned});
        onThemeUpdated();
    }

    const loadComments = async () => {
        const comments = await getThemeComments(theme.id, page, pageSize);
        setComments(comments.comments);
        setTotalCount(comments.totalCount);
    };

    // const loadMore = useCallback(async () => {
    //     const newComments = await getThemeComments(theme.id, page + 1, 5);
    //     setComments((comments) => ([...comments, ...newComments.comments]));
    //     setTotalCount(newComments.totalCount);
    //     setPage(page + 1);
    // }, [setComments, setPage, setTotalCount]);

    // const loadMore = async () => {
    //     const newComments = await getThemeComments(theme.id, page, pageSize);
    //     setComments((comments) => ([...comments, ...newComments.comments]));
    //     setTotalCount(newComments.totalCount);
    // }

    useEffect(() => {
        loadComments();
    }, [page]);

    const increasePage = () => {
        setPage(prevState => prevState + 1);
    }
    const resetPage = () => {
        setPage(prevState => 1);
    }

    useEffect(() => {
        setComments([]);
        setTotalCount(0);
        setPage(1);
        // loadMore();
    }, [theme.id])

    const onChangeDescriptionHandler = (data: string) => {
        setEditMode({...editModeObject as any, description: data})
    }

    const hideThemeHandler = async () => {
        await hideTheme(theme.id);
        await loadComments();
    }

    const pageChange = (a: any, b: any) => {
        setPage(b.activePage);
    }

    // const getCommentsText = `${theme.commentsCount} comments`;
    const getCommentsText = t(keys.forum.comment_base.comment, {count: theme.commentsCount});
    // const getCommentsText = t(keys.subInfo.block.subTime.subTime, {count: theme.commentsCount});
    const totalPages = Math.ceil(totalCount / pageSize);
    return <div className="forum_page__theme_content">
        <div className="forum_page__theme_content__wrapper">
            <div className="forum_page__theme_content__actions">
                {theme.canEdit &&
                <Icon name={'pin'} link color={theme.isPinned ? 'orange' : 'grey'} onClick={pinkThemeHandler}/>}
                {theme.canEdit && <Icon name={theme.isClosed ? 'lock' : 'lock open'} link onClick={lockThemeHandler}/>}
                {theme.canEdit && editModeObject && <Icon name={'save'} link onClick={updateThemeHandler}/>}
                {theme.canEdit &&
                <Icon name={'edit'} link
                      onClick={() => !_.isUndefined(editModeObject) ? setEditMode(undefined) : setEditMode(theme)}/>}
                {theme.canDelete && <Icon name={'delete'} link onClick={hideThemeHandler}/>}
            </div>
            <div className="forum_page__theme_content__header">
                <div className="forum_page__theme_content__title__header__author">
                    {theme.authorName}
                </div>
                <div
                    className="forum_page__theme_content__title__header__date">
                    {moment(theme.lastUpdate).format('LTS ll')}
                </div>
            </div>
            <div className="forum_page__theme_content__title">
                {theme.hidden && `[${t(keys.forum.hidden)}] `}{theme.title}
            </div>
            {/*<div className="forum_page__theme_content__description">*/}
            {/*    {!editModeObject ?*/}
            {/*        <ReactMarkdown>{theme.description}</ReactMarkdown> :*/}
            {/*        <TextArea className={'forum_page__theme_content__description__input'}*/}
            {/*                  value={editModeObject.description}*/}
            {/*                  onChange={(event, data) => onChangeDescriptionHandler(data)}/>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className="forum_page__theme_content__description">
                <HtmlEditor onChange={onChangeDescriptionHandler} value={theme.description} viewMode={!editModeObject}/>
                {/*{!editModeObject ?*/}
                {/*    <HtmlEditor value={theme.description} viewMode/> :*/}
                {/*    // <ReactMarkdown>{theme.description}</ReactMarkdown> :*/}
                {/*    <MarkdownEditor*/}
                {/*        value={editModeObject.description}*/}
                {/*        height={300}*/}
                {/*        onChange={(editor: any, data: any, value: string) => {*/}
                {/*            onChangeDescriptionHandler(value);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*}*/}
            </div>
        </div>
        <div className="forum_page__theme_content__wrapper forum_page__comments">
            <div className="forum_page__theme_content__title">
                {getCommentsText}
                {totalPages > 1 && <Pagination defaultActivePage={1} activePage={page} totalPages={totalPages}
                             onPageChange={pageChange}/>}
            </div>
            {!theme.isClosed && <div className="forum_page__theme_content__input__wrapper">
                {/*<TextArea placeholder={'type your comment here'} value={message}*/}
                {/*          onChange={(event, data) => setMessage(data.value!.toString())}*/}
                {/*          className={'forum_page__theme_content__input'}/>*/}
                <HtmlEditor onChange={setMessage} value={message}/>
                {/*<MarkdownEditor*/}
                {/*    value={message}*/}
                {/*    height={150}*/}
                {/*    onChange={(editor: any, data: any, value: string) => {*/}
                {/*        setMessage(value);*/}
                {/*    }}*/}
                {/*/>*/}
                <Button disabled={isLoading || !message} primary className={'forum_page__theme_content__input__button'}
                        onClick={createMessageHandler}>
                    {t(keys.forum.sendMessage)}
                </Button>
            </div>}

            {comments.map((comment: IForumCommentDto, index: number) => {
                return <ForumCommentComponent comment={comment} onCommentUpdated={loadComments}/>
            })}
            {totalPages > 1 && <div className="forum_page__theme_content__pagination">
                <Pagination defaultActivePage={1} activePage={page} totalPages={totalPages}
                            onPageChange={pageChange}/>
            </div>}
        </div>
    </div>
}

const ForumThemePage = () => {
    const {getTheme} = useForum();
    let {id} = useParams<{ id: string | undefined }>();
    const [theme, setTheme] = useState(undefined as undefined | IForumThemeDto);

    const loadPage = async () => {
        const page = await getTheme(Number.parseInt(id!, 10));
        setTheme(page);
    }

    useEffect(() => {
        if (id) {
            loadPage()
        }
    }, [id])

    const onMessageCreatedHandler = () => {
        if (theme) {
            const updated: IForumThemeDto = {...theme, commentsCount: theme.commentsCount + 1};
            setTheme(updated);
        }
    }

    if (!theme) {
        return <div className="kek">loading...</div>
    }

    return <ForumThemeContent theme={theme} onMessageCreated={onMessageCreatedHandler} onThemeUpdated={loadPage}/>
}

export default ForumThemePage;