import React, {useEffect, useState} from "react";
import {Button, Container, Dimmer, Icon, Label, Loader, Menu, Segment, Tab, Table} from "semantic-ui-react";
import useAuth from "../Hooks/useAuth";
import UserEditPage from "./UserEditPage";
import UserCreatePage from "./UserCreatePage";
import useUsers from "../Hooks/useUsers";
import useLogs, {LogFilters} from "../Hooks/useLogs";
import moment from "moment";
import 'moment/locale/ru';
import LogViewModal from "./LogViewModal";
import ScriptUploadView from "./ScriptUploadView";
import LogsView, {DropDownOption, FiltersOptions} from "./LogsView";



const AdminPage = () => {
    const pageSize = 25;
    const {state} = useAuth();
    const [activeTab, setActiveTab] = useState(2);
    const [isEdit, setEdit] = useState(false);
    const [isCreateUser, setCreateUser] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const {getUsers, deleteUser, MessagePanel, isLoading: isLoadingUsers} = useUsers();
    const {getLogs, MessagePanel: LogMessagePanel, isLoading, clearLogs} = useLogs();

    const [users, setUsers] = useState([]);
    const [logs, setLogs] = useState([]);
    const [logsCount, setLogsCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [logDescription, setLogDescription] = useState('');
    const [filterOptions, setFilterOptions] = useState(
        {
            logLevels: [],
            plugins: [],
            users: []
        } as FiltersOptions);
    const [logFilters, setLogFilters] = useState(
        {
            logLevels: [],
            plugins: [],
            users: []
        } as LogFilters);

    const levels = ['Trace', 'Debug', 'Information', 'Warning', 'Error', 'Critical', 'None']

    const mapToOption = (value: any, parsedArray?: Array<string>) => {
        return {key: value, value: value, text: parsedArray ? parsedArray[value] : value} as DropDownOption;
    }

    const fetch = async () => {
        if (activeTab === 0) {
            const data: any = await getUsers();
            setUsers(data)
        } else if (activeTab === 1) {
            const data = await getLogs(activePage, pageSize, logFilters);
            if (data) {
                setLogs(data.result)
                setLogsCount(data.filter.total)
                setFilterOptions({
                    plugins: data.filter.plugins.map((s: string) => mapToOption(s)),
                    users: data.filter.users.map((s: string) => mapToOption(s)),
                    logLevels: data.filter.levels.map((s: string) => mapToOption(s, levels)),
                })
            }
        }
    }

    useEffect(() => {
        if (!isCreateUser) {
            fetch()
        }
    }, [isCreateUser])

    useEffect(() => {
        fetch()
    }, [activeTab])

    useEffect(() => {
        fetch()
    }, [activePage])

    useEffect(() => {
        fetch()
    }, [logFilters])

    const editClick = (id: number) => {
        setSelectedUserId(id);
        setEdit(true)
    }

    const deleteUserHandler = async (id: string) => {
        await deleteUser(id)
        await fetch();
    }

    const onAccept = (acceptedFilters: LogFilters) => {
        setLogFilters(acceptedFilters);
    }
    const onClear = () => {
        setLogFilters({
            users: [],
            plugins: [],
            logLevels: []
        });
    }
    const onDeleteAllLogs = async () => {
        await clearLogs();
        await fetch();
    }

    const panes = [
        {
            menuItem: {key: 'users', icon: 'users', content: 'Пользователи'},
            render: () => <Tab.Pane>
                <MessagePanel showOnlyError={true}/>
                <Segment>
                    <Dimmer active={isLoadingUsers}>
                        <Loader/>
                    </Dimmer>
                    <Table compact selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={"8"}>Id</Table.HeaderCell>
                                <Table.HeaderCell width={"4"}>Name</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {users && users.map && users.map((user: any, index) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>{user.id}</Table.Cell>
                                        <Table.Cell>{user.userName}</Table.Cell>
                                        <Table.Cell>
                                            <Button floated={"right"} circular color={"red"} icon='user delete'
                                                    onClick={() => deleteUserHandler(user.id)}/>
                                            <Button floated={"right"} circular color={"yellow"} icon='settings'
                                                    onClick={() => editClick(user.id)}/>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}

                        </Table.Body>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    <Button
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        primary
                                        size='small'
                                        onClick={() => setCreateUser(true)}
                                    >
                                        <Icon name='user'/> Добавить пользователя
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>
            </Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='logs'>
                    Логи <Label>{logsCount}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane>
                <LogsView onDescriptionClick={(logObject: any) => setLogDescription(logObject)}
                          onPageChange={page => setActivePage(page)} isLoading={isLoading} logs={logs}
                          logsCount={logsCount} LogMessagePanel={LogMessagePanel} filters={logFilters}
                          filterOptions={filterOptions} onAccept={onAccept} onClear={onClear}
                          onDeleteAllLogs={onDeleteAllLogs}/>
            </Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='plugins'>
                    Управление плагинами
                </Menu.Item>
            ),
            render: () =>
                <Tab.Pane>
                    <ScriptUploadView/>
                </Tab.Pane>
        }

    ]

    const onTabChange = (event: any, {activeIndex}: any) => {
        setActiveTab(activeIndex)
    }

    return <Container>
        <Tab panes={panes} activeIndex={activeTab} onTabChange={onTabChange}/>
        {/*selected: {activeTab}*/}
        <UserEditPage open={isEdit} setOpen={setEdit} userId={selectedUserId}/>
        <UserCreatePage open={isCreateUser} setOpen={setCreateUser}/>
        <LogViewModal open={!!logDescription} setOpen={setLogDescription} log={logDescription}/>
    </Container>
}

export default AdminPage;
