import React, {useEffect, useState} from "react";
import useProgress from "./useProgress";
import axios from "axios";
import Constants from "../Configuration";
import {AuthContext} from "../Context/RootContext";

export type IUserEmailInfo = {
    email: string;
    emailConfirmed: boolean;
}

const useAccount = (preLoad: boolean = false) => {
    const {error, isLoading, BeginLoading} = useProgress();
    const [userInfo, setUserInfo] = useState({email: '', emailConfirmed: false} as IUserEmailInfo);
    const state = React.useContext(AuthContext);
    const changePassword = async (currentPassword: string, newPassword: string) => {
        const request = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: newPassword
        }
        return await BeginLoading(async () => {
            const data = await axios.post(Constants.stsAuthority + `account/ChangePassword`, request)
            return data.data;
        })
    }

    const getUserInfo = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + `account/GetUserInfo`)
            return data.data;
        })
    }

    const setUserPassword = async (userId: string, pass: string) => {
        const request = {
            UserId: userId,
            NewPassword: pass,
        }
        return await BeginLoading(async () => {
            const data = await axios.post(Constants.stsAuthority + `account/ResetPasswordForUser`, request)
            return data.data;
        })
    }

    const sendEmailVerification = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'account/SendEmailVerification');
            return data;
        })
    }

    const changeEmail = async (email: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + `account/ChangeEmail/${email}`);
            // const data = await state.apiService.callApi(Constants.stsAuthority + `account/ChangeEmail/${email}`)
            return data.data;
        })
    }

    const loadUserInfoAndSave = () => {
        getUserInfo().then(value => {
            setUserInfo(value);
        })
    }

    const getHwid = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/Hwid/Get`)
            return data.data;
        })
    }

    const getHwidById = async (userId: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/Hwid/Get/` + userId)
            return data.data;
        })
    }

    const resetHwid = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/Hwid/Reset`)
            return data.data;
        })
    }

    const resetHwidById = async (userId: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/Hwid/Reset/` + userId)
            return data.data;
        })
    }

    const getIp = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/ip`)
            return data.data;
        })
    }

    const getIpById = async (userId: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/ip/` + userId)
            return data.data;
        })
    }

    const getBanState = async (userId: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/getBanState/` + userId)
            return data.data;
        })
    }

    const setBanState = async (userId: string, banState: boolean) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + `Account/setBanState/${userId}/${banState}`)
            return data.data;
        })
    }

    useEffect(() => {
        if (preLoad)
            loadUserInfoAndSave();
    }, [preLoad])

    return {
        changePassword,
        sendEmailVerification,
        getUserInfo,
        changeEmail,
        loadUserInfoAndSave,
        getHwid,
        getHwidById,
        resetHwid,
        resetHwidById,
        setBanState,
        getBanState,
        getIp,
        getIpById,
        setUserPassword,
        isLoading,
        error,
        userInfo
    }
}

export default useAccount
