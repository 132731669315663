import {useTranslation} from "react-i18next";
import keys from "../translations/keys";
import React, {useContext, useState} from "react";
import {LanguageContext} from "../Context/LanguageContext";
import {Button, Confirm, Dimmer, Input, Loader} from "semantic-ui-react";
import TextInput from "../Components/TextInput";
import useAccount from "../Hooks/useAccount";
import {useToasts} from "react-toast-notifications";
import LangSelector from "../Components/LangSelector";
import useSubscribe from "../Hooks/useSubscribe";
import DiscordButton from "../Components/DiscordButton";

const AccountPage = () => {
    const {t, i18n} = useTranslation();
    const {lang, changeLanguage} = useContext(LanguageContext);
    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
    });
    const {activateToken} = useSubscribe();
    const [newMail, setNewMail] = useState('');
    const [newMailOpen, setNewMailOpen] = useState(false);
    // const [hwid, setHwid] = useState('');

    const {addToast} = useToasts();
    const {
        changePassword,
        getHwid,
        resetHwid,
        error,
        isLoading,
        userInfo,
        sendEmailVerification,
        changeEmail,
        loadUserInfoAndSave
    } = useAccount();
    const [tokensForActivate, setTokensForActivate] = useState("");
    // useEffect(() => {
    //     getHwid().then(value => {
    //         setHwid(value)
    //     })
    // },[])

    const changePasswordHandler = async () => {
        if (!passwordData.oldPassword || !passwordData.newPassword)
            return;
        const result = await changePassword(passwordData.oldPassword, passwordData.newPassword);
        if (!result)
            return;
        if (result.error) {
            result.error.forEach((error: any) => {
                    addToast(error.description, {appearance: "error", autoDismiss: true})
                }
            )
        } else {
            setPasswordData({
                oldPassword: '',
                newPassword: '',
            })
            addToast('Password changed successfully', {appearance: "success", autoDismiss: true})
        }
    }

    const SendAgainVerificationHandler = async () => {
        const result = await sendEmailVerification()
        if (!result)
            return;
        if (result.error) {
            addToast(result.error, {appearance: "error", autoDismiss: true})
        } else {
            addToast('Email send to ' + userInfo.email, {appearance: "success", autoDismiss: true})
        }
    }

    const isEmail = () => {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !newMail || newMail.length === 0 || reg.test(newMail);
    }

    const resetHwidHandler = async () => {
        const result = await resetHwid();
        if (!result)
            return;
        if (result.error) {
            addToast(result.error, {appearance: "error", autoDismiss: true})
        } else {
            addToast('Hwid reset successfully', {appearance: "success", autoDismiss: true})
        }
    }
    const ActivateTokenHandler = async () => {
        if (!tokensForActivate)
            return;
        const result = await activateToken(tokensForActivate);
        setTokensForActivate('');
        if (result.error) {
            addToast(result.error, {appearance: "error", autoDismiss: true})
        } else {
            const text = t(keys.notifications.added) + t(keys.day.day, {count: Number(result.days)});
            addToast(text, {appearance: "success", autoDismiss: true})
        }
    }


    return <div className="account-page">
        <div className="block block__lang">
            <div className="block__header">
                {t(keys.account.langSelector)}
            </div>
            <div className="block__content">
                <LangSelector/>
            </div>
        </div>
        <div className="block block__token">
            <div className="block__header">
                {t(keys.account.activateToken)}
            </div>
            <div className="block__content">
                <TextInput id={'token'}
                           placeholder={"token"}
                           value={tokensForActivate}
                           onChange={setTokensForActivate}/>
                <div className="btn" onClick={ActivateTokenHandler}>{t(keys.account.acceptToken)}</div>
                {/*<Button className={'btn'} onClick={ActivateTokenHandler} disabled={!tokensForActivate}>Активировать</Button>*/}
            </div>
        </div>
        {/*<div className="block block__token">
            <div className="block__header">
                {t(keys.account.activateToken)}
            </div>
            <div className="block__content">
                <TextInput id={'token'}
                           type='text'
                           placeholder={"Your token key"}
                           value={''}
                           onChange={value => {
                           }}
                />
                <div className="btn">accept</div>
            </div>
        </div>*/}
        <div className="block block__change_password">
            <div className="block__header">
                {t(keys.account.changePassword)}
            </div>
            <div className="block__content">
                <div className="block__content__input">
                    <TextInput id={'oldPass'}
                               placeholder={"Old password"}
                               type={'password'}
                               value={passwordData.oldPassword}
                               onChange={value => {
                                   setPasswordData(prevState => {
                                       return {...prevState, oldPassword: value}
                                   })
                               }}
                    />
                    <TextInput id={'newPass'}
                               placeholder={"New password"}
                               type={'password'}
                               value={passwordData.newPassword}
                               onChange={value => {
                                   setPasswordData(prevState => {
                                       return {...prevState, newPassword: value}
                                   })
                               }}
                    />
                </div>
                <div className="btn" onClick={changePasswordHandler}>{t(keys.account.changePassword)}</div>
            </div>
        </div>
        {/*<div className="block block__hwid">
            <div className="block__header">
                {t(keys.account.resetHwid)}
            </div>
            <div className="block__content">
                <div className="btn">reset</div>
            </div>
        </div>*/}
        <div className="block block__email">
            <Dimmer active={isLoading}>
                <Loader/>
            </Dimmer>
            <div className="block__header">
                {t(keys.account.email)}
            </div>
            <div className="block__content">
                {userInfo.email && <>
                    <div className="text">{userInfo.email}</div>
                    {userInfo.emailConfirmed ?
                        <div className="btn btn--disabled">{t(keys.account.confirmedEmail)}</div> :
                        <>
                            <div className="btn"
                                 onClick={SendAgainVerificationHandler}>{t(keys.account.confirmEmail)}</div>
                        </>

                    }
                    <div className="btn"
                         onClick={() => setNewMailOpen(true)}>{t(keys.account.changeEmail)}</div>
                </>}
            </div>
            <Confirm
                open={newMailOpen}
                header={t(keys.account.changeEmail)}
                content={() => {
                    return <div className="account-page__modal" style={{width: '100%'}}>
                        <Input error={!isEmail()} onChange={(e) => setNewMail(e.target.value)} focus
                               placeholder={t(keys.account.newEmail)}
                               style={{width: '100%', padding: '0 5px'}}/>
                    </div>
                }}
                onCancel={() => setNewMailOpen(false)}
                onConfirm={async () => {
                    if (isEmail()) {
                        await changeEmail(newMail)
                        setNewMailOpen(false);
                        loadUserInfoAndSave();
                    }
                }}
            />
        </div>
        <div className="block block__hwid">
            <div className="block__header">
                Hwid
            </div>
            <div className="block__content">
                <div className="btn"
                     onClick={resetHwidHandler}>{t(keys.account.resetHwid)}</div>
            </div>
        </div>
        <div className="block block__discord">
            <div className="block__header">
                Link discord
            </div>
            <div className="block__content">
                <DiscordButton/>
            </div>
        </div>
    </div>
}

export default AccountPage;
