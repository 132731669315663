import React from "react";
import {AuthContext, IAuthTypeStatus} from "../Context/RootContext";

const useAuth = () => {
    const state = React.useContext(AuthContext)
    const Login = () => state?.authService?.login();
    const isAuthenticated = !!state.user && state.status === IAuthTypeStatus.LoggedIn
    const user = state.user

    return {
        state,
        isAuthenticated,
        claims: state.claims,
        user
    }
}

export default useAuth
