import React, {Component, RefObject} from 'react';
import _ from 'underscore';
import classNames from 'classnames';

export enum TailPosition {
    RightTop = 0,
    RightMiddle = 1,
    RightBottom = 2,
    BottomLeft = 3,
    BottomCenter = 4,
    BottomRight = 5,
    LeftTop = 6
}

export interface ITailProps {
    target: RefObject<any>;
    offset?: { x: number; y: number };
    trackWidth?: boolean;
    className?: string;
    position?: TailPosition;
    children?: any;
}

export interface ITailState {
    x: number;
    y: number;
    width: number;
}

export const TAIL_CLASS_NAME = 'krkn__tail';

class Tail extends Component<ITailProps, ITailState> {
    private _contentRef: React.RefObject<HTMLDivElement>;

    constructor(props: ITailProps) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            width: 0
        };
        this._contentRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll, true);
        window.addEventListener('resize', this.onResize);
        this.recalculatePos();
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScroll, true);
        window.removeEventListener('resize', this.onResize);
    };

    componentDidUpdate(prevProps: ITailProps) {
        if (prevProps.target !== this.props.target) {
            this.recalculatePos();
        }
    }

    onResize = _.throttle(() => {
        this.recalculatePos();
    }, 10);

    onScroll = _.throttle(() => {
        this.recalculatePos();
    }, 10);

    getContentPos = (): DOMRect | null => {
        const content = this._contentRef;
        return content && content.current && content.current.getBoundingClientRect() as DOMRect;
    };

    getNewPos = (tailPos: TailPosition, pos: ClientRect | DOMRect) => {
        if (!pos) return null;
        let newX = pos.left;
        let newY = pos.top + pos.height;
        let newWidth = pos.width;

        switch (tailPos) {
            case TailPosition.RightMiddle: {
                const contentPos = this.getContentPos();

                newX = pos.right + 10;
                if (contentPos) {
                    newY = pos.top - contentPos.height / 2 + pos.height / 2;
                } else {
                    newY = pos.top;
                }
                newWidth = pos.width;
                break;
            }
            case TailPosition.LeftTop: {
                const contentPos = this.getContentPos();
                if (contentPos) {
                    newX = pos.left - contentPos.width + pos.width;
                    newY = pos.bottom - contentPos.height - pos.height;
                    newWidth = contentPos.width;
                } else {
                    newX = pos.left;
                    newY = pos.bottom;
                }

                break;
            }
            case TailPosition.BottomRight: {
                const contentPos = this.getContentPos();
                if (contentPos) {
                    newX = pos.right - contentPos.width;
                    newWidth = contentPos.width;
                } else {
                    newX = pos.right;
                }
                newY = pos.top + pos.height;
                break;
            }
            case TailPosition.BottomCenter: {
                const contentPos = this.getContentPos();
                if (contentPos) {
                    newX = pos.right - contentPos.width / 2 - pos.width / 2;
                    newY = pos.top + pos.height;
                    newWidth = contentPos.width;
                }
                break;
            }
            default: {
                newX = pos.left;
                newY = pos.top + pos.height;
                newWidth = pos.width;
            }
        }

        const {offset} = this.props;
        const xOffset = offset?.x || 0;
        const yOffset = offset?.y || 0;

        return {
            x: newX + xOffset,
            y: newY + yOffset,
            width: newWidth
        };
    };

    recalculatePos = () => {
        const {target, trackWidth, position} = this.props;

        const pos = target.current && target.current.getBoundingClientRect();

        let targetPosition = TailPosition.RightMiddle;
        if (position) {
            targetPosition = position;
        }

        const newPos = this.getNewPos(targetPosition, pos);
        if (newPos && pos) {
            if (trackWidth) {
                this.setState({
                    x: newPos.x,
                    y: newPos.y,
                    width: newPos.width
                });
            } else {
                this.setState({
                    x: newPos.x,
                    y: newPos.y
                });
            }
        }
    };

    render() {
        const {children, className, trackWidth} = this.props;
        const {x, y, width} = this.state;
        const cls = classNames(TAIL_CLASS_NAME, className);
        let targetStyle: any = {
            left: x,
            top: y
        };
        if (trackWidth) {
            targetStyle.width = width;
        }
        return (
            <div className={cls} style={targetStyle}>
                <div ref={this._contentRef}>{children}</div>
            </div>
        );
    }
}

export default Tail;
