import Button from "../../Components/Button";
import ProgressBarComponent from "../../Components/ProgressBarComponent";
import React, {useContext, useEffect, useState} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {SignalRContext} from "../../Context/SignalRContext";
import keys from "../../translations/keys";
import {useTranslation} from "react-i18next";
import useDllFiles from "../../Hooks/useDllFiles";
import useSignalR from "../../Hooks/useSignalR";
import {useToasts} from "react-toast-notifications";
import {Icon} from "semantic-ui-react";
import useAuth from "../../Hooks/useAuth";
import classNames from "classnames";
import Constants from "../../Configuration";

enum StatusEnum {
    DownloadOrStart,
    StartDota,
    Loading,
}

enum DownloadTypeEnum {
    DownloadResource,
    DownloadClient,
}

const StartDotaBlock = () => {
    const [status, setStatus] = useState(StatusEnum.DownloadOrStart);
    const [downloadType, setDownloadType] = useState(DownloadTypeEnum.DownloadResource);
    const [progress, setProgress] = useState(0);
    const {isClientOnline: isOnline, downloads, canPlay, connection} = useContext(SignalRContext);
    const {t} = useTranslation();
    const {downloadClientFile} = useDllFiles();
    const {startDota, loadOnlineState, isLoading} = useSignalR();
    const {addToast} = useToasts();
    const {user} = useAuth();
    const [isDownloadingClient, setDownloadClientState] = useState(false);
    const isSignalRActive = connection?.connectionState === "Connected";
    useEffect(() => {
        if (isOnline) {
            const download: number = Object.values(downloads).filter((x: string) => x !== '100').map((x => parseInt(x))).reverse()[0];
            if (download) {
                setStatus(StatusEnum.Loading);
                setProgress(download);
            } else {
                setStatus(StatusEnum.StartDota)
                setProgress(0);
            }
        } else {
            setStatus(StatusEnum.DownloadOrStart)
            setProgress(0);
        }
    }, [isOnline, downloads])

    const startDotaHandler = async () => {
        const result: any = await startDota();
        if (result?.error) {
            addToast(result.error, {
                appearance: "error",
                autoDismiss: true
            })
        }
    }

    const onDownloadClientHandler = async () => {
        if (isDownloadingClient) {
            return;
        }
        setDownloadClientState(true);
        addToast('Downloading client...', {
            appearance: "info",
            autoDismiss: true
        })
        setStatus(StatusEnum.Loading);
        setDownloadType(DownloadTypeEnum.DownloadClient);
        setProgress(0);
        await downloadClientFile(progressEvent => {
            let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );

            setProgress(percentCompleted);
        });
        setDownloadClientState(false);
        setProgress(0);
        setStatus(StatusEnum.DownloadOrStart);
        setDownloadType(DownloadTypeEnum.DownloadResource);
    }

    const downloadBtnCls = classNames('download-btn', {
        'download-btn--downloading': isDownloadingClient
    })

    const content = () => {
        switch (status) {
            case StatusEnum.DownloadOrStart:
                return <div className="start-dota-block--buttons">
                    <Button circular className={downloadBtnCls} onClick={onDownloadClientHandler} disabled={isLoading}>
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.2 0H1.13333C0.832755 0 0.544487 0.105352 0.331946 0.292879C0.119405 0.480406 3.61607e-07 0.734747 3.61607e-07 0.99995V14.9643C-0.000179034 15.1624 0.066395 15.3562 0.19127 15.521C0.316144 15.6857 0.493687 15.814 0.701355 15.8895C0.909023 15.9651 1.13745 15.9845 1.35763 15.9452C1.57781 15.906 1.77982 15.8099 1.938 15.6692L5.644 12.4094L9.401 15.7092C9.55982 15.8485 9.76185 15.9432 9.9816 15.9813C10.2013 16.0193 10.4289 15.9991 10.6357 15.9232C10.8424 15.8472 11.019 15.7189 11.1432 15.5545C11.2673 15.39 11.3335 15.1968 11.3333 14.9993V0.99995C11.3333 0.734747 11.2139 0.480406 11.0014 0.292879C10.7888 0.105352 10.5006 0 10.2 0Z"
                                fill="#0070af"/>
                        </svg>
                        {t(keys.mainPage.downloadClient)}
                    </Button>
                    {/*<a target='_blank' href={Constants.apiEndpoint + 'Download/client/false'} className={downloadBtnCls}>*/}
                    {/*    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path*/}
                    {/*            d="M10.2 0H1.13333C0.832755 0 0.544487 0.105352 0.331946 0.292879C0.119405 0.480406 3.61607e-07 0.734747 3.61607e-07 0.99995V14.9643C-0.000179034 15.1624 0.066395 15.3562 0.19127 15.521C0.316144 15.6857 0.493687 15.814 0.701355 15.8895C0.909023 15.9651 1.13745 15.9845 1.35763 15.9452C1.57781 15.906 1.77982 15.8099 1.938 15.6692L5.644 12.4094L9.401 15.7092C9.55982 15.8485 9.76185 15.9432 9.9816 15.9813C10.2013 16.0193 10.4289 15.9991 10.6357 15.9232C10.8424 15.8472 11.019 15.7189 11.1432 15.5545C11.2673 15.39 11.3335 15.1968 11.3333 14.9993V0.99995C11.3333 0.734747 11.2139 0.480406 11.0014 0.292879C10.7888 0.105352 10.5006 0 10.2 0Z"*/}
                    {/*            fill="#0070af"/>*/}
                    {/*    </svg>*/}
                    {/*    {t(keys.mainPage.downloadClient)}*/}
                    {/*</a>*/}
                    <Button circular className={'start-client-btn'} disabled={!isSignalRActive}>
                        <a className='btn btn-two'
                           href={`Divine://${user?.profile.sub}`}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10 20C4.47967 19.9939 0.00606237 15.5203 0 10V9.8C0.109931 4.30453 4.63459 -0.0720429 10.1307 0.000881051C15.6268 0.073805 20.0337 4.56889 19.9978 10.0653C19.9619 15.5618 15.4966 19.9989 10 20ZM8 5.5V14.5L14 10L8 5.5Z"
                                    fill="#FF0000"/>
                            </svg>
                            {t(keys.mainPage.startDivineClient)}
                        </a>
                    </Button>
                </div>
            case StatusEnum.StartDota:
                return <div className="start-dota-block--button">
                    <Button icon circular color={'orange'} className={'start-btn'} onClick={startDotaHandler}>
                        <Icon name='play'/>
                        {t(keys.mainPage.startDota)}
                    </Button>
                </div>
            case StatusEnum.Loading:
                return <div className="start-dota-block--progress">
                    <ProgressBarComponent value={progress}
                                          title={downloadType === DownloadTypeEnum.DownloadResource ? t(keys.mainPage.downloadingResources) : t(keys.mainPage.downloadingClient)}/>
                </div>
        }
    }

    const renderByStatus = () => {
        return <SwitchTransition mode={'out-in'}>
            <CSSTransition
                key={status}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                <div className="content">
                    {content()}
                </div>
            </CSSTransition>
        </SwitchTransition>

    }

    const clickHandler = () => {
        switch (status) {
            case StatusEnum.DownloadOrStart:
                setStatus(StatusEnum.StartDota)
                break;
            case StatusEnum.StartDota:
                setStatus(StatusEnum.Loading)
                break;
            case StatusEnum.Loading:
                setStatus(StatusEnum.DownloadOrStart)
                break;
        }
    }

    return <div className="start-dota-block">
        <div className="animated-container">
            {renderByStatus()}
        </div>

    </div>
}

export default StartDotaBlock;
