import keys from '../keys';

const translations = {
    [keys.regularUser.title]: '恭喜！您是我们的常客。旧价格对您有效!',
    [keys.regularUser.description]: '为了不失去效果，您需要提前补充您的订阅，在它结束之前',
    [keys.invalidTime]: '您的电脑时间设置错误，出于安全考虑，您需要在操作系统设置中设置自动时间同步.',
    [keys.confirmEmailPage.title]: '确认您的电子邮件!',
    [keys.confirmEmailPage.description]: '为了使用该网站，您必须确认您的电子邮件。请查看您在注册时指定的邮箱，已向您发送账户确认邮件，如果未收到邮件，请查看您的邮件服务中的垃圾邮件文件夹，或重新请求该邮箱.',
    [keys.confirmEmailPage.urEmail]: '你的邮件',
    [keys.account.langSelector]: '选择语言',
    [keys.account.activateToken]: '输入卡密',
    [keys.account.acceptToken]: '启用',
    [keys.account.changePassword]: '更改密码',
    [keys.account.resetHwid]: '重置 HWID',
    [keys.account.email]: '邮箱',
    [keys.account.confirmEmail]: '确认电子邮件',
    [keys.account.confirmedEmail]: '电子邮件确认',
    [keys.account.newEmail]: '输入新电子邮件',
    [keys.account.changeEmail]: '更改电子邮件',

    [keys.welcomeHi]: '你好~ ',
    [keys.welcomeInfo]: '在此页面上您可以查看所有信息，下载客户端并运行它.',
    [keys.cancel]: '取消',
    [keys.save]: '保存',
    [keys.showOnMap]: '在地图上显示',
    [keys.download]: '下载',
    [keys.downloadInfo]: '在这里您可以下载客户端并启动它',
    [keys.lefPanel.dashboard.title]: '控制面板',
    [keys.lefPanel.dashboard.main]: '主要的',
    [keys.lefPanel.dashboard.forum]: '论坛',
    [keys.lefPanel.dashboard.buy]: '购买',
    [keys.lefPanel.dashboard.plugins]: '插件',
    [keys.lefPanel.dashboard.addPlugin]: '添加插件',
    [keys.lefPanel.dashboard.localPlugin]: '本地插件',
    [keys.lefPanel.dashboard.cloudConfig]: '配置',
    [keys.lefPanel.dashboard.account]: '帐户',
    [keys.lefPanel.admin.title]: '管理员',
    [keys.lefPanel.admin.users]: '用户',
    [keys.lefPanel.admin.logs]: '日志',
    [keys.lefPanel.admin.loadLibrary]: '加载库',
    [keys.lefPanel.admin.pluginManager]: '插件管理器',
    [keys.lefPanel.admin.oldMenu]: '旧菜单',
    [keys.lefPanel.admin.statistics]: '统计数据',
    [keys.lefPanel.hide]: '隐藏面板',

    [keys.mainPage.downloadClient]: '下载客户端',
    [keys.mainPage.downloadingResources]: '请稍候，资源正在更新中，加载完毕后开始按钮可用',
    [keys.mainPage.downloadingClient]: '请稍候，正在下载客户端',
    [keys.mainPage.lastPluginsUpdate]: '最后更新',
    [keys.mainPage.protection]: '进度 99.9%',
    [keys.mainPage.showAll]: '显示所有',
    [keys.mainPage.startDivineClient]: '启动神圣客户端',
    [keys.mainPage.startDota]: '开始dota',
    [keys.mainPage.startingClient]: '客户端开始...',
    [keys.mainPage.systemStatus]: '系统状况',
    [keys.mainPage.uptime]: '正常运行 99.9%*',
    [keys.mainPage.working]: '工作中',
    [keys.mainPage.paymentBlock.amount]: '数量',
    [keys.mainPage.paymentBlock.discount]: '优惠',
    [keys.mainPage.paymentBlock.freeKassa.btn]: '自由卡萨',
    [keys.mainPage.paymentBlock.freeKassa.tooltip]: '仅适用于 CIS 用户',
    [keys.mainPage.paymentBlock.paymaster.btn]: 'PayMaster',
    [keys.mainPage.paymentBlock.paymaster.tooltip]: 'Visa, MasterCard, Qiwi, WebMoney, Alipay, WeChatPay, etc',
    [keys.mainPage.paymentBlock.payDigi.btn]: 'Digiseller',
    [keys.mainPage.paymentBlock.payDigi.tooltip]: 'Alipay, Btc, Wmz, Qiwi, Steam skins',
    [keys.mainPage.paymentBlock.primePayments.btn]: '高级支付',
    [keys.mainPage.paymentBlock.primePayments.tooltip]: 'Card for ru and some eu',
    [keys.mainPage.paymentBlock.paymax.btn]: 'Vallet',
    [keys.mainPage.paymentBlock.paymax.tooltip]: 'USA, EU and any countries except Russian',
    [keys.mainPage.paymentBlock.paypal.btn]: 'Paypal',
    [keys.mainPage.paymentBlock.paypal.tooltip]: '这时候你需要在discord上创建票发送到paypal，然后我们给你代码',
    [keys.mainPage.paymentBlock.reseller.btn]: '代理商',
    [keys.mainPage.paymentBlock.reseller.tooltip]: '如果所提供的付款方式都不适合您，请与我们的经销商联系. ivan2301#1488',
    [keys.mainPage.paymentBlock.chineseReseller.btn]: '中国经销商',
    [keys.mainPage.paymentBlock.chineseReseller.tooltip]: '跳转中国用户购买网页',

    [keys.mainPage.paymentBlock.title]: '购买订阅',
    [keys.mainPage.transactionBlock.title]: '交易记录',
    [keys.mainPage.subLifeTimeBlock.title]: '钥匙到期时间',
    [keys.mainPage.paymentBlock.tooltip.title]: '订阅能给你带来什么？',
    [keys.mainPage.paymentBlock.tooltip.row1]: '访问所有功能',
    [keys.mainPage.paymentBlock.tooltip.row2]: '快速帮助支持',
    [keys.mainPage.paymentBlock.tooltip.row3]: '允许您每周玩超过 1 场比赛',
    [keys.mainPage.paymentBlock.tooltip.extra]: '付款后5分钟内激活订阅',

    // ------------ othersBlock -----------
    [keys.mainPage.othersBlock.activeToken.title]: '激活令牌',
    [keys.mainPage.othersBlock.activeToken.description]: '在字段中插入订阅激活令牌',
    [keys.mainPage.othersBlock.activeToken.btnText]: '启用',

    [keys.mainPage.othersBlock.hwid.title]: '重置  PC  HWID',
    [keys.mainPage.othersBlock.hwid.description]: '您可以每 8 小时重置一次 HWID',
    [keys.mainPage.othersBlock.hwid.btnText]: '重置',

    [keys.mainPage.othersBlock.discord.title]: 'Discord',
    [keys.mainPage.othersBlock.discord.description]: 'You can link your Discord account and get 3 free sub days',
    [keys.mainPage.othersBlock.discord.btnText]: '使用您的不和谐帐户登录',

    [keys.mainPage.othersBlock.vk.title]: 'VKontakte',
    [keys.mainPage.othersBlock.vk.description]: 'You can link your VK account and get 3 free sub days',
    [keys.mainPage.othersBlock.vk.btnText]: 'Log in with your VK account',

    [keys.mainPage.othersBlock.password.title]: '更改密码',
    [keys.mainPage.othersBlock.password.description]: '您可以更改您帐户的密码',
    [keys.mainPage.othersBlock.password.btnText]: '输入新密码',

    [keys.mainPage.othersBlock.email.description]: '要使用我们的产品，您需要确认您的电子邮件地址',

    [keys.mainPage.userInfoBlock.userId.title]: '您用于联系技术支持的 ID',
    [keys.mainPage.userInfoBlock.email.title]: '你的邮箱',
    [keys.mainPage.userInfoBlock.playedGamesCount.title]: '玩过神器游戏',
    [keys.mainPage.userInfoBlock.subTime.title]: '剩余订阅时间',
    [keys.mainPage.userInfoBlock.subTime.infinity]: '无限',
    [keys.mainPage.userInfoBlock.crashTime.title]: '剩余的 Crasher 订阅时间',

    [keys.buySubBlock.welcomeText]: '加入我们在 Dota 2 中的 DIVINE 社区，您做出了正确的选择',
    [keys.buySubBlock.saleText]: '您的累积折扣',
    [keys.buySubBlock.colOne.title]: '普通客户的累积折扣',
    [keys.buySubBlock.colOne.description]: '普通客户最高可享受 15% 的折扣，以及购买时的各种折扣和其他折扣!',
    [keys.buySubBlock.colTwo.title]: '立即获得',
    [keys.buySubBlock.colTwo.description]: '通过 QIWI、MasterCard、Visa、MIR、Apple Pay、WebMoney 以及各种加密货币和 Steam 皮肤支付',

    [keys.forum.create]: '创造',
    [keys.forum.hidden]: '隐藏的',
    [keys.forum.delete]: '删除',
    [keys.forum.createPage]: '创建页面',
    [keys.forum.createTheme]: '创建主题',
    [keys.forum.sendMessage]: '发信息',
    [keys.forum.created]: '已创建',
    [keys.forum.updated]: '更新',
    [keys.forum.startedBy]: '开始于',
    [keys.forum.comment_base.comment]: '{{count}} 评论',
    [keys.forum.comment_base.comment_plural]: '{{count}} 注释',


    // [keys.daysBefore]: `$t(day.day, {"count": {{days}} }) ago`,
    // [keys.day.day]: '{{count}} day',
    // [keys.day.day_0]: '{{count}} days',
    // [keys.day.day_1]: '{{count}} day',
    // [keys.day.day_2]: '{{count}} days',
    // [keys.day.day_3]: '{{count}} days',
    // [keys.day.day_4]: '{{count}} days',
    // [keys.day.day_5]: '{{count}} days',
    // [keys.day.day_plural]: '{{count}} days',

    [keys.daysBefore]: `$t(day.day, {"count": {{days}} }) ago`,
    [keys.day.day]: '{{count}} day',
    [keys.day.day_plural]: '{{count}} days',

    [keys.pluginsPage.developer]: '开发商',
    [keys.pluginsPage.enablePlugin]: '使能够',
    [keys.pluginsPage.disablePlugin]: '禁用',
    [keys.pluginsPage.info]: '信息',
    [keys.pluginsPage.installed]: '已安装',
    [keys.pluginsPage.deletePlugin]: '删除',
    [keys.pluginsPage.installPlugin]: '安装',
    [keys.pluginsPage.overview]: '概述',
    [keys.pluginsPage.close]: '关',
    [keys.pluginsPage.description]: '描述',
    [keys.pluginsPage.video_overview]: '插件的视频评论',

    [keys.resetPassword.resetPage.title]: '重置密码页面',
    [keys.resetPassword.resetPage.setNewPassword]: '设置新密码',
    [keys.resetPassword.resetPage.newPassword]: '新密码',
    [keys.resetPassword.resetPage.newPasswordConfirm]: '新密码确认',

    [keys.resetPassword.confirmPage.title]: '确认已发送到您的电子邮件地址',
    [keys.resetPassword.confirmPage.text1]: '一条消息已发送到您的邮箱，其中包含确认密码重置的链接。请点击链接完成操作.',
    [keys.resetPassword.confirmPage.text2]: '如果电子邮件未在 15 分钟内到达，请检查垃圾邮件文件夹。如果电子邮件突然进入此文件夹，请打开电子邮件，单击“非垃圾邮件”并单击确认链接。如果邮件不在“垃圾邮件”文件夹中，请再次尝试订阅。您可能输入了错误的地址.',

    [keys.resetPassword.forgotPasswordPage.title]: '忘记密码?',
    [keys.resetPassword.forgotPasswordPage.reset]: '重设密码',

    [keys.welcomePage.tryCheat]: '立即尝试最好的 Dota 2 脚本、插件并感受它的强大功能!',
    [keys.welcomePage.dontOpenMouths]: '不要张开嘴——海军上将在船上!',
    [keys.welcomePage.registerNow]: '现在注册',
    [keys.welcomePage.registration]: '登记',
    [keys.welcomePage.optimized]: '我们的脚本和插件是最优化的!',
    [keys.welcomePage.littleFps]: '所以忘记带脚本的小 fps!',
    [keys.welcomePage.cool]: '脚本是一个令人难以置信的优势！你可以看到敌人的所有动作，突出眩晕和目标能力！一句话—​​—酷.',
    [keys.welcomePage.login]: '登录',
    [keys.welcomePage.signUp]: '登录系统',
    [keys.welcomePage.aboutUs]: '关于我们',
    [keys.welcomePage.agreement]: '协议',
    [keys.welcomePage.privacy]: '隐私',
    [keys.welcomePage.contacts]: '联系人',
    [keys.welcomePage.feature]: '特征',

    [keys.welcomePage.top.stream]: 'Live stream',

    [keys.welcomePage.carousel.one.title]: '该领域久经考验且自信的领导者!',
    [keys.welcomePage.carousel.one.text]: '也许是游戏中最好的脚本和插件',
    [keys.welcomePage.carousel.one.action]: '学到更多',
    [keys.welcomePage.carousel.one.action2]: '购买',

    [keys.welcomePage.carousel.two.title]: 'Dota 2 脚本领域的新​​时代!',
    [keys.welcomePage.carousel.two.text]: 'JOIN OUR SERVER',
    [keys.welcomePage.carousel.two.text2]: 'GET GIFTS!',
    [keys.welcomePage.carousel.two.action]: 'About Us',
    [keys.welcomePage.carousel.two.action2]: 'Join',

    [keys.welcomePage.carousel.three.title]: '未来比你想象的更近！ © 神圣的',
    [keys.welcomePage.carousel.three.text]: 'FORGET ABOUT LOSING WITH ECO SYSTEM',
    [keys.welcomePage.carousel.three.text2]: `'THE WORLD'S FIRST PLUGIN WITH A PERSONAL CORE TO WORK WITH! 0% ERRORS'`,
    [keys.welcomePage.carousel.three.action]: 'Watch the review',
    [keys.welcomePage.carousel.three.action2]: 'Purchase',

    [keys.welcomePage.carousel.four.title]: 'A NEW ERA IN THE FIELD OF SCRIPTS FOR Dota 2!',
    [keys.welcomePage.carousel.four.text]: 'JOIN',
    [keys.welcomePage.carousel.four.text2]: 'COMMUNITY',
    [keys.welcomePage.carousel.four.text3]: 'IN VKONTAKTE AND GET GIFTS!',
    [keys.welcomePage.carousel.four.action]: 'About us',
    [keys.welcomePage.carousel.four.action2]: 'Join',

    [keys.welcomePage.carousel.five.title]: 'Winning with Divine is Easy!',
    [keys.welcomePage.carousel.five.text]: 'NEW INTELLIGENT',
    [keys.welcomePage.carousel.five.text2]: 'HEX LION IS NOT A PROBLEM!',
    [keys.welcomePage.carousel.five.action]: 'Watch the review',
    [keys.welcomePage.carousel.five.action2]: 'Purchase',

    [keys.welcomePage.welcomeText.title]: 'Welcome to Divine!',
    [keys.welcomePage.welcomeText.text]: 'Enter the Dota 2 scripts game zone - Together with Divine!',

    [keys.welcomePage.description]: 'Our unique scripts opens up incredible opportunities \n'+
' to raise your rating! Functions camera distance, \n' +
'highlighting enemies through the fog of war, automatic uport \n'+
' and finishing off creeps are only a small part of our functions. \n'+
'We provide new users with a trial period \n'+
' for subscribing to our communities and linking VK or Discord.',

    [keys.welcomePage.halloween.text]: 'Discount weekend',
    [keys.welcomePage.halloween.text2]: '25% discount on subscription',
    [keys.welcomePage.halloween.text3]: 'From October 31st to November 2nd',
    [keys.welcomePage.halloween.action]: 'Go to play',

    [keys.welcomePage.crasher.text]: 'The new crasher is already available',
    [keys.welcomePage.crasher.text2]: 'Available by a separate subscription for each user',
    [keys.welcomePage.crasher.text3]: 'Watch video',
    [keys.welcomePage.crasher.text4]: 'Please use with caution. Non-refundable (considering the fix from Valve).',
    [keys.welcomePage.crasher.action]: 'Purchase',

    [keys.welcomePage.youtube.title]: '立即观看评论!',
    [keys.welcomePage.youtube.text]: 'Immerse yourself in your favorite game with Divine - Dota 2!',
    [keys.welcomePage.youtube.action]: 'Watch Divine reviews',

    [keys.welcomePage.buy.title]: '以优惠的价格订阅!',
    [keys.welcomePage.buy.action]: '成功购买',
    [keys.welcomePage.buy.price]: '价格',
    [keys.welcomePage.buy.client]: '客户',

    [keys.welcomePage.buy.item1.pre1]: '销售主管',
    [keys.welcomePage.buy.item1.pre2]: 'IN THE PRODUCT OF THE MONTH SEGMENT',
    [keys.welcomePage.buy.item1.name ]: '7 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item1.price]: '$ 3.01',

    [keys.welcomePage.buy.item2.pre1]: 'SALES LEADER',
    [keys.welcomePage.buy.item2.pre2]: 'IN THE PRODUCT OF THE YEAR SEGMENT',
    [keys.welcomePage.buy.item2.name ]: 'SUBSCRIPTION FOR 14 DAYS',
    [keys.welcomePage.buy.item2.price]: '$ 5.32',

    [keys.welcomePage.buy.item3.pre1]: 'IN THE PRODUCT OF THE YEAR SEGMENT',
    [keys.welcomePage.buy.item3.pre2]: 'POPULAR CHOICE',
    [keys.welcomePage.buy.item3.name ]: '30 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item3.price]: '$ 10.08',

    [keys.welcomePage.buy.item4.pre1]: 'BEST PRICE',
    [keys.welcomePage.buy.item4.pre2]: 'THE BEST CHOICE TO BUY',
    [keys.welcomePage.buy.item4.name ]: '90 DAYS SUBSCRIPTION',
    [keys.welcomePage.buy.item4.price]: '$ 28.80',

    [keys.welcomePage.trialGamesBlock.title]: 'Win and up MMR',
    [keys.welcomePage.trialGamesBlock.title2]: 'Together with DIVINE!',
    [keys.welcomePage.trialGamesBlock.description]: 'DOTA 2 脚本',
    [keys.welcomePage.trialGamesBlock.tryToFree]: '免费试用',
    [keys.welcomePage.trialGamesBlock.modal.title]: 'GETTING A TRIAL PERIOD',
    [keys.welcomePage.trialGamesBlock.modal.title2]: '我们为所有用户提供试用期.',
    [keys.welcomePage.trialGamesBlock.modal.p]: '1. 我们为所有订阅我们社交网络的用户提供试用期 ',
    [keys.welcomePage.trialGamesBlock.modal.p1]: 'or',
    [keys.welcomePage.trialGamesBlock.modal.p2]: '2. 在网站上注册并创建您的帐户',
    [keys.welcomePage.trialGamesBlock.modal.p22]: '在您的个人帐户中',
    [keys.welcomePage.trialGamesBlock.modal.p3]: '3. 下载并运行客户端。',
    [keys.welcomePage.trialGamesBlock.modal.p4]: '4. 你可以进入游戏.',
    [keys.welcomePage.trialGamesBlock.modal.p5]: '5. Chinese users can also try it for free, just visit the QQ group (http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=hOhI47NCYL8B6wrOwTqIBGno5NNn55SG&authKey=PjIn40tQmKaQ2gV%2BccNlw3clKsp0%2BnAL6YxmmrhGlYdfXQitCeD5o4eEPYIVfcYn&noverify=0&group_code=1146367786)',
    [keys.welcomePage.trialGamesBlock.modal.action]: '订阅 VKontakte 组',

    [keys.cookies.text]: '我们使用 cookie 来快速方便地操作网站。继续使用本网站，即表示您接受',
    [keys.cookies.text2]: '个人资料处理条件',

    [keys.aboutUs.title]: '关于我们',
    [keys.aboutUs.text]: '我们是著名的 Dota 2 Ensage 项目的前作者，目前我们正在制作我们自己的名为 Divine 的项目，具有相同的酷脚本和功能!',
    [keys.aboutUs.back]: '返回主页面!',

    [keys.notifications.payment]: '付款成功完成。添加',
    [keys.notifications.added]: '添加 ',
    [keys.notifications.successDone]: '支付成功',
    [keys.notifications.processingDescription]: '付款通常会在 5 分钟内收到',
    [keys.notifications.processing]: '我们处理付款 ',
    [keys.notifications.plugin_updated]: '插件 {{name}} 刚刚更新',
    [keys.notifications.client_update_ready]: '客户端更新已准备好下载',

    [keys.subInfo.title]: '购买订阅',
    [keys.subInfo.block.buySub]: '购买订阅',
    [keys.subInfo.block.subTime.subTime]: '订阅 {{count}} 天',
    [keys.subInfo.block.subTime.day_plural]: '订阅 {{count}} 天',
    [keys.subInfo.block.feature1]: '访问所有功能',
    [keys.subInfo.block.feature2]: '快速帮助支持',
    [keys.subInfo.block.feature3]: '获得不可否认的优势',
    [keys.subInfo.block.feature4]: '每天不限次数的游戏',
    [keys.subInfo.extra]: '或在评级模式下获得 1 场免费游戏',
    [keys.subInfo.extra_color]: '注册',

    [keys.configPage.author]: '作者',
    [keys.configPage.all.title]: '用户配置',
    [keys.configPage.all.install]: '安装',
    [keys.configPage.myConfigs.enabled]: '启用',
    [keys.configPage.myConfigs.disabled]: '已禁用',
    [keys.configPage.myConfigs.title]: '你的配置',
    [keys.acceptPayments]: '我们接受付款',
};

export default translations;
