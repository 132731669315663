import React from "react";
import {Redirect, Route} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import useAuth from "../Hooks/useAuth";
import _ from "underscore";
import {Icon} from "semantic-ui-react";

export type IPrivateRouteProps = {
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
    exact?: boolean,
    path: string,
    claimsToHave?: string[]
}
const maintenance = false;

const PrivateRoute: React.FC<IPrivateRouteProps> = ({Component, claimsToHave, ...rest}) => {
    const {isAuthenticated, claims} = useAuth();

    const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;

    if (maintenance) {
        const disabled = _.every(['r_test_panel', 'r_statistics'], x => _.contains(claims, x));
        if (!disabled) {
            return <Route
                {...rest}
                render={(props) => <div className="maintenance">
                    <Icon name={'cogs'} size={'massive'}/>

                    <div>We are on maintenance</div>
                    <div> Coming soon...</div>

                </div>}
            />
        }
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated && hasClaims
                    ? <Component {...props} />
                    : <Redirect to="/authorize"/>
            }
        />
    )
}
export default PrivateRoute

