import React, {useEffect, useState} from "react";
import {AuthContext, IAuthTypeStatus} from "../Context/RootContext";
import useProgress from "./useProgress";
import Constants from "../Configuration";

const useDiscordApiService = () => {
    const {error, isLoading, BeginLoading} = useProgress();
    const state = React.useContext(AuthContext)
    // const [alreadyLinked, setAlreadyLinked] = useState(false);

    // useEffect(() => {
    //     shouldShowCheck();
    // }, []);


    const saveCode = async (code: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint +  `Discord/${code}`);
            return data.data;
        })
    }
    // const shouldShowCheck = async () => {
    //     return await BeginLoading(async () => {
    //         const data = await state.apiService.callApi(Constants.apiEndpoint +  `Discord/check`);
    //         setAlreadyLinked(data.data);
    //         return data.data;
    //     })
    // }
    return {
        saveCode,
        isLoading,
        error,
        // alreadyLinked
    }
}

export default useDiscordApiService
