import React from "react";

export type IProgressBarComponentProps = {
    value: number;
    title: string;
}

const ProgressBarComponent = ({value, title}: IProgressBarComponentProps) => {
    return <div className="progress-bar">
        <div className="bar">
            <div className="bar-inside" style={{width: `${value}%`}}/>
        </div>
        <div className="title">{title}</div>
    </div>
}

export default ProgressBarComponent;
