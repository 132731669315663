import useAuth from "./useAuth";
import {useState} from "react";
import useProgress from "./useProgress";
import Constants from "../Configuration";

export type IStats = {
    statType: number,
    count: number,
    created: string,
    id: number,
}

export type IGroupedToken = {
    reason: number, count: number, tokens: any[]
}

export type ICounterStats = {
    registeredUsersCounts: number,
    logsCount: number,
    scriptsCount: number,
    onlineUsersCount: number,
    onlineOnSiteCount: number,
    totalPlayedWithWebPerDay: { count: number, dateTime: Date }[]
    totalPlayedWithClientPerDay: { count: number, dateTime: Date }[]
    groupedTokens: IGroupedToken[],
    totalTokens: number
}

const useStats = () => {
    const {state} = useAuth();
    const [stats, setStats] = useState([] as IStats[]);
    const [countStats, setCountStats] = useState({
        logsCount: 0,
        scriptsCount: 0,
        onlineUsersCount: 0,
        registeredUsersCounts: 0,
        onlineOnSiteCount: 0,
        // groupedTokens: [],
        totalTokens: 0,
        // totalPlayedWithWebPerDay: 0,
        // totalPlayedWithClientPerDay: 0
    } as ICounterStats);
    const {
        error,
        isLoading,
        BeginLoading
    } = useProgress();

    const getStats = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + 'stats');
            setStats(data.data.data);
        })
    }
    const getCountStats = async () => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.apiEndpoint + 'stats/counts');
            setCountStats(data.data);
        })
    }

    return {
        getStats,
        stats,
        isLoading,
        error,
        countStats,
        getCountStats
    }
}

export default useStats;
