import React, {useEffect, useState} from "react";
import useLogs from "../Hooks/useLogs";
import Scrollbars from "rc-scrollbars";
import moment from "moment";
import CircleLoader from "../Components/CircleLoader";

const classNames = require('classnames');
const LogsPage = () => {
    const {getLogs, isLoading} = useLogs();
    const [logs, setLogs] = useState([] as any[]);
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const fetch = async () => {
        const result = await getLogs(page, 25, {
            logLevels: [],
            plugins: [],
            users: []
        });
        if (result) {
            setLogs(
                [...logs, ...result.result]
            );
            setTotalCount(result.filter.total);
        }
    }

    useEffect(() => {
        fetch();
    }, [page])

    const onUpdateHandler = ({top}: any) => {
        if (top === 1 && !isLoading) {
            setPage(prevState => prevState + 1)
        }
    }


    const cutString = (string: string, length: number = 50) => {
        if (!string)
            return '';
        return string.length > length ?
            string.substring(0, length - 3) + "..." :
            string;
    }

    const levels = ['Trace', 'Debug', 'Information', 'Warning', 'Error', 'Critical', 'None']

    const getLogName = (logLevel: number) => {
        return levels[logLevel]
    }

    const getIconLevelClass = (levelName: number) => {
        return classNames('level-round',
            {'level-round-trace': levelName === 0},
            {'level-round-debug': levelName === 1},
            {'level-round-info': levelName === 2},
            {'level-round-warning': levelName === 3},
            {'level-round-error': levelName === 4},
            {'level-round-critical': levelName === 5},
        );
    }

    return <div className={'logs-page'}>
        <div className="top">
            <div>

                <div className="title">Все сообщения логов и крашей</div>
                <div className="total">Общее количество: {totalCount}шт.</div>
            </div>
            <div className="spacer"/>
            <div className="btn btn-blue btn-clear">Очистить базу от логов</div>
            <div className="page-settings">
                <div className="sort">
                    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.12857 4.26839L3.31563 2.12548C3.48647 1.95815 3.7636 1.9582 3.93434 2.12548L6.12132 4.26839C6.39667 4.53812 6.20059 5.00001 5.81195 5.00001H4.5V13.5714C4.5 13.8081 4.30414 14 4.0625 14H3.1875C2.94586 14 2.75 13.8081 2.75 13.5714V5.00001H1.43794C1.04853 5.00001 0.853791 4.53758 1.12857 4.26839ZM7.5625 3.7143H14.5625C14.8041 3.7143 15 3.52243 15 3.28573V2.42858C15 2.19188 14.8041 2.00001 14.5625 2.00001H7.5625C7.32086 2.00001 7.125 2.19188 7.125 2.42858V3.28573C7.125 3.52243 7.32086 3.7143 7.5625 3.7143ZM7.125 6.71429V5.85715C7.125 5.62045 7.32086 5.42858 7.5625 5.42858H12.8125C13.0541 5.42858 13.25 5.62045 13.25 5.85715V6.71429C13.25 6.951 13.0541 7.14287 12.8125 7.14287H7.5625C7.32086 7.14287 7.125 6.951 7.125 6.71429ZM7.125 13.5714V12.7143C7.125 12.4776 7.32086 12.2857 7.5625 12.2857H9.3125C9.55414 12.2857 9.75 12.4776 9.75 12.7143V13.5714C9.75 13.8081 9.55414 14 9.3125 14H7.5625C7.32086 14 7.125 13.8081 7.125 13.5714ZM7.125 10.1429V9.28572C7.125 9.04901 7.32086 8.85715 7.5625 8.85715H11.0625C11.3041 8.85715 11.5 9.04901 11.5 9.28572V10.1429C11.5 10.3796 11.3041 10.5714 11.0625 10.5714H7.5625C7.32086 10.5714 7.125 10.3796 7.125 10.1429Z"
                            fill="#C5C7CD"/>
                    </svg>

                    Сортировка
                </div>
                <div className="filter">
                    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.4369 2H2.56315C2.06351 2 1.8114 2.60623 2.16542 2.96026L6.5 7.29549V12.125C6.5 12.3085 6.58955 12.4805 6.73993 12.5858L8.61493 13.8979C8.98484 14.1568 9.5 13.8944 9.5 13.437V7.29549L13.8347 2.96026C14.188 2.60694 13.9376 2 13.4369 2Z"
                            fill="#C5C7CD"/>
                    </svg>
                    Фильтр
                </div>
            </div>
        </div>
        <div className="table-container">
            <table className="table">
                <thead>
                <tr>
                    <th>Сообщение ошибки - краша</th>
                    <th>Время и пользователь</th>
                    <th>Плагин</th>
                    <th>Уровень</th>
                </tr>
                </thead>
            </table>
            {isLoading && <div className="loading">
                <CircleLoader isLoading={true}/>
            </div>}
            <Scrollbars autoHide
                        onUpdate={onUpdateHandler}>

                <table className="table">
                    <tbody>
                    {logs && logs.map && logs.map((log: any, index) => {
                        return (
                            <tr key={index}>
                                <td className='message'>{cutString(log.message, 250)}</td>
                                <td className='centered'>
                                    <div className="col-container">
                                        <div className="user">{log.user}</div>
                                        <div className="time">{moment(log.logTime).format('HH:MM:SS L')}</div>
                                    </div>
                                </td>
                                <td className='centered'>
                                    <div className="col-container">
                                        <div className="plugin-name">{log.assembly}</div>
                                        <div className="plugin-author">Developer</div>
                                    </div>
                                </td>
                                <td className='centered level'>
                                    <div className={getIconLevelClass(log.level)}>
                                        {getLogName(log.level)}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </Scrollbars>
        </div>
    </div>
}

export default LogsPage;
