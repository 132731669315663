import React from "react";
import {AuthContext} from "../Context/RootContext";
import Constants from "../Configuration";
import useProgress from "./useProgress";
import axios from 'axios';

export type LogFilters = {
    "logLevels": Array<number>,
    "plugins": Array<string>,
    "users": Array<string>,
    [key: string]: Array<string | number>
}

const useLogs = () => {
    const state = React.useContext(AuthContext)
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        error,
        isLoading,
        BeginLoading
    } = useProgress();

    const getLogs = async (start: number, size: number, filter: LogFilters) => {
        return await BeginLoading(async () => {
            const data = await axios.post(Constants.apiEndpoint2 + `/${start}/${size}`, filter)
            return data.data;
        })
    }
    const getLog = async (id: number) => {
        return await BeginLoading(async () => {
            const data = await axios.get(Constants.apiEndpoint2 + `/${id}`)
            return data.data;
        })
    }
    const getBuildLog = async (id: string) => {
        return await BeginLoading(async () => {
            const data = await axios.get(Constants.apiEndpoint + `download/buildLog/${id}`)
            return data.data;
        })
    }

    const clearLogs = async () => {
        return await BeginLoading(async () => {
            const data = await axios.delete(Constants.apiEndpoint2)
            return data.data;
        })
    }

    return {
        state,
        getLogs,
        getLog,
        getBuildLog,
        clearLogs,
        MessageContainer,
        MessagePanel,
        error,
        isLoading
    }
}

export default useLogs
