import useSubscribe from "../../Hooks/useSubscribe";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import useAuth from "../../Hooks/useAuth";
import _ from "underscore";
import keys from "../../translations/keys";
import {useTranslation} from "react-i18next";
import {DataContext} from "../../Context/DataContext";

type IItem = {
    svg: string;
    title: string;
    value: string;
}

const UserInfoBlock = () => {
    const {isLoading, subEnd, crasherEnd, totalGamePlayed, isEmailConfirmed, email} = useContext(DataContext);
    const {generateToken, activateToken, /*getSubInfo, getGamesCount*/} = useSubscribe();
    // const {
    //     changePassword,
    //     getHwid,
    //     resetHwid,
    //     error,
    //     userInfo,
    //     sendEmailVerification,
    //     changeEmail,
    //     loadUserInfoAndSave
    // } = useAccount();
    const {user, claims} = useAuth();
    const claimsToHave = ['r_infinity_games'];
    const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
    const {t} = useTranslation();


    const [daysLeft, setDatesLeft] = useState('loading...');
    const [crashLeft, setCrashLeft] = useState('loading...');

    function formatDuration(durationInDays: number): string {
        const days = Math.floor(durationInDays);
        const hours = Math.floor((durationInDays - days) * 24);
        const minutes = Math.floor(((durationInDays - days) * 24 - hours) * 60);

        return `${days}d ${hours}h ${minutes}m`;
    }

    useEffect(() => {
        if (subEnd) {
            const timeExp = moment.utc(subEnd);
            const now = moment().local();

            if (timeExp.isAfter(now)) {
                const duration = moment.duration(timeExp.diff(now))
                let final = formatDuration(duration.asDays());
                setDatesLeft(`${final} left`);
            } else {
                setDatesLeft('no sub');
            }
        }
    }, [subEnd])

    useEffect(() => {
        if (crasherEnd) {
            const timeExp = moment.utc(crasherEnd);
            const now = moment().local();

            if (timeExp.isAfter(now)) {
                const duration = moment.duration(timeExp.diff(now))
                let final = formatDuration(duration.asDays());
                setCrashLeft(`${final} left`);
            } else {
                setCrashLeft('no sub');
            }
        }
    }, [crasherEnd])

    // const GetCountOfGamesHandler = async () => {
    //     const count = await getGamesCount();
    //     setCountOfGames(count);
    // }

    // useEffect(() => {
    //     GetSubInfoHandler()
    //     GetCountOfGamesHandler();
    // }, [])
    const userIcon = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.0002 55.0001C26.2204 55.0107 22.4883 54.1555 19.0903 52.5C17.8466 51.8951 16.6551 51.1885 15.5277 50.3875L15.1852 50.1375C12.0848 47.849 9.54993 44.8806 7.77525 41.46C5.93959 37.9197 4.98752 33.9879 5.00012 30C5.00012 16.1929 16.1931 5 30.0002 5C43.8074 5 55.0004 16.1929 55.0004 30C55.0128 33.986 54.0617 37.9159 52.2277 41.455C50.4555 44.8736 47.9242 47.841 44.8278 50.13C43.6597 50.985 42.4201 51.7378 41.1227 52.38L40.9228 52.48C37.5226 54.1443 33.7859 55.0064 30.0002 55.0001ZM30.0002 42.5C26.2539 42.4927 22.8194 44.5851 21.1077 47.9175C26.7112 50.693 33.2893 50.693 38.8927 47.9175V47.905C37.179 44.5762 33.7443 42.4885 30.0002 42.5ZM30.0002 37.5C35.4155 37.5071 40.4088 40.4253 43.0728 45.14L43.1103 45.1075L43.1453 45.0775L43.1028 45.115L43.0778 45.135C49.4002 39.6728 51.661 30.8558 48.7469 23.0253C45.8328 15.1947 38.358 10.0008 30.0028 10.0008C21.6475 10.0008 14.1727 15.1947 11.2586 23.0253C8.34452 30.8558 10.6053 39.6728 16.9277 45.135C19.5934 40.4226 24.5862 37.5066 30.0002 37.5ZM30.0002 35C24.4774 35 20.0002 30.5228 20.0002 25C20.0002 19.4772 24.4774 15 30.0002 15C35.5231 15 40.0003 19.4772 40.0003 25C40.0003 27.6522 38.9467 30.1957 37.0713 32.0711C35.196 33.9464 32.6524 35 30.0002 35ZM30.0002 20C27.2388 20 25.0002 22.2386 25.0002 25C25.0002 27.7614 27.2388 30 30.0002 30C32.7617 30 35.0002 27.7614 35.0002 25C35.0002 22.2386 32.7617 20 30.0002 20Z"
            fill="#F2F2F2"/>
    </svg>

    const mainIcon = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M50 55H10C7.23858 55 5 52.7614 5 50V22.6225C5.08438 20.9446 5.9932 19.4172 7.4275 18.5425L27.4275 6.54249C29.0103 5.59342 30.9872 5.59342 32.57 6.54249L52.57 18.5425C54.073 19.4494 54.9941 21.0746 55 22.83V50C55 52.7614 52.7614 55 50 55ZM10 24.67V50H50V24.67L30 38.0025L10 24.67ZM30 10.83L13.29 20.855L30 31.995L46.7075 20.855L30 10.83Z"
            fill="white"/>
    </svg>

    const regIcon = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.4976 55H12.4976C9.73613 55 7.49756 52.7614 7.49756 50V15C7.49756 12.2386 9.73613 10 12.4976 10H17.4976V5H22.4976V10H37.4976V5H42.4976V10H47.4976C50.259 10 52.4976 12.2386 52.4976 15V50C52.4976 52.7614 50.259 55 47.4976 55ZM12.4976 25V50H47.4976V25H12.4976ZM12.4976 15V20H47.4976V15H12.4976Z"
            fill="#F2F2F2"/>
    </svg>

    const subIcon = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30 55C16.1929 55 5 43.8071 5 30C5 16.1929 16.1929 5 30 5C43.8071 5 55 16.1929 55 30C54.9848 43.8008 43.8008 54.9848 30 55ZM30 10C18.9543 10 10 18.9543 10 30C10 41.0457 18.9543 50 30 50C41.0457 50 50 41.0457 50 30C49.9876 18.9594 41.0406 10.0124 30 10ZM42.5 32.5H27.5V17.5H32.5V27.5H42.5V32.5Z"
            fill="#F2F2F2"/>
    </svg>

    const renderBlock = (icon: any, title: string, value: any) => {
        return <div className="user-info-block__sub">
            <div className="icon">{icon}</div>
            <div className="content">
                <div className="content--title">{title}</div>
                <div className="content--value">{_.isFunction(value) ? value() : value}</div>
            </div>
        </div>
    }

    const style: React.CSSProperties = {
        borderRadius: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderColor: 'rgba(0,0,0,0.5)'
        // opacity: 0.7,
        // padding: '2em',
    }

    // return <Popup
    //     hoverable
    //     style={style}
    //     basic
    //     trigger={
    //         <div className={'email-hover'}>{userInfo.email}</div>
    //     }
    //     content={<Button circular color='green' content={t(keys.account.confirmEmail)}/>}
    //     on={['click', 'hover']}
    //     position='right center'
    // />

    return <div className="user-info-block">
        {renderBlock(userIcon, t(keys.mainPage.userInfoBlock.userId.title), user?.profile?.sub ?? 'loading...')}
        {renderBlock(mainIcon, t(keys.mainPage.userInfoBlock.email.title), email ?? 'loading...')}
        {renderBlock(regIcon, t(keys.mainPage.userInfoBlock.playedGamesCount.title), totalGamePlayed ? `${totalGamePlayed} games` : 'loading...')}
        {renderBlock(subIcon, t(keys.mainPage.userInfoBlock.subTime.title), hasClaims ? t(keys.mainPage.userInfoBlock.subTime.infinity) : daysLeft ?? 'loading...')}
        {/*{renderBlock(subIcon, t(keys.mainPage.userInfoBlock.crashTime.title), crashLeft ?? 'loading...')}*/}
    </div>
}

export default UserInfoBlock;
