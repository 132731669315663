import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import Dropdown, {IDropdownOption} from "./Dropdown";

export type IProps = {
    search: string,
    setSearch: any,
    setSelectedFilter: any
}

export enum SearchPanelDropdownOption {
    All,
    Hero,
    Utility,
    Library
}

const SearchPanel = ({search, setSearch, setSelectedFilter}: IProps) => {
    const [isSelected, setSelected] = useState(false)

    const inputEl = useRef(null as any);

    const clickHandler = () => {
        if (!isSelected) {
            setSelected(true)

        }
    }

    useEffect(() => {
        if (isSelected) {
            if (inputEl && inputEl.current)
                inputEl.current.focus();
        }
    }, [isSelected])


    const onBlur = () => {
        if (search.length === 0)
            setSelected(false)
    }

    const onChangeHandler = (e: any) => {
        e.preventDefault()
        const value = e.target.value;
        if (!value)
            setSearch('')
        else
            setSearch(value)
    }

    const getClass = () => {
        return classNames('panel', {
            'panel-active': isSelected
        })
    }

    const options: IDropdownOption[] = [
        {id: SearchPanelDropdownOption.All, text: 'All'},
        {id: SearchPanelDropdownOption.Hero, text: 'Hero'},
        {id: SearchPanelDropdownOption.Utility, text: 'Utility'},
        {id: SearchPanelDropdownOption.Library, text: 'Library'},
    ];



    const onOptionSelect = (option: IDropdownOption) => {
        setSelectedFilter(option);
    }

    return (
        <div className={getClass()}>
            <div className={'text-with-icon'}>
                {isSelected ?
                    <>
                        <div className="icon">
                            <svg width="1.0em" height="1.0em" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z"
                                      fill="white" fillOpacity="0.74"/>
                            </svg>
                        </div>
                        <input ref={inputEl} value={search} onChange={onChangeHandler} type="text" onBlur={onBlur}
                               placeholder='Type plugin name'/></> :
                    <>
                        <div className="text" onClick={clickHandler}>Click for search</div>
                        <div className="icon">
                            <svg width="1.0em" height="1.0em" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z"
                                      fill="white" fillOpacity="0.74"/>
                            </svg>
                        </div>
                    </>
                }
            </div>
            <div className="btn"><Dropdown items={options} text={'search'} onSelect={onOptionSelect}/></div>

        </div>
    )


}
export default SearchPanel;
