import React, {useEffect} from "react";
import {Line} from 'react-chartjs-2';
import useStats from "../Hooks/useStats";
import moment from "moment";
import Scrollbars from "rc-scrollbars";

enum TokenCreateReason
{
    Unset,
    Vk,
    Reseller,
    Digseller,
    Paypal
}

const StatsPage = () => {
    const {isLoading, error, getStats, stats, getCountStats, countStats} = useStats();
    useEffect(() => {
        getStats();
        getCountStats();
    }, [])


    const onlineUsers = stats.filter(stat => stat.statType === 0);
    const onlineUsersWithSoft = stats.filter(stat => stat.statType === 3);

    // const onlineData = {
    //     labels: onlineUsers.map(x => moment(x.created).format('L')),
    //     datasets: [
    //         {
    //             label: 'Online',
    //             data: onlineUsers.map(x => x.count).filter(x=>x > 0),
    //             fill: false,
    //             backgroundColor: 'rgb(255, 99, 132)',
    //             borderColor: 'rgba(255, 99, 132, 0.2)',
    //         },
    //     ],
    // }

    const registeredUsers = stats.filter(stat => stat.statType === 1);

    const usersData = {
        labels: registeredUsers.map(x => moment(x.created).format('L')),
        datasets: [
            {
                label: 'Registered',
                data: registeredUsers.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            }
        ],
    }
    const usersOnlineData = {
        labels: onlineUsers.map(x => moment(x.created).format('L')),
        datasets: [
            {
                label: 'Online',
                data: onlineUsers.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(11,47,229)',
                borderColor: 'rgb(215,168,25)',
            },
            {
                label: 'Online with soft',
                data: onlineUsersWithSoft.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(13,233,207)',
                borderColor: 'rgb(171,88,43)',
            },
        ],
    }
    const totalUsersOnlineData = {
        labels: countStats.totalPlayedWithWebPerDay?.map(x => moment(x.dateTime).format('L')),
        datasets: [
            {
                label: 'Web',
                data: countStats.totalPlayedWithWebPerDay?.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(11,47,229)',
                borderColor: 'rgb(215,168,25)',
            },
            {
                label: 'Soft',
                data: countStats.totalPlayedWithClientPerDay?.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(13,233,207)',
                borderColor: 'rgb(171,88,43)',
            },
        ],
    }

    const logsCount = stats.filter(stat => stat.statType === 2);



    const logsData = {
        labels: logsCount.map(x => moment(x.created).format('L')),
        // datasets:
        datasets: [
            {
                label: 'logs',
                data: logsCount.map(x => x.count).filter(x=>x > 0),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: false,
                    },
                },
            ],
        },
    }

    const dataSetForTokens: any = [];
    countStats?.groupedTokens?.forEach(x => {
        const tokens = x.tokens;
        const activated = tokens.filter(z=>z.isUsed)
        dataSetForTokens.push({
            label: TokenCreateReason[x.reason],
            data: `${activated.length}/${tokens.length}`
        })
    })

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return <div className="stats-page">
        <Scrollbars>
        <div className="stats-page-block icons">
            <div className="info-block">
                <div className="icon">
                    <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30 12C33.32 12 35.98 9.32 35.98 6C35.98 2.68 33.32 0 30 0C26.68 0 24 2.68 24 6C24 9.32 26.68 12 30 12ZM14 12C17.32 12 19.98 9.32 19.98 6C19.98 2.68 17.32 0 14 0C10.68 0 8 2.68 8 6C8 9.32 10.68 12 14 12ZM14 16C9.34 16 0 18.34 0 23V28H28V23C28 18.34 18.66 16 14 16ZM30 16C29.42 16 28.76 16.04 28.06 16.1C30.38 17.78 32 20.04 32 23V28H44V23C44 18.34 34.66 16 30 16Z"
                            fill="#56657F"/>
                    </svg>
                </div>
                <div className="counter">{countStats.registeredUsersCounts}</div>
                <div className="footer">
                    Пользователи
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30 12C33.32 12 35.98 9.32 35.98 6C35.98 2.68 33.32 0 30 0C26.68 0 24 2.68 24 6C24 9.32 26.68 12 30 12ZM14 12C17.32 12 19.98 9.32 19.98 6C19.98 2.68 17.32 0 14 0C10.68 0 8 2.68 8 6C8 9.32 10.68 12 14 12ZM14 16C9.34 16 0 18.34 0 23V28H28V23C28 18.34 18.66 16 14 16ZM30 16C29.42 16 28.76 16.04 28.06 16.1C30.38 17.78 32 20.04 32 23V28H44V23C44 18.34 34.66 16 30 16Z"
                            fill="#56657F"/>
                    </svg>
                </div>
                <div className="counter">{countStats.onlineUsersCount}</div>
                <div className="footer">
                    Текущий онлайн
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30 12C33.32 12 35.98 9.32 35.98 6C35.98 2.68 33.32 0 30 0C26.68 0 24 2.68 24 6C24 9.32 26.68 12 30 12ZM14 12C17.32 12 19.98 9.32 19.98 6C19.98 2.68 17.32 0 14 0C10.68 0 8 2.68 8 6C8 9.32 10.68 12 14 12ZM14 16C9.34 16 0 18.34 0 23V28H28V23C28 18.34 18.66 16 14 16ZM30 16C29.42 16 28.76 16.04 28.06 16.1C30.38 17.78 32 20.04 32 23V28H44V23C44 18.34 34.66 16 30 16Z"
                            fill="#56657F"/>
                    </svg>
                </div>
                <div className="counter">{countStats.onlineOnSiteCount}</div>
                <div className="footer">
                    Текущий онлайн на сайте
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M38 6H29.64C28.8 3.68 26.6 2 24 2C21.4 2 19.2 3.68 18.36 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM24 6C25.1 6 26 6.9 26 8C26 9.1 25.1 10 24 10C22.9 10 22 9.1 22 8C22 6.9 22.9 6 24 6ZM20 34L12 26L14.82 23.18L20 28.34L33.18 15.16L36 18L20 34Z"
                            fill="white"/>
                    </svg>

                </div>
                <div className="counter">{countStats.scriptsCount}</div>
                <div className="footer">
                    Скриптов в базе
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24 44C26.2 44 28 42.2 28 40H20C20 42.2 21.78 44 24 44ZM36 32V22C36 15.86 32.72 10.72 27 9.36V8C27 6.34 25.66 5 24 5C22.34 5 21 6.34 21 8V9.36C15.26 10.72 12 15.84 12 22V32L8 36V38H40V36L36 32Z"
                            fill="#56657F"/>
                    </svg>

                </div>
                <div className="counter">0</div>
                <div className="footer">
                    Репорты пользователей
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.46 0H10.54L0 10.54V25.46L10.54 36H25.46L36 25.46V10.54L25.46 0ZM18 28.6C16.56 28.6 15.4 27.44 15.4 26C15.4 24.56 16.56 23.4 18 23.4C19.44 23.4 20.6 24.56 20.6 26C20.6 27.44 19.44 28.6 18 28.6ZM20 20H16V8H20V20Z"
                            fill="#56657F"/>
                    </svg>

                </div>
                <div className="counter">{countStats.logsCount}</div>
                <div className="footer">
                    Ошибки
                </div>
            </div>
            <div className="info-block">
                <div className="icon">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.46 0H10.54L0 10.54V25.46L10.54 36H25.46L36 25.46V10.54L25.46 0ZM18 28.6C16.56 28.6 15.4 27.44 15.4 26C15.4 24.56 16.56 23.4 18 23.4C19.44 23.4 20.6 24.56 20.6 26C20.6 27.44 19.44 28.6 18 28.6ZM20 20H16V8H20V20Z"
                            fill="#56657F"/>
                    </svg>

                </div>
                <div className="counter">{countStats.totalTokens}</div>
                <div className="footer">
                    Всего токенов
                </div>
            </div>
            {dataSetForTokens.map((item: any) => <div className="info-block">
                <div className="icon">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.46 0H10.54L0 10.54V25.46L10.54 36H25.46L36 25.46V10.54L25.46 0ZM18 28.6C16.56 28.6 15.4 27.44 15.4 26C15.4 24.56 16.56 23.4 18 23.4C19.44 23.4 20.6 24.56 20.6 26C20.6 27.44 19.44 28.6 18 28.6ZM20 20H16V8H20V20Z"
                            fill="#56657F"/>
                    </svg>

                </div>
                <div className="counter">{item.data}</div>
                <div className="footer">
                    {item.label}
                </div>
            </div>)}
        </div>
        {/*<div className="stats-page-block chart">*/}
        {/*    <>*/}
        {/*        <div className='header'>*/}
        {/*            <h1 className='title'>Users online</h1>*/}
        {/*        </div>*/}
        {/*        <Line data={onlineData} options={options} height={50}/>*/}
        {/*    </>*/}
        {/*</div>*/}
        <div className="stats-page-block chart">
            <>
                <div className='header'>
                    <h1 className='title'>Users</h1>
                </div>
                {/*// @ts-ignore*/}
                <Line data={usersData} options={options} height={50}/>
            </>
        </div>
        <div className="stats-page-block chart">
            <>
                <div className='header'>
                    <h1 className='title'>Online</h1>
                </div>
                {/*// @ts-ignore*/}
                <Line data={usersOnlineData} options={options} height={50}/>
            </>
        </div>
        <div className="stats-page-block chart">
            <>
                <div className='header'>
                    <h1 className='title'>Total Online</h1>
                </div>
                {/*// @ts-ignore*/}
                <Line data={totalUsersOnlineData} options={options} height={50}/>
            </>
        </div>
        <div className="stats-page-block chart">
            <>
                <div className='header'>
                    <h1 className='title'>Logs</h1>
                </div>
                {/*// @ts-ignore*/}

                <Line data={logsData} options={options} height={50}/>
            </>
        </div>
        </Scrollbars>
    </div>
}

export default StatsPage;
