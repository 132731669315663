import React, {Component} from 'react';
import {Button as UiButton, ButtonProps} from 'semantic-ui-react';
import classNames from 'classnames';

export interface IButtonProps extends ButtonProps {
}

class Button extends Component<IButtonProps, any> {
    render() {
        const {children, className, disabled, ...other} = this.props;
        const cls = classNames('dvn__btn', className, {});

        return (
            <UiButton {...other} disabled={disabled} className={cls}>
                {children}
            </UiButton>
        );
    }
}

export default Button;
