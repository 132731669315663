import React from 'react';
import ReactDOM from 'react-dom';
import {canUseDOM} from './portalUtils';

class Portal extends React.Component<any, any> {
    defaultNode: any;

    componentWillUnmount() {
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode);
        }
        this.defaultNode = null;
    }

    render() {
        if (!canUseDOM) {
            return null;
        }
        const {id, containerId, className, isVisible} = this.props;

        if (!isVisible) {
            if (this.defaultNode) {
                //remove only if have parentNode (case then removing outside component by dom)
                if (this.defaultNode.parentNode) document.body.removeChild(this.defaultNode);
                this.defaultNode = null;
            }
            return null;
        }
        if (!this.props.node && !this.defaultNode) {
            this.defaultNode = document.createElement('div');
            if (id) {
                this.defaultNode.id = id;
            }
            if (className) {
                this.defaultNode.className = className;
            }

            if (containerId) {
                const targetContainerId = document.getElementById(containerId);
                if (targetContainerId)
                    targetContainerId.appendChild(this.defaultNode);
            } else {
                document.body.appendChild(this.defaultNode);
            }
        }
        return ReactDOM.createPortal(this.props.children, this.props.node || this.defaultNode);
    }
}

export default Portal;
