import useAuth from "./useAuth";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {useEffect, useState} from "react";
import {RequestType} from "../Services/ApiService";

export type IConfig = {
    reloadKey: number;
    unloadKey: number;
    fullReloadKey: number;
    fullUnloadKey: number;
    launchOptions: string;
    dotaPath: string;
    dotaConsole: boolean;
    loaderConsole: boolean;
    hwidSpoof: boolean;
    fullSpoof: boolean;
}

export type IConfigList = {
    id: number;
    authorName: string;
    name: string;
    code: string;
    isPublic: boolean;
    isActive: boolean;
    disabledSharing: boolean;
}

const useConfig = () => {
    const {state} = useAuth();
    const [config, setConfig] = useState({
        dotaPath: '',
        launchOptions: '',
        fullReloadKey: 0,
        fullUnloadKey: 0,
        reloadKey: 0,
        unloadKey: 0,
        dotaConsole: false,
        loaderConsole: false
    } as IConfig);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        error,
        isLoading,
        BeginLoading
    } = useProgress();

    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();

    const getConfig = async () => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'Config');
            return result.data as IConfig;
        });
    }
    const getPublicList = async (search: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'Config/GetPublicConfigList/' + search );
            return result.data as IConfigList;
        });
    }

    const getMyConfigList = async () => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'Config/GetConfigList');
            return result.data as IConfigList;
        });
    }

    const onToggleConfig = async (config: IConfigList) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'Config/SetActiveConfig/' + config.id);
            return result.data as IConfigList;
        });
    }

    const setConfigPublicFlag = async (config: IConfigList) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `Config/setConfigPublicFlag/${config.id}/${!config.isPublic}`);
            return result.data as IConfigList;
        });
    }

    const deleteConfig = async (config: IConfigList) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `Config/DeleteConfig/${config.id}`);
            return result.data as IConfigList;
        });
    }

    const installPlugin = async (config: IConfigList) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `Config/CopyConfigById/${config.id}`);
            return result.data as IConfigList;
        });
    }

    const renameConfig = async (configId: number, newName: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `Config/RenameConfig/${configId}/${newName}`);
            return result.data as boolean;
        });
    }

    const installPluginByCode = async (config: IConfigList) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + `Config/CopyConfigByCode/${config.code}`);
            return result.data as IConfigList;
        });
    }

    const saveConfig = async () => {
        // return await BeginLoading(async () => {
        await state.apiService.callApi(Constants.apiEndpoint + 'Config', config, RequestType.POST);
        // });
    }

    useEffect(() => {
        const fetch = async () => {
            const result = await getConfig();
            if (result) {
                setConfig(result)
                setFirstTimeLoaded(true);
            }
        }
        fetch();
    }, [])

    return {
        config,
        saveConfig,
        setConfig,
        isLoading,
        error,
        MessagePanel,
        firstTimeLoaded,
        getMyConfigList,
        onToggleConfig,
        installPluginByCode,
        installPlugin,
        setConfigPublicFlag,
        deleteConfig,
        renameConfig,
        getPublicList
    }
}

export default useConfig;
