import {IPluginItem} from "./PluginItem";
import {useModalState} from "../../Hooks/useModalState";
import {Input, Modal, TextArea} from "semantic-ui-react";
import useScripts, {ITogglePlugin} from "../../Hooks/useScripts";
import Image from "react-image-webp";
import React, {useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import useAuth from "../../Hooks/useAuth";
import claimTypes from "../../claims";
import Scrollbars from "rc-scrollbars";
import Button from "../Button";
import keys from "../../translations/keys";
import {useTranslation} from "react-i18next";
import DropZone, {IDropZoneFileProps} from "../DropZone";
import {useToasts} from "react-toast-notifications";
import PluginImageCropper from "./PluginImageCropper";
import useUploadFiles from "../../Hooks/useUploadFiles";
import _ from "underscore";

export type IPluginOverviewProps = {
    onOpen: () => void;
    isOpen: boolean;
    onToggle: () => void;
    onClose: () => void;

    plugin: ITogglePlugin;
}

export type IEditModeType = {
    isEnabled: boolean;
    description: string;
    youtubeLink: string;
}

const PluginOverview = (props: IPluginOverviewProps) => {
    const {claims} = useAuth();
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState({
        description: props.plugin.description,
        isEnabled: false
    } as IEditModeType);
    const canSeeAdminPanel = claims && claims.some(x => x === claimTypes.updateScript);
    const canSeeEditBtn = props.plugin.isOwner || canSeeAdminPanel;
    const {UpdateDescription, isLoading} = useScripts();
    const {Upload} = useUploadFiles();
    const [filesToUpload, setFilesToUpload] = useState([] as IDropZoneFileProps[])
    const {addToast} = useToasts();
    const claimsToHave = ['r_devpanel_in_loader'];
    const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
    const onFileLoaded = (file: IDropZoneFileProps) => {
        addToast(`Файл загружен на сервер. ${file.name}`, {appearance: "info", autoDismiss: true})
        setFilesToUpload(prevState => {
            return [...prevState, file]
        })
    }
    const onFileError = (arg: any) => {
        addToast(`Файл не загружен на сервер. ${arg}`, {appearance: "error", autoDismiss: true})
    }
    const enableEditMode = () => setEditMode({
        description: props.plugin.description,
        youtubeLink: props.plugin.youTubeLink,
        isEnabled: true
    });

    const updateDescriptionInEditMode = (event: any) => setEditMode({...editMode, description: event.target.value});
    const updateYoutubeLink = (event: any) => setEditMode({...editMode, youtubeLink: event.target.value});
    const disableEditMode = () => setEditMode({...editMode, isEnabled: false});

    const saveEditMode = () => {
        UpdateDescription(props.plugin?.id, editMode.description, editMode.youtubeLink).then(() => {
            disableEditMode();
        })
    };

    const uploadHandler = async (data: Blob) => {
        await Upload(props.plugin.id, data);
    }

    return <Modal open={props.isOpen} onOpen={props.onOpen} onClose={props.onClose} basic>
        <div className="plugin_overview">
            <div className="plugin_overview__name">{props.plugin.pluginName} {hasClaims && <span>(id = {props.plugin.id})</span>}</div>
            {/*<Image className="plugin_overview__logo" src='/divine-main.png' webp='/divine-main.webp' alt={'DIVINE'}/>*/}
            <img className="plugin_overview__logo" src='/Plugins/LogoBig.png' alt={'DIVINE'}/>
            {props.plugin.youTubeLink && <>
                <div className="plugin_overview__title">{t(keys.pluginsPage.video_overview)}</div>
                <div className="player-wrapper">
                    <ReactPlayer muted playing
                                 width='100%'
                                 height='100%'
                                 loop={true}
                                 className='react-player'
                                 config={{
                                     youtube: {
                                         playerVars: {
                                             showInfo: 0,
                                             controls: 1
                                         },
                                         embedOptions: {}
                                     }
                                 }}
                                 url={props.plugin.youTubeLink}/>
                </div>
            </>}

            <div className="plugin_overview__description__title">{t(keys.pluginsPage.description)}</div>
            <div className="plugin_overview__description">
                {!editMode.isEnabled ? <ReactMarkdown>
                    {props.plugin.description}
                </ReactMarkdown> : <>
                    <div className="edit_content">
                        {/*<div className="edit_content__editor">*/}
                        <TextArea className="edit_content__editor" value={editMode?.description}
                                  onChange={updateDescriptionInEditMode}/>
                        {/*</div>*/}
                        <Scrollbars className="edit_content__viewer">
                            <ReactMarkdown>
                                {editMode.description}
                            </ReactMarkdown>
                        </Scrollbars>
                        {/*<div className="dropZone">*/}
                        {/*    <DropZone onFileLoaded={onFileLoaded} onError={onFileError}*/}
                        {/*              apiUrl={`upload/image/plugin/${props.plugin.id}`}*/}
                        {/*              description={'Загрузить картинку'}/>*/}
                        {/*</div>*/}
                        <PluginImageCropper onUpload={uploadHandler}/>
                        <span>Ссылка на ютуб</span>
                        <Input value={editMode.youtubeLink} onChange={updateYoutubeLink} type="text"/>
                    </div>
                </>}
            </div>

            {editMode.isEnabled && <div className={'plugin_overview__description__actions'}>
                <Button onClick={saveEditMode} primary>Save</Button>
                <Button onClick={disableEditMode} secondary>Cancel</Button>
            </div>}

            {canSeeEditBtn && <div className="plugin_overview__action plugin_overview__action--edit"
                                   onClick={enableEditMode}>{editMode.isEnabled ? 'Сбросить' : 'Редактировать'}</div>}
            <div className="plugin_overview__action plugin_overview__action--close"
                 onClick={props.onClose}>{t(keys.pluginsPage.close)}</div>
        </div>
    </Modal>
}

export default PluginOverview;