import useProgress from "./useProgress";
import {useState} from "react";
import axios from "axios";
import Constants from "../Configuration";
import {useHistory} from "react-router-dom";

const useResetPassword = (code: string) => {
    const {error, isLoading, BeginLoading} = useProgress();
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');

    const changePassword = async () => {
        const request = {
            Email: email,
            Password: password,
            ConfirmPassword: passwordConfirm,
            Code: code
        }
        return await BeginLoading(async () => {
            const data: any = await axios.post(Constants.stsAuthority + `account/ResetPassword`, request)
            if (data.data.error){
                throw new Error(data.data.error)
            }
            if (data.status === 200){
                history.push('/authorize')
            }
            return data.data;
        })
    }
    const resetPassword = async () => {
        const request = {
            Email: email,
        }
        return await BeginLoading(async () => {
            const data: any = await axios.post(Constants.stsAuthority + `account/ForgotPassword`, request)
            if (data.data.error){
                throw new Error(data.data.error)
            }
            if (data.status === 200){
                history.push('/reset-password-confirm')
            }
            return data.data;
        })
    }

    return {
        password, setPassword, passwordConfirm, setPasswordConfirm, isLoading, changePassword, email, setEmail, resetPassword
    }
}

export default useResetPassword
