import useAuth from "./useAuth";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {RequestType} from "../Services/ApiService";

const useUploadFiles = () => {
    const {state} = useAuth();
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        BeginLoading,
        error,
        isLoading
    } = useProgress();

    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();

    const Upload = async (id: number, file: any) => {
        return await BeginLoading(async () => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileVersion', '0.0.0.0');
            return await state.apiService.callApi(Constants.apiEndpoint + 'upload/image/plugin/' + id, formData, RequestType.POST);
        });
    }
    const UploadForumImage = async (file: any) => {
        return await BeginLoading(async () => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileVersion', '0.0.0.0');
            return await state.apiService.callApi(Constants.apiEndpoint + 'upload/image/forum', formData, RequestType.POST);
        });
    }

    return {
        Upload,
        UploadForumImage,
    }
}

export default useUploadFiles;
