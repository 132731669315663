import React, {useState} from "react";
import {Container, Dimmer, Loader, Message, Segment} from "semantic-ui-react";
// import style from "../Views/ScriptPage.module.scss";
import {useToasts} from "react-toast-notifications";


const useProgress = () => {
    const [isLoading, setLoading] = useState(false as boolean);
    const [error, setError] = useState(undefined as Error | undefined);

    const MessageContainer = ({children, showMessageHeader = true, showMessageText = true}: any) => {
        return <Segment padded='very'>
            {error?.message && <Message negative color={"red"}>
                {showMessageHeader && <Message.Header>Ошибка при получении ответа с сервера</Message.Header>}
                {showMessageText && <p>{error.message}</p>}
            </Message>}
            <Container>
                {isLoading ?
                    <Dimmer active inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    : <div>
                        {children}
                    </div>
                }
            </Container>
        </Segment>
    }

    const MessagePanel = ({showMessageHeader = true, showMessageText = true, showOnlyError = false}: any) => {
        return <>{((isLoading && !showOnlyError) || error) &&
        <Segment padded='very'>
            {error?.message && <Message negative color={"red"}>
                {showMessageHeader && <Message.Header>Ошибка при получении ответа с сервера</Message.Header>}
                {showMessageText && <p>{error.message}</p>}
            </Message>}
            {isLoading && !showOnlyError &&
            <Dimmer active inverted>
                <Loader>Loading</Loader>
            </Dimmer>}
        </Segment>
        }</>
    }

    const StartLoading = () => setLoading(true);
    const EndLoading = () => setLoading(false);
    const SetError = (error: Error) => setError(error);
    const ClearError = () => setError(undefined);
    const {addToast} = useToasts();
    const BeginLoading = async (callback: () => any) => {
        ClearError();
        StartLoading();
        try {
            const result = await callback();
            // console.log('result', result)
            // if (result?.error) {
            //     if (_.isArray(result.error)){
            //         result?.error.foreEach((error: Error) => {
            //             addToast('An error occurred while loading the data: ' + error, {appearance: "error"})
            //         })
            //         SetError(result?.error[0])
            //     }
            //     else {
            //         SetError(result?.error)
            //         addToast('An error occurred while loading the data: ' + result?.data?.error, {appearance: "error"})
            //     }
            //     return
            // }
            return result;
        } catch (e: any) {
            SetError(e)
            if (e.request.responseType==='blob'){
                console.log(e);
            }
            else if (e.request?.responseText) {
                // The request was made but no response was received
                console.log(e.request);
                addToast(e.request.responseText, {appearance: "error"})
            } else if (e.message) {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', e.message);
                addToast(e.message, {appearance: "error"})
            } else {
                addToast('An error occurred while loading the data: ' + e, {appearance: "error"})
            }

        } finally {
            EndLoading();
        }
    }

    return {
        StartLoading,
        EndLoading,
        SetError,
        ClearError,
        MessageContainer,
        MessagePanel,
        BeginLoading,
        isLoading,
        error
    }
}

export default useProgress;
