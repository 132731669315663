import Button from "./Button";
import {useContext, useEffect} from "react";
import useDiscordApiService from "../Hooks/useDiscordApiService";
import {useToasts} from "react-toast-notifications";
import {DataContext} from "../Context/DataContext";

const DiscordButton = ({btnText}: { btnText?: string }) => {
    const {isLoading: isMainDataLoading, subEnd, isDiscordLinked, isEmailConfirmed, email: currentEmail} = useContext(DataContext);
    const {saveCode, error, isLoading} = useDiscordApiService();
    const {addToast} = useToasts();
    const handleMessage = (event: MessageEvent) => {
        const {data} = event;
        // console.log('event')
        // console.log(event)
        // console.log('data', data)
        if (data && data.match) {
            const regex = /\?code=(.*)/;
            const str = data.match(regex)
            if (str && str[1]) {
                saveCode(str[1]).then(r => {
                    if (r.error) {
                        addToast(r.error, {appearance: 'error', autoDismiss: true});
                    } else {
                        window.location.reload();
                    }
                    // window.removeEventListener('message', handleMessage, false)
                });
            }
        }
    }

    useEffect(() => {
        const params = window.location.search
        if (window.opener) {
            // console.log('params', params)
            window.opener.postMessage(params, "https://divine.wtf")
            // window.opener.postMessage(params, "http://localhost:3000")
            window.close()
        } else {
            // alert('No opener')
        }
    }, [])

    const clickHandler = () => {
        if (isLoading || isMainDataLoading)
            return;
        // const dPopup = window.open(`https://discord.com/api/oauth2/authorize?client_id=858609682968215582&redirect_uri=https%3A%2F%2Fdivine.wtf/%2Faccount&response_type=code&scope=identify%20email`, 'discordLogin', 'height=850,width=500')
        const dPopup = window.open(`https://discord.com/api/oauth2/authorize?client_id=858609682968215582&redirect_uri=https%3A%2F%2Fdivine.wtf%2Faccount&response_type=code&scope=identify%20email`, 'discordLogin', 'height=850,width=500')
        // const dPopup = window.open(`https://discord.com/api/oauth2/authorize?client_id=858609682968215582&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdev&response_type=code&scope=identify%20email`, 'discordLogin', 'height=850,width=500')
        window.addEventListener('message', handleMessage, false)
    }

    return <Button onClick={clickHandler} color={'blue'} circular loading={isLoading || isMainDataLoading} disabled={isDiscordLinked}>
        {btnText ? btnText : 'login'}
    </Button>
}

export default DiscordButton;
