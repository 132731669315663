import Button from "../../Components/Button";
import DiscordButton from "../../Components/DiscordButton";
import {useTranslation} from "react-i18next";
import keys from "../../translations/keys";
import useAccount from "../../Hooks/useAccount";
import {useToasts} from "react-toast-notifications";
import {Input} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import useSubscribe from "../../Hooks/useSubscribe";
import useConfirmationDialog from "../../Hooks/useConfirmationDialog";
import {DataContext} from "../../Context/DataContext";
import VkButton from "../../Components/VkButton";

const OtherSettings = () => {
    const {isLoading: isMainDataLoading, isMainLoaded, subEnd, totalGamePlayed, isEmailConfirmed, email: currentEmail} = useContext(DataContext);
    const {t} = useTranslation();
    const {
        changePassword,
        getHwid,
        resetHwid,
        error,
        isLoading: isLoadingBase,
        // userInfo,
        sendEmailVerification,
        changeEmail,
        loadUserInfoAndSave
    } = useAccount();

    const isLoading = isLoadingBase || isMainDataLoading || !isMainLoaded

    const {addToast} = useToasts();
    const {activateToken} = useSubscribe();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
    });

    useEffect(() => {
        if (currentEmail && currentEmail.length > 0) {
            setEmail(currentEmail);
        }
    }, [currentEmail])

    const {onOpen: onOpenToken, Dialog: TokenDialog} = useConfirmationDialog({
        headerText: t(keys.account.acceptToken),
        contentText: () => {
            return <div className="account-page__modal" style={{width: '100%'}}>
                <Input onChange={(e) => setToken(e.target.value)} focus
                       placeholder={t(keys.account.activateToken)} value={token}
                       style={{width: '100%', padding: '0 5px'}}/>
            </div>
        },
        onConfirmCallback: async () => {
            const response = await activateToken(token);
            if (response.error) {
                handlerError(response.error);
            }
            else {
                window.location.reload();
            }
        }
    });

    const {onOpen: onOpenNewEmail, Dialog: EmailDialog} = useConfirmationDialog({
        headerText: t(keys.account.changeEmail),
        contentText: () => {
            return <div className="account-page__modal" style={{width: '100%'}}>
                <Input onChange={(e) => setEmail(e.target.value)} focus
                       placeholder={t(keys.account.email)}
                       style={{width: '100%', padding: '0 5px'}}/>
            </div>
        },
        onConfirmCallback: async () => {
            const response = await changeEmail(email);
            if (response?.error) {
                handlerError(response.error);
            }
        }
    });

    const changePasswordHandler = async () => {
        if (!passwordData.oldPassword || !passwordData.newPassword)
            return;
        const result = await changePassword(passwordData.oldPassword, passwordData.newPassword);
        if (!result)
            return;
        if (result.error) {
            result.error.forEach((error: any) => {
                    addToast(error.description, {appearance: "error", autoDismiss: true})
                }
            )
        } else {
            setPasswordData({
                oldPassword: '',
                newPassword: '',
            })
            addToast('Password changed successfully', {appearance: "success", autoDismiss: true})
        }
    }

    const {onOpen: onOpenPassword, Dialog: PasswordDialog} = useConfirmationDialog({
        headerText: t(keys.account.changePassword),
        contentText: () => {
            return <div className="account-page__modal" style={{width: '100%'}}>
                <Input onChange={(e) =>
                    setPasswordData(prevState => {
                        return {...prevState, oldPassword: e.target.value}
                    })} type={'password'}
                       focus placeholder={'Old password'} style={{width: '100%', padding: '0 5px'}}/>
                <Input onChange={(e) =>
                    setPasswordData(prevState => {
                        return {...prevState, newPassword: e.target.value}
                    })} type={'password'}
                       focus placeholder={'New password'} style={{width: '100%', padding: '0 5px'}}/>
            </div>
        },
        onConfirmCallback: async () => {
            await changePasswordHandler()
        }
    });
    const renderBlock = (title: string, description: string, content: any) => {
        return <div className="other-settings-block__sub">
            <div className="other-settings-block__sub--title">{title}</div>
            <div className="other-settings-block__sub--description">{description}</div>
            <div className="other-settings-block__sub--content">{content()}</div>
        </div>
    }

    const handlerError = (error: any) => {
        addToast(error, {appearance: 'error', autoDismiss: true});
    }

    const resetHwidHandler = async () => {
        const result = await resetHwid();
        if (result.error) {
            handlerError(result.error);
        }
    }

    const activateTokenHandler = () => {

    }

    return <div className="other-settings-block">
        {renderBlock(t(keys.mainPage.othersBlock.activeToken.title), t(keys.mainPage.othersBlock.activeToken.description), () => {
            return <Button color={'green'} circular onClick={onOpenToken}>{t(keys.mainPage.othersBlock.activeToken.btnText)}</Button>
        })}
        {renderBlock(t(keys.mainPage.othersBlock.hwid.title), t(keys.mainPage.othersBlock.hwid.description), () => {
            return <Button color={'green'} loading={isLoading} onClick={resetHwidHandler} circular>{t(keys.mainPage.othersBlock.hwid.btnText)}</Button>
        })}
        {renderBlock(t(keys.mainPage.othersBlock.discord.title), t(keys.mainPage.othersBlock.discord.description), () => {
            return <DiscordButton btnText={t(keys.mainPage.othersBlock.discord.btnText)}/>
        })}
        {renderBlock(t(keys.mainPage.othersBlock.vk.title), t(keys.mainPage.othersBlock.vk.description), () => {
            return <VkButton btnText={t(keys.mainPage.othersBlock.vk.btnText)} loadingState={isLoading}/>
        })}
        {renderBlock(t(keys.mainPage.othersBlock.password.title), t(keys.mainPage.othersBlock.password.description), () => {
            return <Button color={'blue'} onClick={onOpenPassword} circular>{t(keys.mainPage.othersBlock.password.btnText)}</Button>
        })}
        {renderBlock(t(keys.account.confirmEmail), t(keys.mainPage.othersBlock.email.description), () => {
            return <>
                <Button loading={isLoading} color={'green'}
                        circular onClick={onOpenNewEmail}>{t(keys.account.changeEmail)}</Button>
                <Button loading={isLoading} disabled={isEmailConfirmed} color={'green'}
                        circular onClick={sendEmailVerification}>{t(keys.account.confirmEmail)}</Button>
            </>
        })}
        {TokenDialog}
        {PasswordDialog}
        {EmailDialog}
    </div>
}

export default OtherSettings;
