import React from "react";
import {NavLink} from "react-router-dom";
import keys from "../translations/keys";
import {useTranslation} from "react-i18next";
import Image from "react-image-webp";

const Footer = () => {
    const {t} = useTranslation();
    return <div className={'footer'}>
        {/*<Image className="icon" src='/divine-main.png' webp='/divine-main.webp' alt={'DIVINE'}/>*/}
        {/*<div className="line">DIVINE</div>*/}
        {/*/!*<LangSelector/>*!/*/}
        {/*<NavLink className="link" exact to='/agreement'>{t(keys.welcomePage.agreement)}</NavLink>*/}
        {/*<NavLink className="link" exact to='/privacy'>{t(keys.welcomePage.privacy)}</NavLink>*/}
        {/*<NavLink className="link" exact to='/contacts'>{t(keys.welcomePage.contacts)}</NavLink>*/}
        {/*<a href="https://info.paymaster.ru/" className={'payments'}>*/}
        {/*    <img*/}
        {/*        src="/logos/paymaster_logo.png"*/}
        {/*        title="Приём оплаты на сайте картами" alt={'Прием электронных платежей'}/>*/}
        {/*</a>*/}
        {/*<a href="//freekassa.ru/" target="_blank" rel="noopener noreferrer" className={'freekassa'}>*/}
        {/*    <img src="/logos/free_kassa.png" title="Прием платежей" alt={'Прием платежей'}/>*/}
        {/*</a>*/}
        {/*<ul className="lightrope">*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*    <li></li>*/}
        {/*</ul>*/}
    </div>
}

export default Footer;
