import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import keyboardMap from '../keyCodes';
export type IProps = {
  label: string;
  onChange?: (newKey: string, keyCode: number) => void;
  value: number;
};

const HotkeyComponent = ({ label, onChange, value }: IProps) => {
  const inputRef: any = React.createRef();

  const notifyOnChange = _.debounce((newKey: string, keyCode: number) => {
    if (onChange) {
      onChange(newKey, keyCode);
    }
  }, 100);

  // const [hotkey, setHotkey] = useState('0');
  const [isActive, setActive] = useState(false);
  const { t } = useTranslation();

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    notifyOnChange(event.key, event.keyCode);
    // setHotkey(event.key);
    setActive(false);
    // String.fromCharCode(e.keyCode)
  };

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const hotkey = keyboardMap[Number(value)];

  return (
    <div className="hotkey">
      <div className="label">{label}</div>
      {isActive ? (
        <input
          id="input"
          ref={inputRef}
          className="input"
          value={hotkey}
          readOnly
          onBlur={() => setActive(false)}
          onKeyDown={(event) => keyDownHandler(event)}
        />
      ) : (
        <div className="hotkey-container" onClick={() => setActive(true)}>
          <div className="hotkey-label">
            {/*{hotkey === '0' ? t('noHotkey') : `${hotkey} ${label}`}*/}
            {hotkey === '0' ? t('noHotkey') : `${hotkey}`}
          </div>
          <div className="hotkey-tooltip">
            {/*<img src={Keyboard} alt="React Logo" />*/}
          </div>
          <div className="hotkey-tooltip--hover">{t('changeHotkey')}</div>
        </div>
      )}
    </div>
  );
};

export default HotkeyComponent;
