import useAuth from "./useAuth";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {RequestType} from "../Services/ApiService";

export interface IForumPageDto {
    id: number;
    themes: IForumThemeDto[];
    pages: IForumPageDto[];
    parentId: number | null;
    parent: IForumPageDto | null;
    title: string;
    miniDescription: string;
    hidden: boolean;
    createdTime: Date;
    updatedTime: Date;
    isPinned: boolean;
}

export interface IForumCommentDto {
    id: number;
    authorId: string;
    authorName: string;
    content: string;
    hidden: boolean;
    createdTime: Date;
    canEdit: boolean;
    canDelete: boolean;
}

export type IForumCommentsGetResponse = {
    comments: IForumCommentDto[];
    totalCount: number;
}

export interface IForumThemeDto {
    id: number;
    title: string;
    description: string;
    authorId: string;
    authorName: string;
    lastUpdate: string;
    commentsCount: number;
    hidden: boolean;
    canEdit: boolean;
    canDelete: boolean;
    isClosed: boolean;
    isPinned: boolean;
}
const baseUrl = Constants.apiEndpoint + 'Forum/';
const useForum = () => {
    const {state} = useAuth();
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        error,
        isLoading,
        BeginLoading
    } = useProgress();
    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();

    const getPage = async (pageId: number) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `pages/${pageId}`);
            return result.data as IForumPageDto;
        });
    }
    
    const getTheme = async (themeId: number) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `themes/${themeId}`);
            return result.data as IForumThemeDto;
        });
    }
    const getThemeComments = async (themeId: number, page: number, pageSize: number): Promise<IForumCommentsGetResponse> => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `themes/${themeId}/comments/${page}/${pageSize}`);
            return result.data as IForumCommentsGetResponse;
        });
    }
    const createMessage = async (themeId: number, message: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `comment/create`, {
                themeId: themeId,
                content: message
            }, RequestType.POST);
            return result.data as IForumThemeDto;
        });
    }
    const createPage = async (parentPageId: number, title: string, description: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `pages/create`, {
                parentId: parentPageId,
                title: title,
                miniDescription: description,
            }, RequestType.POST);
            return result.data as number;
        });
    }
    const createTheme = async (parentPageId: number, title: string, description: string) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `theme/create`, {
                PageId: parentPageId,
                title: title,
                description: description,
            }, RequestType.POST);
            return result.data as number;
        });
    }
    const updateTheme = async (theme: IForumThemeDto) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `theme`, {
                themeId: theme.id,
                title: theme.title,
                description: theme.description,
                isClosed: theme.isClosed,
                isPinned: theme.isPinned,
            }, RequestType.PUT);
            return result.data as number;
        });
    }

    const updateComment = async (commentDto: IForumCommentDto) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `comment`, {
                commentId: commentDto.id,
                hidden: commentDto.hidden,
                content: commentDto.content
            }, RequestType.PUT);
            return result.data as number;
        });
    }
    const hidePage = async (pageId: number) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `page/${pageId}`, {}, RequestType.DELETE);
            return result.data as number;
        });
    }
    const hideTheme = async (pageId: number) => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(baseUrl + `theme/${pageId}`, {}, RequestType.DELETE);
            return result.data as number;
        });
    }

    return {
        isLoading,
        hidePage,
        hideTheme,
        updateComment,
        updateTheme,
        createPage,
        createTheme,
        createMessage,
        getThemeComments,
        getPage,
        getTheme
    }
}

export default useForum;