import {useCallback, useRef} from "react";
import _ from "underscore";

const useDebouncedCallback = (callback: any, delay: number) => {
    const callbackRef = useRef()
    callbackRef.current = callback;
    return useCallback(_.debounce(
        (...args: any) => {
            if (callbackRef && callbackRef.current) {
                // @ts-ignore
                callbackRef.current(...args)
            }
        },
        delay
    ), []);
};

export default useDebouncedCallback;
