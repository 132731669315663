// @ts-ignore
import VkAuth from 'react-vk-auth';
import Button from "./Button";
import {useContext, useEffect, useState} from "react";
import useVkApi from "../Hooks/useVkApi";
import {DataContext} from "../Context/DataContext";

export type IVkButtonProps = {
    btnText?: string;
    loadingState?: boolean;
}

const VkButton = (props: IVkButtonProps) => {
    const {isLoading: isMainDataLoading, isVkConfirmed} = useContext(DataContext);
    const {btnText, loadingState} = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const {auth} = useVkApi();
    const apiId = 7952987;
    const groupId = 199870662;
    //https://oauth.vk.com/authorize?client_id=7952987&display=page&redirect_uri=https://divine.wtf/vk-confirmed&scope=email&response_type=token&v=5.81
    useEffect(() => {
        const el = document.getElementById('vk-sdk');
        if (el) {
            sdkLoaded()
        }
        asyncInit();
        loadSdkAsync();
    }, []);


    const asyncInit = () => {
        // @ts-ignore
        window.vkAsyncInit = () => {
            // @ts-ignore
            window.VK.init({apiId});
            setIsLoaded(true);
        };
    }

    const sdkLoaded = () => {
        setIsLoaded(true);
    }

    const loadSdkAsync = () => {
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.src = 'https://vk.com/js/api/openapi.js?';
        el.async = true;
        el.id = 'vk-sdk';
        document.getElementsByTagName('head')[0].appendChild(el);
    }

    const checkLoginState = (response: any) => {
        setIsProcessing(false);
        setIsLoaded(true);
        console.log(JSON.stringify(response))
        // auth(response);
        // @ts-ignore
        window.VK.Api.call('groups.isMember', {v:"5.81", group_id: groupId}, function(r) {
            if(r.response) {
                const isMember = r.response;
                auth({...response, isMember: !!isMember}).then(_ => {
                    setIsLoaded(false);
                    setIsDisabled(true);
                    window.location.reload();
                });
                // alert('Привет, ' + r.response[0].first_name);
            }
        });
    };

    const handleClick = () => {
        if (!isLoaded || isProcessing || isVkConfirmed || loadingState) {
            return;
        }
        setIsProcessing(true);
        // @ts-ignore
        window.VK.Auth.login(checkLoginState);
    };


    return <Button color={'blue'} circular disabled={isVkConfirmed || isDisabled} loading={loadingState || !isLoaded}
                   onClick={handleClick}>
        {props.btnText}
    </Button>
}

export default VkButton;
