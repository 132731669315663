import React from "react";
import BuySubBlock from "../Views/MainPageBlocks/BuySubBlock/BuySubBlock";
import keys from "../translations/keys";
import {useTranslation} from "react-i18next";
import ProgressBarComponent from "../Components/ProgressBarComponent";
import Image from "react-image-webp";

const BuySubscribePage = () => {
    const {t} = useTranslation();
    return <div className="buy_subscribe_page">
        <div className="buy_subscribe_page__title_block">
            <div className="title_block--text">
                {t(keys.buySubBlock.welcomeText)}
            </div>
            {/*<img className="title_block--icon" src="/und.png" alt={'start-client-block--icon'}/>*/}
            <Image
                className="title_block--icon"
                src={'/und.png'}
                webp={'/und.webp'}
            />
        </div>
        <div className="buy_subscribe_page__subscribe_block">
            <div className="subscribe_block--title">{t(keys.mainPage.paymentBlock.title)}</div>
            <div className="subscribe_block--content">
                <BuySubBlock/>
            </div>
        </div>
{/*        <div className="buy_subscribe_page__sale_block">
            <div className="sale_block--title">
                {t(keys.buySubBlock.saleText)}
            </div>
            <div className="sale_block--content">
                <div className="sale-percents">
                    <div className="image">
                        <img className="dis" src="/sale1.png" alt={'divine'}/>
                        <Image
                            className="dis"
                            src={'/sale1.png'}
                            webp={'/sale1.webp'}
                        />
                    </div>
                    <div className="image">
                        <img className="slark" src="/sale2.png" alt={'divine'}/>
                        <Image
                            className="slark"
                            src={'/sale2.png'}
                            webp={'/sale2.webp'}
                        />
                    </div>
                    <div className="image">
                        <img className="razor" src="/sale3.png" alt={'divine'}/>
                        <Image
                            className="razor"
                            src={'/sale3.png'}
                            webp={'/sale3.webp'}
                        />
                    </div>
                    <div className="image">
                        <img className="void" src="/sale4.png" alt={'divine'}/>
                        <Image
                            className="void"
                            src={'/sale4.png'}
                            webp={'/sale4.webp'}
                        />
                    </div>
                    <div className="image">
                        <img className="sf" src="/sale5.png" alt={'divine'}/>
                        <Image
                            className="sf"
                            src={'/sale5.png'}
                            webp={'/sale5.webp'}
                        />
                    </div>
                </div>
                <ProgressBarComponent value={1} title={''}/>
                <div className="sale-percents">
                    <div className="percent">2%</div>
                    <div className="percent">4%</div>
                    <div className="percent">8%</div>
                    <div className="percent">12%</div>
                    <div className="percent">15%</div>
                </div>
            </div>

        </div>
        <div className="buy_subscribe_page__info_block">
            <div className="info_block">
                <div className="info_block--title">{t(keys.buySubBlock.colOne.title)}</div>
                <div className="info_block--description">{t(keys.buySubBlock.colOne.description)}</div>
            </div>
            <div className="info_block">
                <div className="info_block--title">{t(keys.buySubBlock.colTwo.title)}</div>
                <div className="info_block--description">{t(keys.buySubBlock.colTwo.description)}</div>
            </div>
        </div>*/}
    </div>
}

export default BuySubscribePage;
