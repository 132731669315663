import React, {useEffect, useState} from "react";
import {IUserModel} from "../Hooks/useUsers";
import {Checkbox, Label, List, Tab, Table} from "semantic-ui-react";
import useClaims from "../Hooks/useClaims";
import axios from "axios";
import Constants from "../Configuration";
import {IClaim} from "../Views/UserEditPage";
import useAccount from "../Hooks/useAccount";
import useSubscribe, {ISubInfo} from "../Hooks/useSubscribe";
import moment from "moment";
import _ from "underscore";
import useSignalR from "../Hooks/useSignalR";
import {Scrollbars} from "rc-scrollbars/lib/Scrollbars";
import {AddSubReasonEnum, IPayment, ISubReasonObject, usePayment} from "../Hooks/usePayment";
import Slider from "rc-slider";
import Button from "./Button";

export type IUserPageProps = {
    selectedUser: IUserModel
}
const regex = /^\w_\w*/;
const UserPage = ({selectedUser}: IUserPageProps) => {
    const {id} = selectedUser;
    const {getClaims, setClaimsForUser} = useClaims();
    const [claims, setClaims] = useState([] as IClaim[]);
    const {getIpById, getHwidById, resetHwidById, setBanState, getBanState, setUserPassword} = useAccount(true);
    const [ips, setIps] = useState([])
    const [hwid, setHwid] = useState('')
    const {isLoading, generateToken, activateToken, getSubInfoById, addSubDaysToUser} = useSubscribe();
    const [daysLeft, setDatesLeft] = useState(0);
    const {checkAccount} = useSignalR();
    const {getPaymentByUser} = usePayment();
    const [count, setCount] = useState(1);
    const [days, setDays] = useState<number>(0);
    const [isBanned, setBanned] = useState(false);

    const [stateAccount, setStateAccount] = useState(undefined as {
        error: string,
        hwids: string[],
        steams: string[],
        systems: string[],
        ipis: string[]
    } | undefined);
    const [payments, setPayments] = useState([] as ISubReasonObject[]);
    const roundToTwo = (num: number | string): number => {
        if (!num)
            return 0;
        return +(Math.round(Number(num + 'e+2')) + 'e-2');
    };

    const GetSubInfoHandler = async () => {
        const result: ISubInfo = await getSubInfoById(id);
        if (!result)
            return;
        const targetDate = moment(result.time).utc();
        const difInH = targetDate.diff(moment().utc(), 'hours', true);
        setDatesLeft(roundToTwo(difInH));
    }

    const GetIpHandler = async () => {
        const result = await getIpById(id);
        setIps(result);
    }
    const GetHwidHandler = async () => {
        const result = await getHwidById(id);
        setHwid(result);
    }

    const CheckAccountHandler = async () => {
        const result = await checkAccount(id);
        setStateAccount(result);
    }
    const GetPaymentsHandler = async () => {
        const result: ISubReasonObject[] = await getPaymentByUser(id);
        setPayments(result);
    }
    const GetBanStateHandler = async () => {
        const result: boolean = await getBanState(id);
        setBanned(result);
    }
    const ResetPasswordHandler = async () => {
        const result: boolean = await setUserPassword(id, 'password');
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                const response: any = await axios.get(Constants.stsAuthority + ".well-known/openid-configuration")
                const allClaims = response.data.claims_supported;
                const filteredClaims = allClaims.filter((x: string) => regex.exec(x))
                const {claims} = await getClaims(id);
                setClaims(filteredClaims.map((x: string) => {
                    return {name: x, isEnabled: claims.some((z: string) => z === x)}
                }))
            } catch (e) {
                console.error(e)
            }

        }
        if (id) {
            fetch()
            GetSubInfoHandler();
            GetIpHandler()
            GetHwidHandler()
            CheckAccountHandler()
            GetPaymentsHandler()
            GetBanStateHandler()
        }
    }, [id])

    const onUpdateProp = (index: number, newValue: boolean) => {
        setClaims((prevState: IClaim[]) => {
            const newArray = [...prevState]
            newArray[index].isEnabled = newValue
            return newArray
        })
    }

    const save = async () => {
        try {
            const postResult = await setClaimsForUser(id, claims);
        } catch (e) {
        }
    }

    const convertName = (claim: IClaim) => {
        return claim.name
            .replace('r_', 'read_')
            .replace('d_', 'delete_')
            .replace('c_', 'create_')
            .replace('u_', 'update_')
            .replace('readelete_', 'read_')
    }

    const mainInfoTab = () => {
        return <Table celled>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Id</Table.Cell>
                    <Table.Cell>{selectedUser.id}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Логин</Table.Cell>
                    <Table.Cell>{selectedUser.userName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Почта</Table.Cell>
                    <Table.Cell>{selectedUser.email} --- {selectedUser.emailConfirmed ? 'Подтвержден' : 'Не подтвержден'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Сбросить пароль</Table.Cell>
                    <Table.Cell><Button onClick={ResetPasswordHandler}>Поменять на 'MyN3wP@ssw0rd'</Button></Table.Cell>
                </Table.Row>
                <Table.Row negative={isBanned}>
                    <Table.Cell>Заблокирован</Table.Cell>
                    <Table.Cell>{isBanned ? 'Заблокирован' : 'Не заблокирован'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Подписка</Table.Cell>
                    <Table.Cell>{`Осталось: ${daysLeft} h.`}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Подозрительный</Table.Cell>
                    {!!stateAccount ? <Table.Cell>{!stateAccount || !stateAccount.error ? 'Нет' : <div>
                        <div className="text">Аккаунт имеет в базе схожие hwid или ip</div>
                        <Label>Схожие по базе айпишников</Label>
                        <List>
                            {_.chain(stateAccount.ipis).uniq((x: any) => x).value().map((item: any, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>{item}</List.Content>
                                    </List.Item>
                                )
                            })}
                        </List>
                        <Label>Схожие по базе steam</Label>
                        <List>
                            {_.chain(stateAccount.steams).uniq((x: any) => x).value().map((item: any, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>{item}</List.Content>
                                    </List.Item>
                                )
                            })}
                        </List>
                        <Label>Схожие по базе конфига системы</Label>
                        <List>
                            {_.chain(stateAccount.systems).uniq((x: any) => x).value().map((item: any, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>{item}</List.Content>
                                    </List.Item>
                                )
                            })}
                        </List>
                        <Label>Схожие по базе hwid</Label>
                        <List>
                            {_.chain(stateAccount.hwids).uniq((x: any) => x).value().map((item: any, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>{item}</List.Content>
                                    </List.Item>
                                )
                            })}
                        </List>
                    </div>}
                    </Table.Cell> : <Table.Cell>Загрузка данных...</Table.Cell>}
                </Table.Row>
            </Table.Body>
        </Table>
    }

    const claimsTab = () => {
        return <div>
            <Scrollbars autoHide autoHeight={true} autoHeightMin={'300px'}>
                <List>
                    {claims.map((claim: IClaim, index) => {
                        return (
                            <List.Item key={index}>
                                <List.Content><Checkbox checked={claim.isEnabled}
                                                        onClick={(event) => onUpdateProp(index, !claim.isEnabled)}
                                                        label={`${convertName(claim)}`}/></List.Content>
                            </List.Item>
                        )
                    })}
                </List>
            </Scrollbars>
            <Button
                content="Save"
                labelPosition='right'
                icon='checkmark'
                onClick={() => save()}
                positive
                loading={isLoading}
            />
        </div>
    }

    const hwidAndIpTab = () => {
        return <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}>
            <div style={{display: "flex", flexFlow: 'column'}}>
                <div className="hwid">{hwid}</div>
                <Button onClick={event => resetHwidById(id)}>Сбросить</Button>
            </div>
            <List>
                {_.chain(ips).uniq((x: any) => x.ip).value().map((item: any, index) => {
                    return (
                        <List.Item key={index}>
                            <List.Content>{item.ip}</List.Content>
                        </List.Item>
                    )
                })}
            </List>
        </div>
    }

    const marks = {
        '-180': '-180',
        '-90': '-90',
        '-30': '-30',
        '-14': '-14',
        '-7': '-7',
        '-3': '-9',
        '3': '3',
        '7': '7',
        '14': '-14',
        '30': '30',
        '90': '-90',
        '180': '180',
    };

    const subscribeContent = () => {
        return <>
            <div className="sub-block">
                <label>Days: </label>
                <span>{days}</span>
                <Slider value={days} step={3} marks={marks} min={-180}
                        max={180} included={false}  onChange={value => {
                    setDays(value)
                }} ariaLabelForHandle={`${days} дней`}/>
                <Button positive loading={isLoading} onClick={async event => {
                    await addSubDaysToUser(id, days as number)
                    await GetSubInfoHandler();
                }}>Выдать</Button>
            </div>

        </>
    }

    const paymentsTab = () => {
        return <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Откуда</Table.HeaderCell>
                    <Table.HeaderCell>Дни</Table.HeaderCell>
                    <Table.HeaderCell>Цена</Table.HeaderCell>
                    <Table.HeaderCell>Дата</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {payments.map((payment: ISubReasonObject, index: number) => {
                    return <Table.Row key={index}>
                        <Table.Cell>{AddSubReasonEnum[payment.subReason]}</Table.Cell>
                        <Table.Cell>{payment?.token?.lifeTime ?? payment.payment?.daysEnum ?? payment.days}</Table.Cell>
                        <Table.Cell>{payment?.payment?.price ?? 'token'} руб.</Table.Cell>
                        <Table.Cell>{moment(payment.createdTime).format('L LT')}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    }
    const banTab = () => {
        return <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Статус</Table.HeaderCell>
                    <Table.HeaderCell>Заблокировать</Table.HeaderCell>
                    <Table.HeaderCell>Разблокировать</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Cell>{isBanned ? 'Заблокирован' : 'Не заблокирован'}</Table.Cell>
                <Table.Cell><Button content={'Заблокировать'}
                                    onClick={event => setBanState(selectedUser.id, true)}/></Table.Cell>
                <Table.Cell><Button content={'Разблокировать'} onClick={event => setBanState(selectedUser.id, false)}/></Table.Cell>
            </Table.Body>
        </Table>
    }

    const panes = [
        {menuItem: 'Общая информация', render: () => <Tab.Pane>{mainInfoTab()}</Tab.Pane>},
        {menuItem: 'Права', render: () => <Tab.Pane>{claimsTab()}</Tab.Pane>},
        {menuItem: 'Hwid & Ip', render: () => <Tab.Pane>{hwidAndIpTab()}</Tab.Pane>},
        {menuItem: 'Подписка', render: () => <Tab.Pane>{subscribeContent()}</Tab.Pane>},
        {menuItem: 'История подписок', render: () => <Tab.Pane>{paymentsTab()}</Tab.Pane>},
        {menuItem: 'Управление блокировкой', render: () => <Tab.Pane>{banTab()}</Tab.Pane>},
    ]

    return <div className="user-container">
        <Tab panes={panes}/>
    </div>
}

export default UserPage;
