import React, {useEffect, useState} from "react";
import useConfig, {IConfigList} from "../Hooks/useConfig";
import CircleLoader from "../Components/CircleLoader";
import useDebouncedCallback from "../Hooks/useDebouncedCallback";
import ConfigsBrowser from "./Config/ConfigsBrowser";
import ConfigSettings from "./Config/ConfigSettings";
import MyConfigBrowser from "./Config/MyConfigBrowser";


const CloudConfigPage = () => {
    const {
        error,
        isLoading,
        config,
        setConfig,
        saveConfig,
        firstTimeLoaded,
        getPublicList,
        onToggleConfig,
        setConfigPublicFlag,
        installPluginByCode,
        deleteConfig,
        installPlugin,
        renameConfig,
        getMyConfigList
    } = useConfig();
    const saveOnChange = useDebouncedCallback(saveConfig, 500)
    const [publicConfigs, setPublicConfigs] = useState([] as IConfigList[]);
    const [privateConfigs, setPrivateConfigs] = useState([] as IConfigList[]);

    const updateValue = async (key: string, value: any) => {
        setConfig(prevState => {
            return {...prevState, [key]: value};
        })
    }

    const fetchGlobalPlugins = async () => {
        const response = await getPublicList('');
        if (response)
            setPublicConfigs(response)
    }

    const fetchLocalPlugins = async () => {
        const response = await getMyConfigList();
        if (response)
            setPrivateConfigs(response)
    }

    useEffect(() => {
        if (!isLoading && firstTimeLoaded)
            saveOnChange();
    }, [config])

    useEffect(() => {
        fetchGlobalPlugins();
        fetchLocalPlugins();
    }, []);

    const installPluginByCodeHandler = async (config: IConfigList) => {
        await installPluginByCode(config);
        await fetchLocalPlugins();
    }
    const installPluginHandler = async (config: IConfigList) => {
        await installPlugin(config);
        await fetchGlobalPlugins();
        await fetchLocalPlugins();
    }

    const renameConfigHandler = async (id: number, name: string) => {
        await renameConfig(id, name);
        await fetchLocalPlugins();
    }

    return <div className={'cloud-config-page'}>
        <CircleLoader isLoading={isLoading}/>
        <div className="cloud-config-page--content">
            <ConfigsBrowser isLoading={isLoading} getPublicList={getPublicList} publicConfigs={publicConfigs}
                            installPlugin={installPluginHandler} installPluginByCode={installPluginByCodeHandler}/>
            <ConfigSettings config={config} error={error} isLoading={isLoading} updateValue={updateValue}/>
            <MyConfigBrowser isLoading={isLoading} getMyConfigList={fetchLocalPlugins} onToggleConfig={onToggleConfig}
                             renameConfig={renameConfigHandler}
                             setConfigPublicFlag={setConfigPublicFlag} privateConfigs={privateConfigs}
                             onDeleteClick={deleteConfig}/>
        </div>
    </div>
}

export default CloudConfigPage;
