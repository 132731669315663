import React, {useState} from "react";
import useDllFiles from "../Hooks/useDllFiles";
import DropZone, {IDropZoneFileProps} from "../Components/DropZone";
import classNames from "classnames";
import moment from "moment";
import CircleLoader from "../Components/CircleLoader";
import {useToasts} from "react-toast-notifications";

const AddLibraryDllFilePage = () => {
    const {isLoading, makeValid, dllFiles, deleteDllFile, getFiles, downloadDllFile} = useDllFiles(true);
    const [filesToUpload, setFilesToUpload] = useState([] as IDropZoneFileProps[])
    const {addToast} = useToasts();
    const onFileLoaded = (file: IDropZoneFileProps) => {
        addToast(`Файл загружен на сервер. ${file.name}`, {appearance: "info", autoDismiss: true})
        setFilesToUpload(prevState => {
            return [...prevState, file]
        })
    }

    const onFileError = (arg: any) => {
        addToast(`Файл не загружен на сервер. ${arg}`, {appearance: "error", autoDismiss: true})
    }

    const isDisabled = !filesToUpload || filesToUpload.length === 0

    const cls = classNames('save', {
        'save--disabled': isDisabled
    })

    let downloadHandler = async (id: number, fileName: string) => {
        await downloadDllFile(id, fileName);
    }
    let deleteHandler = async (id: number) => {
        await deleteDllFile(id);
        await getFiles();
    }
    let onClickHandler = async () => {
        if (isDisabled)
            return;
        const result = await makeValid(filesToUpload.map(x => x.fileId));
        if (result && result.status === 200) {
            addToast(`Файл(ы) успешно загружен в количестве ${filesToUpload.length} шт.`, {appearance: "success", autoDismiss: true})
        } else {
            addToast(`Ошибка при загрузке файла`, {appearance: "error"})
        }
        setFilesToUpload([]);
        await getFiles();
    };
    return <div className="add-library-file-page">
        <CircleLoader isLoading={isLoading}/>
        <div className="list-of-files">
            {dllFiles.map((x: { fileName: string, id: number, version: string, releaseDate: Date }) => {
                return <div key={x.id} className="list-of-files--item">
                    <div className="title">
                        {x.fileName}
                    </div>
                    <div className="date">{moment(x.releaseDate).format('L LT')}</div>
                    <div className="version">v. {x.version}</div>
                    <div className="download-btn" onClick={() => downloadHandler(x.id, x.fileName)}>Скачать</div>
                    <div className="delete-btn" onClick={() => deleteHandler(x.id)}>Удалить</div>
                </div>
            })}
        </div>
        <div className="upload-container">
            <DropZone onFileLoaded={onFileLoaded} onError={onFileError} apiUrl={"upload/library"}
                      description={'Dll файлы'}/>
        </div>
        <div className={cls} onClick={onClickHandler}>Загрузить файлы на сервер</div>
    </div>
}

export default AddLibraryDllFilePage;
