import {createSlice} from "@reduxjs/toolkit";
import useMainData, {IMainData} from "../../Hooks/useMainData";
import moment from "moment";

export interface IStoreData extends IMainData {
    isLoading: boolean;
}

export const itemsSlice = createSlice({
    name: "items",
    initialState: {
        isDiscordLinked: false,
        email: '',
        isLoading: false,
        isEmailConfirmed: false,
        pluginToggleGetDtos: [],
        prices: [],
        subEnd: moment().toDate(),
        crasherEnd: moment().toDate(),
        totalGamePlayed: 0,
        isVkConfirmed: false,
        isOldCostumer: false
    } as  IStoreData,
    reducers: {
        setItems: (state: any, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const {setItems} = itemsSlice.actions;

export const getData = (state: any): IStoreData => state.items;

export default itemsSlice.reducer;
