import React from "react";

export type IProps = {
    isLoading: boolean;
}

const CircleLoader = ({isLoading}: IProps) => {
    if (!isLoading)
        return <></>
    return <div className="circle-loader">
        <div className="circle-loader-circle"/>
    </div>
}

export default CircleLoader;
