import React, {useContext, useEffect, useState} from "react";
import * as signalR from "@microsoft/signalr";
import {HttpTransportType} from "@microsoft/signalr";
import {AuthContext} from "./RootContext";
import Constants from "../Configuration";
import {useToasts} from "react-toast-notifications";
import {useTranslation} from "react-i18next";
import keys from "../translations/keys";

export type IDownloadType = {
    id: string,
    percent: number
}

// export type INotificationState = {
//     isVisited: boolean,
//     data: string[],
// }

export type ISignalRContextType = {
    connection: any,
    isClientOnline: boolean,
    downloads: { [key: string]: string },
    canPlay: boolean | null,
    notifications: string[],
    isVisited: boolean,
    changeNotificationVisibility?: (visited: boolean) => void
    // notificationState: INotificationState,
    // setNotificationState: any
}

const initialValue = {
    connection: null,
    isClientOnline: false,
    canPlay: null,
    downloads: {},
    notifications: [],
    isVisited: true,
    changeNotificationVisibility: undefined
} as ISignalRContextType

export const SignalRContext = React.createContext(initialValue);


const SignalRProvider = ({children}: any) => {
    const changeNotificationVisibility = (visitedState: boolean) => {
        setContext(prevState => {
            return {...prevState, isVisited: visitedState}
        })
    }
    const [context, setContext] = useState({...initialValue, changeNotificationVisibility});

    const addNotification = (text: string) => {
        setContext(prevState => {
            return {...prevState, isVisited: false, notifications: [...prevState.notifications, text]}
        })
    }

    const authContext = useContext(AuthContext)

    const {addToast} = useToasts();

    const {t} = useTranslation();


    useEffect(() => {
        const token = authContext.user?.access_token;
        if (!token)
            return;
        if (context.connection) {
            context.connection.stop();
        }

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Constants.apiEndpoint + "web", {
                withCredentials: false,
                accessTokenFactory(): string | Promise<string> {
                    return token
                },
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true,
                logMessageContent: true,
            })
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect()
            .build();


        connection.start().catch(err => console.log('signalR error ->', err));
        connection.on('Online', args => {
            setContext(prevState => {
                return {...prevState, isClientOnline: args[0] === '1', downloads: {}, canPlay: args[1]}
            })
        })
        connection.on('ChangeState', args => {
            // console.log('ChangeState ->', args)
            if (args[1] >= 100) {
                addToast(`${args[0]} downloaded successfully`, {appearance: "success", autoDismiss: true})
                addNotification(`${args[0]} downloaded successfully`)
            }
            if (args[1] === '0') {
                // addToast(`${args[0]} starting downloading`, {appearance: "info", autoDismiss: true})
                // addNotification(`${args[0]} starting downloading`);
            }
            setContext(prevState => {
                const downloadsOld = {...prevState.downloads};
                const id = args[0];
                downloadsOld[id] = args[1];
                return {...prevState, downloads: downloadsOld}
            })
        })
        connection.on('ClientUpdated', args => {
            addToast(t(keys.notifications.client_update_ready), {appearance: "info", autoDismiss: true})
            addNotification(t(keys.notifications.client_update_ready))
        });
        connection.on('PluginUpdated', args => {
            addToast(t(keys.notifications.plugin_updated, {name: args[1]}), {appearance: "info", autoDismiss: true})
            addNotification(t(keys.notifications.plugin_updated, {name: args[1]}))
        });
        connection.on('LibraryUpdated', args => {
            addToast(t(keys.notifications.plugin_updated, {name: args[1]}), {appearance: "info", autoDismiss: true})
            addNotification(t(keys.notifications.plugin_updated, {name: args[1]}))
        });
        connection.on('PaymentConfirm', args => {
            const text = t(keys.notifications.payment) + t(keys.day.day, {count: Number(args[0])});
            addToast(text, {
                appearance: "success",
                autoDismiss: true
            })
        });
        connection.on('HWID IS NOT VALID', args => {
            addToast("Disconnected by HWID", {
                appearance: "success",
                autoDismiss: true
            })
        });
        setContext(prevState => {
            return {...prevState, connection}
        })
    }, [authContext.user?.access_token])

    return (
        <SignalRContext.Provider value={context}>
            {children}
        </SignalRContext.Provider>
    )
}

export default SignalRProvider;
