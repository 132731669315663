import React from "react";
import {Link, NavLink} from "react-router-dom";
import classnames from "classnames";
import useAuth from "../Hooks/useAuth";
import {useTranslation} from "react-i18next";
import keys from "../translations/keys";
import Image from "react-image-webp";

export type IProps = {
    headerText: string;
    show: boolean;
    className?: string;
}

const BasicTopNavBar = ({headerText, className, show}: IProps) => {
    const {state, isAuthenticated} = useAuth()
    const {t} = useTranslation();
    const onLogin = async () => {
        await state.LoginAction();
    };

    const logout = async () => {
        await state.LogoutAction();
    }
    const getStickyTopClasses2 = () => {
        return classnames('basic-top-nav-bar', {'basic-top-nav-bar-show': show})
    }
    return <div className={getStickyTopClasses2() + ' ' + className}>
        <div className="header">{headerText}</div>
        {/*<div className="spacer"/>*/}
        <div className="links">
            {!isAuthenticated ?
                <NavLink className="link" exact to='/about'>{t(keys.welcomePage.aboutUs)}</NavLink> :
                <NavLink className="link" exact to='/main'>{t(keys.lefPanel.dashboard.main)}</NavLink>}
            {/*<NavLink className="link" exact to='/agreement'>{t(keys.welcomePage.agreement)}</NavLink>*/}
            {/*<NavLink className="link" exact to='/videos'>Видео</NavLink>*/}
            <Link to="route" target="_blank" className="link" onClick={(event) => {
                event.preventDefault();
                window.open('https://vk.com/divinecheat');
            }}>VK</Link>
            <Link to="route" target="_blank" className="link" onClick={(event) => {
                event.preventDefault();
                window.open('https://discord.gg/YvFuC6H');
            }}>Discord</Link>
            {/*<div className="link" onClick={onLogin}>{t(keys.welcomePage.login)}</div>*/}
            {!isAuthenticated && <div className="link" onClick={onLogin}>
                <div className="sign-up">
                    {t(keys.welcomePage.signUp)}
                </div>
            </div>}
        </div>
        <Image className="icon" src='/divine-main.png' webp='/divine-main.webp' alt={'DIVINE'}/>
        <div className="line">DIVINE</div>
    </div>
}

export default BasicTopNavBar;
