import TextInput from "../Components/TextInput";
import keys from "../translations/keys";
import {Button} from "semantic-ui-react";
import useResetPassword from "../Hooks/useResetPassword";
import {useTranslation} from "react-i18next";

const ForgotPasswordPage = () => {
    const {t} = useTranslation();
    const {isLoading, email, setEmail, resetPassword} = useResetPassword('');
    return <div className="forgot-password-page">
        <div className="forgot-password-page__title">{t(keys.resetPassword.forgotPasswordPage.title)}</div>
        <div className="forgot-password-page__content">
            <TextInput type={'email'} id={'email'} onChange={value => setEmail(value)}
                       value={email} placeholder={t(keys.account.email)}/>
        </div>
        <div className="forgot-password-page__action">
            <Button loading={isLoading} primary={true} fluid={true}
                    onClick={resetPassword}>{t(keys.resetPassword.forgotPasswordPage.reset)}</Button>
        </div>
    </div>
}

export default ForgotPasswordPage;
