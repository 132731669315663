import React, {useEffect, useState} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classnames from "classnames";
import useUploadFiles from "../../../Hooks/useUploadFiles";
import Constants from "../../../Configuration";

export type IHtmlEditorProps = {
    onChange?: (value: string) => void;
    value: string;
    viewMode?: boolean;
    readOnly?: boolean;
    toolbarHidden?: boolean;
}

const HtmlEditor = ({onChange, value, readOnly, viewMode, toolbarHidden}: IHtmlEditorProps) => {
    const {UploadForumImage} = useUploadFiles();
    const [editorState, setState] = useState(undefined as EditorState | undefined);
    useEffect(() => {
        if (value) {
            const contentBlock = htmlToDraft(value);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setState(editorState);
        } else {
            setState(EditorState.createEmpty());
        }

    }, []);

    const onUpdateHandler = (editorState: EditorState) => {
        setState(editorState);
        if (onChange)
            onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    const readOnlyProp = readOnly || viewMode;
    const toolbarHiddenProp = toolbarHidden || viewMode;

    const editorCls = classnames('html_editor_editor', {
        'html_editor_editor--view-mode': viewMode
    })
    const wrapperCls = classnames('html_editor_wrapper', {
        'html_editor_wrapper--view-mode': viewMode
    })

    const uploadImageCallBack = async (file: any) => {
        const fileId = (await UploadForumImage(file))?.data;
        const url = `${Constants.apiEndpoint}download/image/${fileId}`;
        return { data: { link: url}};
    }

    return <Editor
        readOnly={readOnlyProp}
        toolbarHidden={toolbarHiddenProp}
        spellCheck
        editorState={editorState}
        toolbarClassName={'html_editor_toolbar'}
        wrapperClassName={wrapperCls}
        editorClassName={editorCls}
        onEditorStateChange={onUpdateHandler}
        toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: { uploadCallback: uploadImageCallBack, previewImage: true },
        }}
    />
}

export default HtmlEditor;