import React, {useEffect, useState} from "react";
import {Cropper} from "react-cropper";
import Portal from "../portal/portal";
import Button from "../Button";
import classNames from "classnames";
import useUploadFiles from "../../Hooks/useUploadFiles";
import "cropperjs/dist/cropper.css";

export type IPluginImageCropperProps = {
    onUpload: (data: Blob) => void;
}

export const PluginImageCropper = ({onUpload}: IPluginImageCropperProps) => {
    const [image, setImage] = useState();
    const [isEditorVisible, setIsEditorVisible] = useState(false);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
        setIsEditorVisible(true);
    };

    useEffect(() => {
        if (cropper) {
            console.log('cropper')
            setTimeout(args => {
                cropper.setCropBoxData({height: 211, width: 428})
            }, 1000)
        }
    }, [cropper])

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
        }
    };

    const modalCls = classNames('krkn__modal', 'image_uploader_modal');
    const modalWrapperCls = classNames('krkn__modal-wrapper', 'image_uploader-wrapper');

    const cancelHandler = () => {
        setIsEditorVisible(false);
    }

    const saveHandler = () => {
        setIsEditorVisible(false);
        cropper.getCroppedCanvas().toBlob(async (blob: Blob | null) => {
            if (!blob) {
                return;
            }
            await onUpload(blob);
        });
    }

    const flushFileData = (event: React.MouseEvent<HTMLInputElement>) => {
        // @ts-ignore
        event.target.value = "";
    }

    return (<>
            <input type="file" onChange={onChange} onClick={flushFileData}/>
            <Portal id="krkn__modal-portal" className="krkn__modal-portal" isVisible={isEditorVisible}>
                <div className={modalWrapperCls}>
                    <div className={modalCls}>
                        <div className="image_uploader_modal__container">
                            <Cropper
                                style={{height: '100%', width: '100%'}}
                                // zoomTo={2}
                                // initialAspectRatio={1}
                                preview=".img-preview"
                                src={image}
                                viewMode={0}
                                dragMode={'move'}
                                guides={false}
                                center={false}
                                highlight={false}
                                minCropBoxHeight={211}
                                minCropBoxWidth={428}
                                minContainerWidth={100}
                                minContainerHeight={100}
                                // minCanvasWidth={100}
                                // minCanvasHeight={100}
                                wheelZoomRatio={0.25}
                                background={false}
                                responsive={true}
                                // autoCropArea={1}
                                cropBoxMovable={false}
                                toggleDragModeOnDblclick={false}
                                cropBoxResizable={false}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                            />
                        </div>
                        {/*<img src={cropData} alt="cropped" />*/}
                    </div>
                    <div className="image_uploader__actions">
                        <Button negative className="krkn__modal-footer__control" onClick={cancelHandler} circular>
                            Cancel
                        </Button>
                        {/*<Button basic className="krkn__modal-footer__control" onClick={getCropData}>*/}
                        {/*    getCropData*/}
                        {/*</Button>*/}
                        <Button primary className="krkn__modal-footer__control" circular  onClick={saveHandler}>
                            Save
                        </Button>
                    </div>
                </div>

                <div className={'krkn__modal-overlay image_editor-overlay'}/>
            </Portal>
        </>
    );
};

export default PluginImageCropper;