const Constants = {
    cookie_domain: process.env.REACT_APP_cookie_domain ?? 'localhost',
    // cookie_domain: '.divine.wtf',
    stsAuthority: process.env.REACT_APP_stsAuthority ?? 'http://84.38.183.219:5000/',
    clientId: process.env.REACT_APP_clientId ?? 'WebClient',
    clientRoot: process.env.REACT_APP_clientRoot ?? 'http://84.38.183.219/',
    clientScope: process.env.REACT_APP_clientScope ?? 'openid profile API',
    apiEndpoint: process.env.REACT_APP_apiEndpoint ?? 'http://84.38.183.219:5005/',
    apiEndpoint2: process.env.REACT_APP_apiEndpoint2 ?? 'http://84.38.183.219:5003/Logs',
    userApi: process.env.REACT_APP_stsAuthority ?? 'http://84.38.183.219:5000/',
    dontUseAuth: process.env.NODE_ENV !== "development",
    paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '',
}

export default Constants;
