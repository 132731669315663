import React, {useRef} from "react";
import {Icon, Popup} from "semantic-ui-react";
import keys from "../../translations/keys";
import {ITogglePlugin} from "../../Hooks/useScripts";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useModalState} from "../../Hooks/useModalState";
import Image from "react-image-webp";
import PluginOverview from "./PluginOverview";
import Constants from "../../Configuration";
import useAuth from "../../Hooks/useAuth";
import _ from "underscore";
import {useCopyToClipboard} from "react-use";

export type IPluginItem = {
    plugin: ITogglePlugin
    isLoading: boolean;
    toggle: (id: number, value: boolean) => void;
}

const PluginItem = ({plugin, toggle, isLoading: IsPluginLoading}: IPluginItem) => {
    const btn1 = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();
    const {onOpen, isOpen, onToggle, onClose} = useModalState();
    const [_, copyToClipboard] = useCopyToClipboard();

    const getItemClass = (enabled: boolean) => classNames('item', {
        'item--enabled': enabled
    });

    const onToggleHandler = async () => {
        await toggle(plugin.id, !plugin.isEnabled);
    }

    const saveUrlToClipboardHandler = () => {
        const currentLoc = window.location.host;
        copyToClipboard((currentLoc+'/plugins/'+plugin.id).replace('//', ''));
    }

    return <div className={getItemClass(plugin.isEnabled)} ref={btn1}>
        <div className="item__content">
            {plugin.imageId && <img className="item__content__background" src={`${Constants.apiEndpoint}download/image/${plugin.imageId}`} alt={'DIVINE'}/>}
            <Popup size={'mini'} hideOnScroll on={'hover'} position={'top center'}
                   trigger={<div className="item__content__name" onClick={saveUrlToClipboardHandler}>
                       {plugin.pluginName}
                   </div>}
                   content={'Copy link'}/>
            {/*<div className="item__content__name ">{plugin.pluginName}</div>*/}
            <div className="item__content__author">{plugin.authorName}</div>
            <img className="item__content__logo" src='/Plugins/LogoSmall.png' alt={'DIVINE'}/>
            <Popup size={'mini'} hideOnScroll on={'hover'}
                   trigger={<div className="item__content__count_of_users">
                       <Icon name={'user'}/>
                       {plugin.countOfUsers}
                   </div>}
                   content={'Count of installs'}/>
        </div>
        <div className="item__actions">
            <div className="item__actions__item" onClick={onOpen}>{t(keys.pluginsPage.overview)}</div>
            <div className="item__actions__item"
                 onClick={onToggleHandler}>{plugin.isEnabled ? t(keys.pluginsPage.deletePlugin) : t(keys.pluginsPage.installPlugin)}</div>
        </div>
        <PluginOverview onClose={onClose} onOpen={onOpen} isOpen={isOpen} onToggle={onToggle} plugin={plugin}/>
    </div>;
}

export default PluginItem;
