import useScripts, {IPlugin} from "../Hooks/useScripts";
import {Dimmer, Loader} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import moment from "moment";

const PluginManagerPage = () => {
    const {GetScriptForApprove, isLoading, ApprovePlugin, DeleteScript, AddToLocal} = useScripts();
    const [data, setData] = useState([] as IPlugin[]);

    const fetch = async () => {
        const data = await GetScriptForApprove();
        if (data)
            setData(data);
    }

    useEffect(() => {
        fetch();
    }, [])

    const approve = async (plugin: IPlugin) => {
        await ApprovePlugin(plugin.id)
        await fetch();
    }
    const decline = async (plugin: IPlugin) => {
        await DeleteScript(plugin.id)
        await fetch();
    }
    const addToLocal = async (plugin: IPlugin) => {
        await AddToLocal(plugin.id)
        await fetch();
    }

    const getDate = (plugin: IPlugin) => {
        const timeDifference = new Date().getTimezoneOffset();
        const time = moment(plugin.createdTime).utcOffset(0, true).fromNow()
        return time;
    }

    return <div className={'plugin-manager-page'}>
        <Dimmer active={isLoading}>
            <Loader/>
        </Dimmer>
        <div className="plugin-manager-page__list">
            {data.map((plugin: IPlugin, index) => {
                return <div key={index} className={'plugin-manager-page__list__item'}>
                    <div className="plugin-manager-page__list__item__info_area">
                        <div className="plugin-manager-page__list__item__info_area__row">
                            <div className="plugin-manager-page__list__item__info_area_name">
                                {plugin.pluginName}
                            </div>
                            <div className="plugin-manager-page__list__item__info_area_author">
                                {`by ${plugin.authorName}`}
                            </div>
                            <div className="plugin-manager-page__list__item__info_area_time">
                                {getDate(plugin)}
                            </div>
                            <div className="plugin-manager-page__list__item__info_area_description">
                                {plugin.description}
                            </div>
                        </div>


                        <div className="plugin-manager-page__list__item__info_area__row">
                            <div className="plugin-manager-page__list__item_path">
                                {plugin.path}
                            </div>
                        </div>
                    </div>
                    <div className="plugin-manager-page__list__item_local"
                         onClick={(event) => addToLocal(plugin)}>
                        Добавить в локальные
                    </div>
                    <div className="plugin-manager-page__list__item_decline"
                         onClick={(event) => decline(plugin)}>
                        Удалить
                    </div>
                    <div className="plugin-manager-page__list__item_approve"
                         onClick={(event) => approve(plugin)}>
                        Одобрить
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default PluginManagerPage;

