import React, {useEffect, useState} from "react";
import useUsers, {IUserModel} from "../Hooks/useUsers";
import {FixedSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import TextInput from "../Components/TextInput";
import {useModalState} from "../Hooks/useModalState";
import { Modal } from "semantic-ui-react";
import Button from "../Components/Button";
import UserPage from "../Components/UserPage";

const UsersPage = () => {
    const {getUsers, isLoading} = useUsers();

    const [users, setUsers] = useState([] as IUserModel[]);
    const [filter, setFilter] = useState('');
    const {onClose, isOpen, onOpen, onToggle} = useModalState();
    const [selectedUser, setSelectedUser] = useState(undefined as IUserModel | undefined);

    // const [page, setPage] = useState(1);
    // const [total, setTotal] = useState(0);
    // const pageSize = 4000;
    // const PADDING_SIZE = 10;
    // useEffect(() => {
    //     getUsersWithPage(page, pageSize).then((data) => {
    //         const usersToUpdate = [...users];
    //         usersToUpdate.push(...data.users)
    //         setUsers(usersToUpdate);
    //         setTotal(data.total);
    //     });
    // }, [])
    // useEffect(() => {
    //     setPage(1)
    //     getUsersWithPage(1, pageSize).then((data) => {
    //         const usersToUpdate = [...users];
    //         usersToUpdate.push(...data.users)
    //         setUsers(usersToUpdate);
    //         setTotal(data.total);
    //     });
    // }, [filter])

    useEffect(() => {
        getUsers().then((data) => {
            setUsers(data);
        });
    }, []);

    const lowerFilter = filter.toLowerCase()
    const filteredUsers = users.filter((user) => !lowerFilter || lowerFilter === '' || user.id?.toLowerCase().includes(lowerFilter) || user.userName?.toLowerCase().includes(lowerFilter) || user.email?.toLowerCase().includes(lowerFilter))

    const usersHeader = `Пользователи (${users.length}шт.)`

    const Row = ({index, style}: any) => {
        const user = filteredUsers[index];
        if (!user) {
            return <div>loading</div>
        }
        return (
            <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={{...style}} onClick={event => {setSelectedUser(user)}}>
                <div className="user-item">
                    <div className="line line-name">{user.userName}</div>
                    <div className="line line-id">{user.id}</div>
                    <div className="line line-id">{user.email}</div>
                </div>
            </div>
        );
    }

    // const isItemLoaded = (index: number) => filteredUsers.length > index;
    // const loadNextPage = (startIndex: number, stopIndex: number) => {
    //     return new Promise((resolve) => {
    //         getUsersWithPage(page, pageSize).then((data) => {
    //             const usersToUpdate = [...users];
    //             usersToUpdate.push(...data.users)
    //             setUsers(usersToUpdate);
    //             setPage(page + 1);
    //             resolve(true);
    //         });
    //
    //     });
    // };
    // const nothingLoad = (startIndex: number, stopIndex: number) => {
    //     return new Promise((resolve) => {
    //         resolve(true);
    //     });
    // };
    //
    // const loadMoreItems = isLoading ? nothingLoad : loadNextPage;

    useEffect(() => {
        if (selectedUser){
            onOpen();
        }
    }, [selectedUser])

    useEffect(() => {
        if (!isOpen) {
            setSelectedUser(undefined)
        }
    }, [isOpen])

    return (
        <div className={'users-page'}>
            <div className="users-page-top">
                <div className="header">{usersHeader}</div>
                {/*<div className="spacer"/>*/}
                {/*<div className="btn btn-red">*/}
                {/*    <div className="btn-content">*/}
                {/*        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*                  d="M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55229 12 6C12 5.44772 11.5523 5 11 5H7V1Z"*/}
                {/*                  fill="white"/>*/}
                {/*        </svg>*/}
                {/*        Добавить пользователя*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="users-page-top">
                <TextInput onChange={setFilter} placeholder={'filter'} id={'filter'} value={filter}/>
            </div>
            <div className="users-page-list">
                {/*<CircleLoader isLoading={isLoading}/>*/}

                {/*<InfiniteLoader*/}
                {/*    isItemLoaded={isItemLoaded}*/}
                {/*    itemCount={total}*/}
                {/*    loadMoreItems={loadMoreItems}*/}
                {/*>*/}
                {/*    {({onItemsRendered, ref}) => (*/}
                        <AutoSizer>
                            {({height, width}) => (
                                <List
                                    className="List"
                                    height={height}
                                    itemCount={filteredUsers.length}
                                    itemSize={100}
                                    // onItemsRendered={onItemsRendered}
                                    // ref={ref}
                                    width={width}
                                >
                                    {Row}
                                </List>
                            )}
                        </AutoSizer>
                {/*    )}*/}
                {/*</InfiniteLoader>*/}

                {/*{users.map((user: any, index) => {*/}
                {/*    return (*/}
                {/*        <div className="user-item">*/}
                {/*            <div className="line line-icon"/>*/}
                {/*            <div className="line line-name">{user.userName}</div>*/}
                {/*            <div className="line line-id">{user.id}</div>*/}
                {/*            <div className="settings-icon">*/}
                {/*                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                {/*                    <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*                          d="M5 13.75C5.46413 13.75 5.90925 13.5656 6.23744 13.2374C6.56563 12.9092 6.75 12.4641 6.75 12C6.75 11.5359 6.56563 11.0908 6.23744 10.7626C5.90925 10.4344 5.46413 10.25 5 10.25C4.53587 10.25 4.09075 10.4344 3.76256 10.7626C3.43437 11.0908 3.25 11.5359 3.25 12C3.25 12.4641 3.43437 12.9092 3.76256 13.2374C4.09075 13.5656 4.53587 13.75 5 13.75ZM19 13.75C19.4641 13.75 19.9092 13.5656 20.2374 13.2374C20.5656 12.9092 20.75 12.4641 20.75 12C20.75 11.5359 20.5656 11.0908 20.2374 10.7626C19.9092 10.4344 19.4641 10.25 19 10.25C18.5359 10.25 18.0908 10.4344 17.7626 10.7626C17.4344 11.0908 17.25 11.5359 17.25 12C17.25 12.4641 17.4344 12.9092 17.7626 13.2374C18.0908 13.5656 18.5359 13.75 19 13.75ZM13.75 12C13.75 12.4641 13.5656 12.9092 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0908 13.5656 10.7626 13.2374C10.4344 12.9092 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0908 10.7626 10.7626C11.0908 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0908 13.75 11.5359 13.75 12Z"*/}
                {/*                          fill="black"/>*/}
                {/*                    <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*                          d="M5 13.75C5.46413 13.75 5.90925 13.5656 6.23744 13.2374C6.56563 12.9092 6.75 12.4641 6.75 12C6.75 11.5359 6.56563 11.0908 6.23744 10.7626C5.90925 10.4344 5.46413 10.25 5 10.25C4.53587 10.25 4.09075 10.4344 3.76256 10.7626C3.43437 11.0908 3.25 11.5359 3.25 12C3.25 12.4641 3.43437 12.9092 3.76256 13.2374C4.09075 13.5656 4.53587 13.75 5 13.75ZM19 13.75C19.4641 13.75 19.9092 13.5656 20.2374 13.2374C20.5656 12.9092 20.75 12.4641 20.75 12C20.75 11.5359 20.5656 11.0908 20.2374 10.7626C19.9092 10.4344 19.4641 10.25 19 10.25C18.5359 10.25 18.0908 10.4344 17.7626 10.7626C17.4344 11.0908 17.25 11.5359 17.25 12C17.25 12.4641 17.4344 12.9092 17.7626 13.2374C18.0908 13.5656 18.5359 13.75 19 13.75ZM13.75 12C13.75 12.4641 13.5656 12.9092 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0908 13.5656 10.7626 13.2374C10.4344 12.9092 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0908 10.7626 10.7626C11.0908 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0908 13.75 11.5359 13.75 12Z"*/}
                {/*                          fill="#9EA2A9"/>*/}
                {/*                </svg>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*})}*/}
            </div>
            <Modal
                onClose={() => onToggle()}
                // onOpen={() => onToggle()}
                open={isOpen}
            >
                {selectedUser && <UserPage selectedUser={selectedUser}/>}
            </Modal>
        </div>
    )
}

export default UsersPage;
