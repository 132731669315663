import React, {useEffect} from 'react';
import 'semantic-ui-css/semantic.min.css'


import "./Styles/styles.scss";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from './Components/PrivateRoute';
import LoginPage from "./Pages/LoginPage";
import AuthProvider from "./Context/RootContext";
import AdminPage from "./Views/AdminPage";
import LogView from "./Views/LogView";
import WelcomePage from "./Pages/WelcomePage";
import AboutPage from "./Pages/AboutPage";
import MainContentPage from "./Pages/MainContentPage";
import UsersPage from "./Pages/UsersPage";
import {Scrollbars} from 'rc-scrollbars';
import LogsPage from "./Pages/LogsPage";
import PublicPluginsPage from "./Pages/PublicPluginsPage";
import 'rc-slider/assets/index.css';
import CloudConfigPage from "./Pages/CloudConfigPage";
import StatsPage from "./Pages/StatsPage";
import PublicRoute from "./Components/PublicRoute";
import AddPluginsPage from "./Pages/AddPluginsPage";
import AddLibraryDllFilePage from "./Pages/AddLibraryDllFilePage";
import SignalRProvider from "./Context/SignalRContext";
import CookieConsent, { Cookies } from "react-cookie-consent";
// @ts-ignore
import LocalPluginsPage from "./Pages/LocalPluginsPage";
import {ToastProvider} from "react-toast-notifications";
import PluginManagerPage from "./Pages/PluginManagerPage";
import AccountPage from "./Views/AccountPage";
import LanguageProvider from "./Context/LanguageContext";
import InvalidLocalTimePage from "./Pages/InvalidLocalTimePage";
import TestPage from "./Pages/TestPage";
import keys from "./translations/keys";
import {IRouteItem} from "./routes";
import {useTranslation} from "react-i18next";
import claimTypes from "./claims";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import ResetPasswordErrorPage from "./Pages/ResetPasswordErrorPage";
import ResetPasswordErrorConfirm from "./Pages/ResetPasswordErrorConfirm";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import TermOfUse from "./Pages/TermOfUse";
import Privacy from "./Pages/Privacy";
import Contacts from "./Pages/Contacts";
import BuildLogView from "./Views/BuildLogView";
import SuccessPayment from "./Views/SuccessPayment";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import BuySubscribePage from "./Pages/BuySubscribePage";
import SignInCallback from "./Pages/SignInCallback";
import DataProvider from "./Context/DataContext";
import ConfirmEmailPage from "./Pages/ConfirmEmailPage";
// import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import Constants from "./Configuration";
import {Container} from "semantic-ui-react";
import NewYearBanner from "./Components/NewYearBanner";
import Snowfall from "react-snowfall";
import ShowFallComponent from "./Components/ShowFallComponent";
import ForumPage from "./Pages/Forum/ForumMainPage";
import ForumThemePage from "./Pages/Forum/ForumThemePage";
import SilentRenew from "./Pages/SilentRenew";

function App() {
    const {t, i18n} = useTranslation();
    const claimsToHaveForAdmin = ['r_devpanel_in_loader'];

    const RouteItems: IRouteItem[] = [
        {
            name: t(keys.lefPanel.dashboard.main),
            url: '/main',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M7 2H3.5C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H7V7V6V2ZM7 1H3.5C2.11929 1 1 2.11929 1 3.5V12.5C1 13.8807 2.11929 15 3.5 15H7H8H12.5C13.8807 15 15 13.8807 15 12.5V7V6V3.5C15 2.11929 13.8807 1 12.5 1H8H7ZM14 6V3.5C14 2.67157 13.3284 2 12.5 2H8V6H14ZM8 7V14H12.5C13.3284 14 14 13.3284 14 12.5V7H8Z"
                              fill="#778192"/>
                    </svg>

                )
            }
            ,
            routeItem: MainContentPage
        },
        {
            name: t(keys.lefPanel.dashboard.main),
            url: '/buy',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M7 2H3.5C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H7V7V6V2ZM7 1H3.5C2.11929 1 1 2.11929 1 3.5V12.5C1 13.8807 2.11929 15 3.5 15H7H8H12.5C13.8807 15 15 13.8807 15 12.5V7V6V3.5C15 2.11929 13.8807 1 12.5 1H8H7ZM14 6V3.5C14 2.67157 13.3284 2 12.5 2H8V6H14ZM8 7V14H12.5C13.3284 14 14 13.3284 14 12.5V7H8Z"
                              fill="#778192"/>
                    </svg>

                )
            },
            routeItem: BuySubscribePage
        },
        {
            name: t(keys.lefPanel.dashboard.main),
            url: '/forum/page/:id',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M7 2H3.5C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H7V7V6V2ZM7 1H3.5C2.11929 1 1 2.11929 1 3.5V12.5C1 13.8807 2.11929 15 3.5 15H7H8H12.5C13.8807 15 15 13.8807 15 12.5V7V6V3.5C15 2.11929 13.8807 1 12.5 1H8H7ZM14 6V3.5C14 2.67157 13.3284 2 12.5 2H8V6H14ZM8 7V14H12.5C13.3284 14 14 13.3284 14 12.5V7H8Z"
                              fill="#778192"/>
                    </svg>

                )
            },
            routeItem: ForumPage,
            claimsToHave: [claimTypes.readDevPanel]
        },
        {
            name: t(keys.lefPanel.dashboard.main),
            url: '/forum/theme/:id',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M7 2H3.5C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H7V7V6V2ZM7 1H3.5C2.11929 1 1 2.11929 1 3.5V12.5C1 13.8807 2.11929 15 3.5 15H7H8H12.5C13.8807 15 15 13.8807 15 12.5V7V6V3.5C15 2.11929 13.8807 1 12.5 1H8H7ZM14 6V3.5C14 2.67157 13.3284 2 12.5 2H8V6H14ZM8 7V14H12.5C13.3284 14 14 13.3284 14 12.5V7H8Z"
                              fill="#778192"/>
                    </svg>

                )
            },
            routeItem: ForumThemePage,
            claimsToHave: [claimTypes.readDevPanel]
        },
        {
            name: t(keys.lefPanel.dashboard.plugins),
            url: '/plugins/:id?',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13 6C13 8.14202 11.6896 10.4123 10.2191 12.2503C9.50159 13.1472 8.78159 13.8975 8.24042 14.4236C8.15548 14.5062 8.0751 14.5831 8 14.6541C7.9249 14.5831 7.84452 14.5062 7.75958 14.4236C7.21841 13.8975 6.49841 13.1472 5.78087 12.2503C4.31044 10.4123 3 8.14202 3 6C3 3.23858 5.23858 1 8 1C10.7614 1 13 3.23858 13 6ZM14 6C14 11 8 16 8 16C8 16 2 11 2 6C2 2.68629 4.68629 0 8 0C11.3137 0 14 2.68629 14 6ZM10 6C10 7.10457 9.10457 8 8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6ZM11 6C11 7.65685 9.65685 9 8 9C6.34315 9 5 7.65685 5 6C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6Z"
                              fill="#778192"/>
                    </svg>
                )
            }
            ,
            routeItem: PublicPluginsPage
        },
        {
            name: t(keys.lefPanel.dashboard.addPlugin),
            url: '/add-plugin',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M6.12209 13.4733L4.5 12.5L3 13.4L2.75725 13.5457C2.42399 13.7456 2 13.5056 2 13.1169V12.8338V2.5C2 1.67157 2.67157 1 3.5 1H10.5C11.3284 1 12 1.67157 12 2.5V6.75777C13.206 7.56504 14 8.93979 14 10.5C14 12.9853 11.9853 15 9.5 15C8.15439 15 6.94673 14.4094 6.12209 13.4733ZM11 2.5V6.25606C10.5308 6.09023 10.026 6 9.5 6C8.42835 6 7.4442 6.3746 6.67133 7H5.5C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H5.75777C5.27914 8.71505 5 9.57493 5 10.5C5 10.929 5.06004 11.3441 5.17219 11.7371L5.0145 11.6425L4.75725 11.4882C4.59891 11.3932 4.40109 11.3932 4.24275 11.4882L3.9855 11.6425L3 12.2338V2.5C3 2.22386 3.22386 2 3.5 2H10.5C10.7761 2 11 2.22386 11 2.5ZM5 4.5C5 4.22386 5.22386 4 5.5 4H8.5C8.77614 4 9 4.22386 9 4.5C9 4.77614 8.77614 5 8.5 5H5.5C5.22386 5 5 4.77614 5 4.5ZM13 10.5C13 12.433 11.433 14 9.5 14C7.567 14 6 12.433 6 10.5C6 8.567 7.567 7 9.5 7C11.433 7 13 8.567 13 10.5ZM11.8536 9.85355C12.0488 9.65829 12.0488 9.34171 11.8536 9.14645C11.6583 8.95118 11.3417 8.95118 11.1464 9.14645L9 11.2929L7.85355 10.1464C7.65829 9.95118 7.34171 9.95118 7.14645 10.1464C6.95118 10.3417 6.95118 10.6583 7.14645 10.8536L8.64645 12.3536C8.84171 12.5488 9.15829 12.5488 9.35355 12.3536L11.8536 9.85355Z"
                              fill="#778192"/>
                    </svg>
                )
            }
            ,
            routeItem: AddPluginsPage
        },
        {
            name: t(keys.lefPanel.dashboard.localPlugin),
            url: '/local-plugins',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                              fill="#778192"/>
                    </svg>
                )
            }
            ,
            routeItem: LocalPluginsPage
        },
        {
            name: t(keys.lefPanel.dashboard.cloudConfig),
            url: '/cloud-config',
            icon: () => {
                return (
                    // // <img src={cloud} alt="logo" />
                    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width={22} height={22}>
                    //     <defs id="defs3051">
                    //     </defs>
                    //     <path
                    //         style={{fill: "currentcolor", fillOpacity: 1, stroke: 'none'}}
                    //         d="M 11 4 A 6 6 0 0 0 5 10 A 6 6 0 0 0 5.0039062 10.128906 A 4 4 0 0 0 2 14 A 4 4 0 0 0 6 18 L 15 18 A 5 5 0 0 0 20 13 A 5 5 0 0 0 16.757812 8.3242188 A 6 6 0 0 0 11 4 z M 11 5 A 5 5 0 0 1 15.919922 9.1113281 A 4.0000019 4.0000019 0 0 1 19 13 A 4.0000019 4.0000019 0 0 1 15 17 L 6 17 A 2.9999979 2.9999979 0 0 1 3 14 A 2.9999979 2.9999979 0 0 1 6 11 A 2.9999979 2.9999979 0 0 1 6.1074219 11.005859 A 5 5 0 0 1 6 10 A 5 5 0 0 1 11 5 z "
                    //         className="ColorScheme-Text"/>
                    // </svg>

                    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.9999 16H5.99992C2.87429 16.0019 0.270809 13.6038 0.0162787 10.4886C-0.238251 7.37335 1.94144 4.58454 5.02592 4.079C6.44563 1.56189 9.11003 0.00351115 11.9999 -5.38556e-05C13.8016 -0.0066412 15.5516 0.601515 16.9609 1.724C18.3448 2.8216 19.3288 4.34434 19.7609 6.057C22.3477 6.45094 24.1932 8.77262 23.9936 11.3816C23.7939 13.9905 21.6165 16.0043 18.9999 16ZM11.9999 1.99999C9.83163 2.00255 7.83259 3.1721 6.76792 5.061L6.29992 5.9L5.35091 6.055C3.3012 6.3984 1.85592 8.2543 2.02513 10.3257C2.19433 12.3971 3.92164 13.9938 5.99992 14H18.9999C20.5685 14.0016 21.8735 12.7946 21.9941 11.2307C22.1147 9.66673 21.0102 8.2739 19.4599 8.035L18.1439 7.835L17.8219 6.543C17.1572 3.86981 14.7545 1.99496 11.9999 1.99999Z"
                            fill="#778192"/>
                    </svg>

                )
            }
            ,
            routeItem: CloudConfigPage
        },
        {
            name: t(keys.lefPanel.dashboard.account),
            url: '/account',
            icon: () => {
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M11 4C11 5.65685 9.65685 7 8 7C6.34315 7 5 5.65685 5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4ZM12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4ZM1.02242 15C1.27504 12.1968 3.63098 10 6.5 10H9.5C12.369 10 14.725 12.1967 14.9776 15H1.02242ZM9.5 9C12.9216 9 15.7257 11.6438 15.9811 15C15.9936 15.165 16 15.3318 16 15.5V16H15H1H0V15.5C0 15.3318 0.00639146 15.165 0.0189448 15C0.274257 11.6438 3.07839 9 6.5 9H9.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            routeItem: AccountPage
        },
        //admins
        {
            name: t(keys.lefPanel.admin.users),
            url: '/users',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M5.5 2H10.5C10.7761 2 11 2.22386 11 2.5V5.5C11 5.77614 10.7761 6 10.5 6H9.5H6.5H5.5C5.22386 6 5 5.77614 5 5.5V2.5C5 2.22386 5.22386 2 5.5 2ZM7 7H9V8.5C9 8.77614 9.22386 9 9.5 9H12V10H10.5C9.67157 10 9 10.6716 9 11.5V13.5C9 14.3284 9.67157 15 10.5 15H13.5C14.3284 15 15 14.3284 15 13.5V11.5C15 10.6716 14.3284 10 13.5 10H13V8.5C13 8.22386 12.7761 8 12.5 8H10V7H10.5C11.3284 7 12 6.32843 12 5.5V2.5C12 1.67157 11.3284 1 10.5 1H5.5C4.67157 1 4 1.67157 4 2.5V5.5C4 6.32843 4.67157 7 5.5 7H6V8H3.5C3.22386 8 3 8.22386 3 8.5V10H2.5C1.67157 10 1 10.6716 1 11.5V13.5C1 14.3284 1.67157 15 2.5 15H5.5C6.32843 15 7 14.3284 7 13.5V11.5C7 10.6716 6.32843 10 5.5 10H4V9H6.5C6.77614 9 7 8.77614 7 8.5V7ZM3.5 11H2.5C2.22386 11 2 11.2239 2 11.5V13.5C2 13.7761 2.22386 14 2.5 14H5.5C5.77614 14 6 13.7761 6 13.5V11.5C6 11.2239 5.77614 11 5.5 11H3.5ZM10.5 11H12.5H13.5C13.7761 11 14 11.2239 14 11.5V13.5C14 13.7761 13.7761 14 13.5 14H10.5C10.2239 14 10 13.7761 10 13.5V11.5C10 11.2239 10.2239 11 10.5 11Z"
                              fill="#F2F2F2"/>
                    </svg>
                )
            },
            routeItem: UsersPage,
            isAdminPanel: true
        },
        {
            name: t(keys.lefPanel.admin.logs),
            url: '/logs',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            isAdminPanel: true,
            routeItem: LogsPage
        },
        {
            name: t(keys.lefPanel.admin.statistics),
            url: '/stats',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            isAdminPanel: true,
            routeItem: StatsPage
        },
        {
            name: t(keys.lefPanel.admin.loadLibrary),
            url: '/library-dlls',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            claimsToHave: [claimTypes.createScript],
            isAdminPanel: true,
            routeItem: AddLibraryDllFilePage
        },
        {
            name: t(keys.lefPanel.admin.pluginManager),
            url: '/plugin-manager',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            claimsToHave: [claimTypes.approveScripts],
            isAdminPanel: true,
            routeItem: PluginManagerPage
        },
        {
            name: t(keys.lefPanel.admin.oldMenu),
            url: '/admin',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            claimsToHave: [claimTypes.createScript, claimTypes.createUsers],
            isAdminPanel: true,
            routeItem: AdminPage
        },
        {
            name: 'Страница для тестов',
            url: '/dev',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            claimsToHave: [claimTypes.readTestPanel, claimTypes.readDevPanel],
            isAdminPanel: true,
            routeItem: TestPage
        },
        {
            name: 'Платежи',
            url: '/payments',
            icon: () => {
                return (
                    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.5 2H2.5C2.22386 2 2 2.22386 2 2.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V2.5C14 2.22386 13.7761 2 13.5 2ZM15 2.5C15 1.67157 14.3284 1 13.5 1H2.5C1.67157 1 1 1.67157 1 2.5V13.5C1 14.3284 1.67157 15 2.5 15H13.5C14.3284 15 15 14.3284 15 13.5V2.5ZM11.5 4C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5C4 4.22386 4.22386 4 4.5 4H11.5ZM12 7.5C12 7.22386 11.7761 7 11.5 7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H11.5C11.7761 8 12 7.77614 12 7.5ZM11.5 10C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5Z"
                              fill="#778192"/>
                    </svg>
                )
            },
            claimsToHave: [claimTypes.ReadPayments, claimTypes.readDevPanel],
            isAdminPanel: true,
            routeItem: TestPage
        }
    ]
    const setDocHeight = () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    }

    const options = {
        'client-id': Constants.paypalClientId,
        intent: 'capture',
        currency: 'USD'
    }

    useEffect(() => {
        setDocHeight();
        window.addEventListener('resize', setDocHeight);
        window.addEventListener('orientationchange', setDocHeight);
        return () => {
            window.removeEventListener('resize', setDocHeight);
            window.removeEventListener('orientationchange', setDocHeight);
        }
    }, [])

    return (
        <Router>
            <LanguageProvider>

                <CookieConsent>{t(keys.cookies.text)}<a href={'Docs/data_processing.pdf'}>{t(keys.cookies.text2)}</a></CookieConsent>
                <Switch>
                    <Route exact path="/invalid-time" component={InvalidLocalTimePage}/>
                    <Route path="/signin-callback.html" component={SignInCallback}/>
                    <Route path="/silent-renew.html" component={SilentRenew}/>
                    <AuthProvider>
                        <ToastProvider>
                            <DataProvider>
                                {/*<PayPalScriptProvider options={options}>*/}
                                    <Switch>
                                        <PublicRoute exact path="/" Component={WelcomePage}/>
                                        {/*<Route exact path="/Docs/terms_of_use.pdf" render={() => {*/}
                                        {/*    window.location.href = 'Docs/terms_of_use.pdf';*/}
                                        {/*    return null;*/}
                                        {/*    // return <Redirect  to="TermsOfUse.pdf" />*/}
                                        {/*}} />*/}
                                        {/*<Route exact path="/Docs/privacy_policy.pdf" render={() => {*/}
                                        {/*    window.location.href = 'Docs/privacy_policy.pdf';*/}
                                        {/*    return null;*/}
                                        {/*    // return <Redirect  to="Privacy.pdf" />*/}
                                        {/*}} />*/}
                                        {/*<Route exact path="/Docs/data_processing.pdf" render={() => {*/}
                                        {/*    window.location.href = 'Docs/data_processing.pdf';*/}
                                        {/*    return null;*/}
                                        {/*    // return <Redirect  to="Privacy.pdf" />*/}
                                        {/*}} />*/}
                                        <Route exact path="/about" component={AboutPage}/>
                                        {/*<Route exact path="/agreement" component={TermOfUse}/>*/}
                                        {/*<Route exact path="/privacy" component={Privacy}/>*/}
                                        <Route exact path="/contacts" component={Contacts}/>
                                        <PublicRoute exact path="/authorize" Component={LoginPage}/>
                                        <Route path="/reset-password/:code" component={ResetPasswordPage}/>
                                        <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
                                        <Route exact path="/reset-password-error" component={ResetPasswordErrorPage}/>
                                        <Route exact path="/reset-password-confirm"
                                               component={ResetPasswordErrorConfirm}/>
                                        <PrivateRoute path="/buildLog/:id" Component={BuildLogView}/>
                                        <PrivateRoute path="/confirm-email"
                                                      Component={ConfirmEmailPage}/>
                                        <Route>
                                            <SignalRProvider>
                                                <PrivateRoute path="/successPayment/:id"
                                                              Component={SuccessPayment}/>

                                                <div className="authed-place">
                                                    {/*<MainNavBar items={RouteItems}/>*/}
                                                    <Navbar adminPages={RouteItems.filter(x => x.isAdminPanel)}/>
                                                    <div className="authed-place--content">
                                                        <Switch>
                                                            <Scrollbars autoHide>
                                                                {RouteItems.map((routeItem: IRouteItem, index) => {
                                                                    return <PrivateRoute key={index} exact
                                                                                         path={routeItem.url}
                                                                                         claimsToHave={routeItem.claimsToHave}
                                                                                         Component={routeItem.routeItem}/>
                                                                })}
                                                                <PrivateRoute claimsToHave={claimsToHaveForAdmin}
                                                                              path="/logs/:id"
                                                                              Component={LogView}/>

                                                                <Footer/>
                                                            </Scrollbars>
                                                        </Switch>
                                                    </div>
                                                </div>
                                                {/*<ShowFallComponent/>*/}
                                            </SignalRProvider>
                                        </Route>
                                    </Switch>
                                {/*</PayPalScriptProvider>*/}
                            </DataProvider>
                        </ToastProvider>

                    </AuthProvider>
                </Switch>

            </LanguageProvider>
        </Router>
    );
}

export default App;
