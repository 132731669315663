import React from "react";
import {AuthContext} from "../Context/RootContext";
import Constants from "../Configuration";
import useProgress from "./useProgress";
import {IClaim} from "../Views/UserEditPage";
import {RequestType} from "../Services/ApiService";
import {IConfig} from "./useConfig";

const useClaims = () => {
    const state = React.useContext(AuthContext)
    const {MessageContainer, MessagePanel, error, isLoading, BeginLoading} = useProgress();

    const getClaims = async (userId?: string) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'Claim' + (userId ? '/' + userId : ''));
            return data.data;
        });
    }

    const setClaimsForUser = async (userId: string, claims: IClaim[]) => {
        return await BeginLoading(async () => {
            const data = await state.apiService.callApi(Constants.stsAuthority + 'Claim' + '/' + userId, claims, RequestType.POST);
            return data.data;
        });
    }

    return {
        getClaims,
        setClaimsForUser,
        MessageContainer,
        MessagePanel,
        error,
        isLoading
    }
}

export default useClaims
