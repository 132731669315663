import React, {useContext, useEffect, useState} from "react";
import useScripts, {CostType, ITogglePlugin, PluginType} from "../Hooks/useScripts";
import {SearchPanelDropdownOption} from "../Components/SearchPanel";
import CircleLoader from "../Components/CircleLoader";
import {IDropdownOption} from "../Components/Dropdown";
// @ts-ignore
import Expand from 'react-expand-animated';
import {Scrollbars} from "rc-scrollbars";
import CustomSlider from "../Components/CustomSlider";
import {useTranslation} from "react-i18next";
import PluginItem from "../Components/Plugins/PluginItem";
import {Input} from "semantic-ui-react";
import {NavLink, useParams} from "react-router-dom";
import keys from "../translations/keys";
import {DataContext} from "../Context/DataContext";
import useAuth from "../Hooks/useAuth";
import _ from "underscore";

const PublicPluginsPage = () => {
    let {id} = useParams<{ id: string | undefined }>();
    const {isLoading: isMainDataLoading, isPluginsLoaded, pluginToggleGetDtos} = useContext(DataContext);
    const {isLoading: isLoadingToggle, TogglePlugin} = useScripts();
    const [plugins, setPlugins] = useState([] as ITogglePlugin[])
    const [search, setSearch] = useState('')
    const [selectedFilter, setSelectedFilter] = useState(undefined as IDropdownOption | undefined)
    const {t} = useTranslation();
    const isLoading = isMainDataLoading || !isPluginsLoaded
    const [isOpenDescription, setIsOpenDescription] = useState(undefined as ITogglePlugin | undefined);
    const [selectedPluginForLoading, setSelectedPluginForLoading] = useState(-1);

    useEffect(() => {
        setPlugins(pluginToggleGetDtos)
    }, [pluginToggleGetDtos])


    const toggle = async (id: number, value: boolean) => {
        setPlugins((prevState) => {
            let newArr = [...prevState];
            newArr[newArr.findIndex(x => x.id === id)].isEnabled = value;
            return prevState
        })
        setSelectedPluginForLoading(id);
        await TogglePlugin(id, value);
        setSelectedPluginForLoading(-1);
    }


    const getBottom = (plugin: ITogglePlugin) => {
        const clr = CostType[plugin.costType || 0];
        switch (plugin.costType) {
            case CostType.Free:
            case undefined:
                return <div className={`btn btn-${clr}`}>Free plugin</div>
            case CostType.Buy:
                return <div className={`btn btn-${clr}`}>Buy plugin</div>
            case CostType.Purchased:
                return <div className={`btn btn-${clr}`}>Purchased</div>

        }
    }

    const checkType = (pluginType: PluginType) => {
        if (!selectedFilter)
            return true;
        switch (selectedFilter.id as SearchPanelDropdownOption) {
            case SearchPanelDropdownOption.All:
                return true;
            case SearchPanelDropdownOption.Hero:
                return pluginType === PluginType.Hero
            case SearchPanelDropdownOption.Utility:
                return pluginType === PluginType.Utility
            case SearchPanelDropdownOption.Library:
                return pluginType === PluginType.Library
        }
    }

    const getFilteredData = plugins && plugins
        .filter((x) => (
            !!id && x.id === parseInt(id!))
            || (!id && x.pluginName.toLowerCase().indexOf(search.toLowerCase()) >= 0 && checkType(x.type)));

    return (
        <div className="public-plugins-page">
            <div className="left-panel">
                {/*<SearchPanel search={search} setSearch={setSearch} setSelectedFilter={setSelectedFilter}/>*/}
                <Input value={search} icon='search' placeholder='Search...'
                       onChange={(event, data) => setSearch(data.value)}/>
                <NavLink className="link" exact to='/plugins'>{t(keys.lefPanel.dashboard.plugins)}</NavLink>
                <NavLink className="link" exact to='/local-plugins'>{t(keys.lefPanel.dashboard.localPlugin)}</NavLink>
                <NavLink className="link" exact to='/add-plugin'>{t(keys.lefPanel.dashboard.addPlugin)}</NavLink>
            </div>
            <Scrollbars autoHide hideTracksWhenNotNeeded disableDefaultStyles>
                <div className="content">
                    <CircleLoader isLoading={isLoading}/>
                    {/*{!isLoading && getFilteredData.length === 0 && <div className="no-data">Нет данных</div>}*/}
                    {getFilteredData.map((plugin: ITogglePlugin, index) => {
                        return <PluginItem plugin={plugin} toggle={toggle}
                                           isLoading={isLoadingToggle && selectedPluginForLoading === plugin.id}
                                           key={index}/>
                    })}
                </div>
            </Scrollbars>
            {isOpenDescription && <div className="description-content">
                <div className="empty-space" onClick={() => setIsOpenDescription(undefined)}/>
                <div className="description">
                    <div className="top">
                        <div className="close-icon" onClick={() => setIsOpenDescription(undefined)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                    fill="#5F5F5F"/>
                            </svg>

                        </div>
                        <div className="plugin-name">{isOpenDescription.pluginName} </div>
                    </div>
                    <div className="mini-block">
                        <div className="header">Developer</div>
                        <div className="text">{isOpenDescription.authorName}</div>
                    </div>
                    <div className="mini-block">
                        <div className="header">Plugin type</div>
                        <div className="text">{PluginType[isOpenDescription.type]}</div>
                    </div>
                    <div className="mini-block">
                        <div className="header">Price</div>
                        <div className="text">free</div>
                    </div>
                    <div className="mini-block mini-block--with-height">
                        <div className="header">Description</div>
                        <div className="text">
                            <div className="real-text">{isOpenDescription.description}</div>
                            <div className="show-more-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="5"
                                              width="8" height="13">
                                            <path
                                                d="M8.29496 7.11496L12.875 11.705L8.29496 16.295L9.70496 17.705L15.705 11.705L9.70496 5.70496L8.29496 7.11496Z"
                                                fill="#5F5F5F"/>
                                        </mask>
                                        <g mask="url(#mask0)">
                                            <rect width="24" height="24" fill="#5F5F5F"/>
                                        </g>
                                    </g>
                                </svg>

                            </div>
                        </div>
                    </div>
                    <div className="enable-block">
                        <div className="header">Enable plugin</div>
                        <CustomSlider value={isOpenDescription.isEnabled}
                                      onChange={value => toggle(isOpenDescription.id, value)}/>
                        {/*<Toggle/>*/}
                    </div>
                    <div className="close-button-wrapper" onClick={() => setIsOpenDescription(undefined)}>
                        <div className="close-button">Close</div>
                    </div>
                </div>
            </div>}
        </div>)
}

export default PublicPluginsPage;
