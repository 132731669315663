import {Input, Modal, TextArea} from "semantic-ui-react";
import {useModalState} from "../../../Hooks/useModalState";
import Button from "../../../Components/Button";
import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "@uiw/react-markdown-editor";
import {useTranslation} from "react-i18next";
import keys from "../../../translations/keys";
import HtmlEditor from "./HtmlEditor";

export type ICreateForumThemeComponentProps = {
    isActive: boolean;
    onCreated: (title: string, description: string) => void;
    onClose: () => void;
}

const CreateForumThemeComponent = (props: ICreateForumThemeComponentProps) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const {t} = useTranslation();
    useEffect(() => {
        if (!props.isActive)
            setTitle('');
    }, [props.isActive]);

    if (!props.isActive) {
        return <></>
    }

    return <Modal open={props.isActive} onClose={props.onClose}>
        <Modal.Content>
            <Input fluid placeholder={'Page Title'} value={title}
                   onChange={(event, data) => setTitle(data.value)}/>
            {/*<ReactMarkdown>{description}</ReactMarkdown>*/}
            {/*<TextArea className={'create_forum_theme_theme_area'} value={description}*/}
            {/*          onChange={(event, data) => setDescription(data.value as string)}/>*/}
            <HtmlEditor onChange={setDescription} value={description}/>
            {/*<MarkdownEditor*/}
            {/*    value={description}*/}
            {/*    height={500}*/}
            {/*    visible={true}*/}
            {/*    visibleEditor={true}*/}
            {/*    onChange={(editor: any, data: any, value: string) => {*/}
            {/*        setDescription(value);*/}
            {/*    }}*/}
            {/*/>*/}
        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={props.onClose}>{t(keys.cancel)}</Button>
            <Button positive onClick={() => props.onCreated(title, description)} disabled={title.length > 50}>{t(keys.forum.create)}</Button>
        </Modal.Actions>
    </Modal>
}

export default CreateForumThemeComponent;