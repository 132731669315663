import {useEffect, useState} from "react";
import classnames from "classnames";

export type ICustomSliderProps = {
    onChange?: (value: boolean) => void;
    value: boolean;
    disabled?: boolean;
}

const CustomSlider = ({onChange, value, disabled}: ICustomSliderProps) => {
    const [isActive, setIsActive] = useState(value);

    useEffect(() => {
        if (value !== isActive) {
            setIsActive(value);
        }
    }, [value])

    useEffect(() => {
        if (onChange && isActive !== value)
            onChange(isActive);
    }, [isActive])

    const toggle = (e: any, value: boolean) => {
        if (disabled){
            return;
        }
        e.stopPropagation();
        setIsActive(value)
    }

    const cls = classnames('custom-slider-container', {
        'custom-slider-container--active': isActive
    })
    return (
        <div className={cls} onClick={(e: any) => toggle(e, !isActive)}>
            <div className="custom-slider-body"/>
            <div className='custom-slider-circle'/>
        </div>
    )
}

export default CustomSlider;
