import {Languages} from "./translations/types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../Context/LanguageContext";
import classNames from "classnames";

export type ILangSelectorProps = {}

const LangSelector = ({}: ILangSelectorProps) => {
    const {t, i18n} = useTranslation();
    const {lang, changeLanguage} = useContext(LanguageContext);

    const clsName = (lng: Languages) => classNames("lang-selector__click-catcher", {
        "lang-selector__click-catcher--active": lng === lang
    })

    return <div className="lang-selector">
        <div className={clsName(Languages.RU)} onClick={() => changeLanguage(Languages.RU)}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-ru" viewBox="0 0 512 512">
                <g fillRule="evenodd" strokeWidth="1pt">
                    <path fill="#fff" d="M0 0h512v512H0z"/>
                    <path fill="#0039a6" d="M0 170.7h512V512H0z"/>
                    <path fill="#d52b1e" d="M0 341.3h512V512H0z"/>
                </g>
            </svg>
        </div>
        <div className={clsName(Languages.EN)} onClick={() => changeLanguage(Languages.EN)}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 512 512">
                <path fill="#012169" d="M0 0h512v512H0z"/>
                <path fill="#FFF"
                      d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"/>
                <path fill="#C8102E"
                      d="M184 324l11 34L42 512H0v-3l184-185zm124-12l54 8 150 147v45L308 312zM512 0L320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"/>
                <path fill="#FFF" d="M176 0v512h160V0H176zM0 176v160h512V176H0z"/>
                <path fill="#C8102E" d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z"/>
            </svg>
        </div>
        <div className={clsName(Languages.CH)} onClick={() => changeLanguage(Languages.CH)}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="flag-icon-css-cn"
                 viewBox="0 0 512 512">
                <defs>
                    <path id="a" fill="#ffde00" d="M1-.3L-.7.8 0-1 .6.8-1-.3z"/>
                </defs>
                <path fill="#de2910" d="M0 0h512v512H0z"/>
                <use width="30" height="20" transform="matrix(76.8 0 0 76.8 128 128)" xlinkHref="#a"/>
                <use width="30" height="20" transform="rotate(-121 142.6 -47) scale(25.5827)" xlinkHref="#a"/>
                <use width="30" height="20" transform="rotate(-98.1 198 -82) scale(25.6)" xlinkHref="#a"/>
                <use width="30" height="20" transform="rotate(-74 272.4 -114) scale(25.6137)" xlinkHref="#a"/>
                <use width="30" height="20" transform="matrix(16 -19.968 19.968 16 256 230.4)" xlinkHref="#a"/>
            </svg>
        </div>
    </div>
}

export default LangSelector;
