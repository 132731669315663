import React from "react";
import {Redirect, Route} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import useAuth from "../Hooks/useAuth";

export type IPublicRouteProps = {
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
    exact?: boolean,
    path: string,
}

const PublicRoute: React.FC<IPublicRouteProps> = ({Component, ...rest}) => {
    const {isAuthenticated} = useAuth();
    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated
                    ? <Component {...props} />
                    : <Redirect to="/main"/>
            }
        />
    )
}
export default PublicRoute

