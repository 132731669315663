import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Container, Form, Icon, Input, List, Modal} from 'semantic-ui-react'
import useAuth from "../Hooks/useAuth";
import axios from 'axios';
import useUsers from "../Hooks/useUsers";

const regex = /^\w_\w*/;

export type IClaim = {
    name: string,
    isEnabled: boolean
}

export type IUserCreateModel = {
    Username: string,
    Password: string,
}

const UserCreatePage = ({open, setOpen}: any) => {
    const {state} = useAuth();

    const {createUser, MessagePanel, isLoading} = useUsers()

    const [userData, setUserData] = useState({
        Username: '',
        Password: '',
    } as IUserCreateModel)

    const onUpdateProp = (argName: string, newValue: string) => {
        setUserData(prevState => {
            return {...prevState, [argName]:newValue}
        })
    }

    const save = async () => {
        try {
            await createUser(userData)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size={"mini"}
        >
            <Modal.Header>Create new user</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <MessagePanel/>
                    <Container textAlign={"center"}>
                        <Input fluid placeholder='login' onChange={(event, data) => onUpdateProp('Username',data.value)}/>
                        <br />
                        <Input fluid placeholder='password' onChange={(event, data) => onUpdateProp('Password',data.value)}/>
                    </Container>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' loading={isLoading} onClick={() => setOpen(false)}>
                    Back
                </Button>
                <Button
                    content="Create"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => save()}
                    positive
                    loading={isLoading}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default UserCreatePage