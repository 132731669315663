import React, {useState} from "react";
import TextInput from "../Components/TextInput";
import useScripts, {CreateScriptDto, PluginType} from "../Hooks/useScripts";
import {Dimmer, Input, Loader} from "semantic-ui-react";
import CategoryPicker, {IICategoryPickerItem} from "../Components/CategoryPicker";
import classnames from "classnames";
import {useToasts} from "react-toast-notifications";
import {NavLink} from "react-router-dom";
import keys from "../translations/keys";
import {useTranslation} from "react-i18next";

const AddPluginsPage = () => {
    const [pluginName, setPluginName] = useState("");
    const [git, setGit] = useState("");
    // const [youTubeLink, setYouTubeLink] = useState("");
    // const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [pluginType, setPluginType] = useState(PluginType.Unknown);
    const {CreateScript, isLoading, error} = useScripts();
    const {addToast} = useToasts();
    const {t} = useTranslation();
    const validateGitUrl = () => {
        const regex = /(http(s)?)(:\/\/)?github\.com\/\S*(.csproj)/gm;
        return regex.test(git);
    }

    const validate = () => {
        if (!pluginName || !git || !description || pluginType === PluginType.Unknown || !validateGitUrl())
            return false;

        return true;
    }

    const addPlugin = async () => {
        if (isLoading)
            return;
        if (!validate())
            return;

        const data: CreateScriptDto = {
            Name: pluginName,
            GithubUrl: git,
            PluginType: pluginType,
            FullDescription: description,
            Author: ''
        }
        await CreateScript(data)
        if (!error) {
            setPluginName('');
            setGit('');
            setDescription('');
            setPluginType(PluginType.Unknown);
            addToast(`The plugin was successfully sent for verification`, {appearance: "success", autoDismiss: true})
        }
    }

    const categories: IICategoryPickerItem[] = [
        {
            id: PluginType.Hero,
            text: 'Hero'
        }, {
            id: PluginType.Utility,
            text: 'Utils'
        }, {
            id: PluginType.Library,
            text: 'Library'
        },
    ]

    const acceptBtnCls = classnames(
        'btn', 'btn--accept', {'btn--disabled': !validate()}
    )

    return <div className="add-plugins-page">
        <div className="left-panel">
            {/*<SearchPanel search={search} setSearch={setSearch} setSelectedFilter={setSelectedFilter}/>*/}
            <NavLink className="link" exact to='/plugins'>{t(keys.lefPanel.dashboard.plugins)}</NavLink>
            <NavLink className="link" exact to='/local-plugins'>{t(keys.lefPanel.dashboard.localPlugin)}</NavLink>
            <NavLink className="link" exact to='/add-plugin'>{t(keys.lefPanel.dashboard.addPlugin)}</NavLink>
        </div>
        <div className="add-plugins-page__content">
            <div className="add-plugins-page--header">
                <div className="title">Add new plugin to our database?</div>
                {/*<div className="btn btn--cancel">Отменить</div>*/}
                <div className={acceptBtnCls} onClick={addPlugin}>
                    Send to approve
                </div>
                <div className="tooltip">
                    <div className="red">All categories marked with *</div>
                    are required!
                </div>
            </div>
            <Dimmer active={isLoading}>
                <Loader/>
            </Dimmer>


            <div className="add-plugins-page--container">
                <div className="left-part">
                    {/*<div className="block">*/}
                    {/*    <div className="block--header">*/}
                    {/*        Фотография для скрипта*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="block">
                        <div className="block--header">
                            Name *
                        </div>
                        <div className="block--content">
                            <TextInput id={'name'} placeholder={"Plugin name"} value={pluginName}
                                       onChange={value => setPluginName(value)}/>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block--header">
                            Description *
                        </div>
                        <div className="block--content">
                        <textarea className="textarea" value={description}
                                  onChange={value => setDescription(value.target.value)}
                                  placeholder="Full description"/>
                        </div>
                    </div>
                    {/*<div className="block">*/}
                    {/*    <div className="block--header">*/}
                    {/*        Youtube link*/}
                    {/*    </div>*/}
                    {/*    <div className="block--content">*/}
                    {/*        <div className="subtext">*/}
                    {/*            Хуй знает что тут написать но пусть типо тут дохуя важной инфы как вставлять ссылку, какой*/}
                    {/*            должен быть репозиторий и какая длина световой волны от венеры до юпитера*/}
                    {/*        </div>*/}
                    {/*        <TextInput id={'youtube'} placeholder={"Вставьте ссылку на видео из ютуба"} value={youTubeLink}*/}
                    {/*                   onChange={value => setYouTubeLink(value)}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="right-part">
                    <div className="block">
                        <div className="block--header">
                            Category *
                        </div>
                        <div className="block--content">
                            {/*<TagsSelector isMulti={false}/>*/}
                            <CategoryPicker items={categories} onSelect={item => setPluginType(item.id)}/>
                            {/*<div className="btn-row-list">*/}
                            {/*    <div className="btn btn-rounded">Combo</div>*/}
                            {/*    <div className="btn btn-rounded">Utils</div>*/}
                            {/*    <div className="btn btn-rounded">Library</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="block">
                        <div className="block--header">
                            Github *
                        </div>
                        <div className="block--content">
                            <div className="subtext">
                                example: <br/>
                                https://github.com/JumpAttacker/Crappahilation/blob/master/TechiesCrappahilationPaid/TechiesCrappahilationPaid.csproj
                            </div>

                            <TextInput id={'git'} placeholder={"link on *.csproj file in your github repository"}
                                       value={git} error={(!validateGitUrl() && !!git)}
                                       onChange={value => setGit(value)}/>
                        </div>
                    </div>
                    {/*<div className="block">*/}
                    {/*    <div className="block--header">*/}
                    {/*        Цена*/}
                    {/*    </div>*/}
                    {/*    <div className="block--content">*/}
                    {/*        <div className="subtext">При указании цены равной 0 , плагин будет распространяться бесплатно!*/}
                    {/*        </div>*/}
                    {/*        <TextInput id={'price'}*/}
                    {/*                   placeholder={"Установите будущюю цену, указываеться в долларах"}*/}
                    {/*                   value={price}*/}
                    {/*                   onChange={value => setPrice(value)}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>

    </div>
}

export default AddPluginsPage;
