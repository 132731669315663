export interface IClaims {
    readScripts: string;
    updateStatus: string;
    createScript: string;
    deleteScript: string;
    updateScript: string;
    readUsers: string;
    createUsers: string;
    deleteUsers: string;
    updateUsers: string;
    readClaims: string;
    readDevPanel: string;
    approveScripts: string;
    createToken: string;
    readToken: string;
    updateToken: string;
    deleteToken: string;
    readIps: string;
    readHwids: string;
    readTestPanel: string;
    readStatistics: string;
    readLogs: string;
    deleteLogs: string;
    InfinityGames: string;
    ReadResellerPanel: string;
    ReadSupportPanel: string;
    UpdateHwid: string;
    ReadPayments: string;
}

const claimTypes: IClaims = {
    updateStatus: "u_status",
    readScripts: "r_script",
    createScript: "c_script",
    deleteScript: "d_script",
    updateScript: "u_script",
    readUsers: "r_user",
    createUsers: "c_user",
    deleteUsers: "d_user",
    updateUsers: "u_user",
    readClaims: "r_claim",
    readDevPanel: "r_devpanel_in_loader",
    approveScripts: "a_script",
    createToken: "c_tokens",
    readToken: "r_tokens",
    updateToken: "u_tokens",
    deleteToken: "d_tokens",
    readIps: "r_ip",
    readHwids: "r_hwid",
    readTestPanel: "r_test_panel",
    readStatistics: "r_statistics",
    readLogs: "r_logs",
    deleteLogs: "d_logs",
    InfinityGames: "infinity_games",
    ReadResellerPanel: "r_reseller_panel",
    ReadSupportPanel: "r_support_panel",
    UpdateHwid: "u_hwid",
    ReadPayments: "r_payments",
}

export default claimTypes;
