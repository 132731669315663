import {useTranslation} from "react-i18next";
import {IConfigList} from "../../Hooks/useConfig";
import React, {useEffect, useRef, useState} from "react";
import Button from "../../Components/Button";
import {Icon, Input} from "semantic-ui-react";
import moment from "moment";
import {useToasts} from "react-toast-notifications";
import keys from "../../translations/keys";
import Image from "react-image-webp";
import classnames from "classnames";
import CustomSlider from "../../Components/CustomSlider";

export type IMyConfigBrowserProps = {
    getMyConfigList: () => Promise<any>
    onToggleConfig: (config: IConfigList) => Promise<any>
    setConfigPublicFlag: (config: IConfigList) => Promise<any>
    onDeleteClick: (config: IConfigList) => Promise<any>
    isLoading: boolean;
    privateConfigs: IConfigList[];
    renameConfig: (configId: number, newName: string) => Promise<any>;
}

const MyConfigBrowser = ({
                             getMyConfigList,
                             onToggleConfig,
                             setConfigPublicFlag,
                             isLoading,
                             renameConfig,
                             privateConfigs: configs,
                             onDeleteClick
                         }: IMyConfigBrowserProps) => {
    const {t} = useTranslation();
    const {addToast} = useToasts();
    const inputEl = useRef(null as any);

    const [editMode, setEditMode] = useState(0);
    const [rename, setRename] = useState('');

    const saveHandler = async () => {
        if (rename !== configs?.find((z) => z.id === editMode)?.name) {
            await renameConfig(editMode, rename);
        }
        setEditMode(0);
        setRename('');
    }

    const onOverlayClick = async (e: any) => {
        if (editMode) {
            await saveHandler();
        }
    };

    useEffect(() => {
        window.addEventListener('click', onOverlayClick);

        return () => {
            window.removeEventListener('click', onOverlayClick, false);
        }
    }, [onOverlayClick])

    useEffect(() => {
        // fetch();
    }, [])

    const setConfigPublicFlagHandler = async (config: IConfigList) => {
        await setConfigPublicFlag(config);
        await getMyConfigList();
    }

    const onToggleConfigHandler = async (config: IConfigList) => {
        await onToggleConfig(config)
        await getMyConfigList();
    }

    const onDeleteClickHandler = async (config: IConfigList) => {
        if (config.isActive)
            return;
        await onDeleteClick(config)
        await getMyConfigList();
    }

    const enableRenameMode = (config: IConfigList) => {
        setEditMode(config.id);
        setRename(config.name);
    }

    const onKeyDownHandler = async (e: any) => {
        if (e.keyCode === 13) {
            await saveHandler();

        } else if (e.keyCode === 27) {
            setEditMode(0);
        }
    }

    useEffect(() => {
        if (editMode) {
            if (inputEl && inputEl.current) {
                inputEl.current.focus();
            }
        }
    }, [editMode]);

    const lockBtnCls = (isPublic: boolean) => classnames('lock-btn', {
        'lock-btn--public': isPublic
    })

    return <div className="my-configs-browser">
        <div className="my-configs-browser--title">
            {t(keys.configPage.myConfigs.title)}
        </div>
        <div className="my-configs-browser--list">
            {/*<Scrollbars>*/}
            <div className="my-configs-browser--list--content">
                {configs?.map((config, index) => {
                    return <div className="config-item" key={index}>
                        {/*<img className="backImage" src="/userConfig.png" alt={'divine'}/>*/}
                        {/*<Image*/}
                        {/*    className="backImage"*/}
                        {/*    src={'/userConfig.png'}*/}
                        {/*    webp={'/userConfig.webp'}*/}
                        {/*/>*/}
                        <div className="config-item--index">
                            {index + 1}
                        </div>
                        <div className="config-item--title-with-text">
                            <div className="config-item--title" onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                enableRenameMode(config);
                            }}>
                                {config.id === editMode ?
                                    <Input defaultValue={rename} ref={inputEl}
                                           onChange={event => setRename(event.target.value)}
                                           placeholder="name" onKeyDown={onKeyDownHandler}/> : config.name}
                            </div>
                            {/*<div className="config-item--author">*/}
                            {/*    {t(keys.configPage.author)}: {config.authorName}*/}
                            {/*</div>*/}
                        </div>
                        <div className="code-btn" onClick={event => {
                            navigator.clipboard.writeText(config.code.toString())
                                .then(() => {
                                    addToast('The code is copied', {
                                        appearance: 'success',
                                        autoDismiss: true
                                    })
                                })
                                .catch(err => {
                                    console.log('Something went wrong', err);
                                });
                        }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.971 1.75208C18.9784 1.74759 19.6705 1.74564 20.1977 1.80321C20.3691 1.82193 20.5123 1.84604 20.6339 1.87518L10.0054 12.5037C9.71253 12.7965 9.71253 13.2714 10.0054 13.5643C10.2983 13.8572 10.7732 13.8572 11.0661 13.5643L21.6946 2.93585C21.7237 3.05745 21.7478 3.20065 21.7665 3.37199C21.8241 3.89923 21.8221 4.59138 21.8177 5.59875L21.8066 8.08089C21.8047 8.4951 22.139 8.83238 22.5532 8.83423C22.9674 8.83607 23.3047 8.50179 23.3066 8.08758L23.3178 5.56074C23.3221 4.60902 23.3256 3.83119 23.2577 3.20915C23.187 2.56233 23.0314 1.98966 22.6501 1.49688C22.5675 1.3902 22.4785 1.28892 22.3834 1.19358C22.286 1.09581 22.1822 1.0043 22.0729 0.919649C21.5801 0.538287 21.0074 0.382717 20.3606 0.31208C19.7386 0.244151 18.9607 0.247636 18.009 0.251899L15.4822 0.263173C15.0679 0.265021 14.7337 0.602302 14.7355 1.01651C14.7374 1.43072 15.0746 1.76501 15.4888 1.76316L17.971 1.75208Z" fill="#FAFAFA"/>
                                <path d="M13.009 3.44199C12.4034 3.43473 11.7363 3.43473 11.0032 3.43473H10.9547C9.11821 3.43473 7.67861 3.43472 6.53648 3.55846C5.37094 3.68474 4.42656 3.94706 3.62024 4.53289C3.13209 4.88754 2.70281 5.31682 2.34815 5.80497C1.76232 6.61129 1.50001 7.55567 1.37373 8.72121C1.24999 9.86335 1.24999 11.3029 1.25 13.1395V13.23C1.24999 15.0665 1.24999 16.5061 1.37373 17.6483C1.50001 18.8138 1.76232 19.7582 2.34815 20.5645C2.70281 21.0526 3.13209 21.4819 3.62024 21.8366C4.42656 22.4224 5.37094 22.6847 6.53648 22.811C7.67859 22.9347 9.11817 22.9347 10.9547 22.9347H11.0453C12.8818 22.9347 14.3214 22.9347 15.4635 22.811C16.6291 22.6847 17.5734 22.4224 18.3798 21.8366C18.8679 21.4819 19.2972 21.0526 19.6518 20.5645C20.2377 19.7582 20.5 18.8138 20.6263 17.6483C20.75 16.5061 20.75 15.0666 20.75 13.2301V13.1813C20.75 12.4483 20.75 11.7813 20.7427 11.1757C20.7378 10.7616 20.398 10.4298 19.9838 10.4348C19.5696 10.4398 19.2379 10.7795 19.2428 11.1937C19.25 11.7895 19.25 12.448 19.25 13.1847C19.25 15.0763 19.249 16.4347 19.135 17.4867C19.0225 18.5246 18.8074 19.1748 18.4383 19.6828C18.1762 20.0436 17.8589 20.3609 17.4981 20.6231C16.9901 20.9921 16.3399 21.2073 15.302 21.3197C14.25 21.4337 12.8916 21.4347 11 21.4347C9.10843 21.4347 7.74999 21.4337 6.69804 21.3197C5.66013 21.2073 5.00992 20.9921 4.50191 20.6231C4.14111 20.3609 3.82382 20.0436 3.56168 19.6828C3.19259 19.1748 2.97745 18.5246 2.865 17.4867C2.75103 16.4347 2.75 15.0763 2.75 13.1847C2.75 11.2932 2.75103 9.93473 2.865 8.88278C2.97745 7.84487 3.19259 7.19465 3.56168 6.68665C3.82382 6.32584 4.14111 6.00855 4.50191 5.74641C5.00992 5.37733 5.66013 5.16219 6.69804 5.04974C7.74999 4.93576 9.10843 4.93473 11 4.93473C11.7367 4.93473 12.3952 4.93474 12.991 4.94189C13.4052 4.94685 13.745 4.61511 13.7499 4.20093C13.7549 3.78675 13.4232 3.44696 13.009 3.44199Z" fill="#FAFAFA"/>
                            </svg>

                            {config.code}
                        </div>
                        <div className='lock-btn' onClick={() => setConfigPublicFlagHandler(config)}>
                            <Icon name={config.isPublic ? 'lock open' : 'lock'}/>
                            {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*    <path d="M12.75 13C12.75 12.5858 12.4142 12.25 12 12.25C11.5858 12.25 11.25 12.5858 11.25 13V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V13Z" fill="#FF0000"/>*/}
                            {/*    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C8.8244 1.25 6.25003 3.82436 6.25003 7V7.52281L6.22319 7.53142C4.47267 8.1002 3.10024 9.47263 2.53146 11.2232C2.24958 12.0907 2.24975 13.1319 2.25002 14.8382V15.1618C2.24975 16.8681 2.24958 17.9093 2.53146 18.7768C3.10024 20.5274 4.47267 21.8998 6.22319 22.4686C7.0907 22.7505 8.13191 22.7503 9.83817 22.75H14.1619C15.8682 22.7503 16.9094 22.7505 17.7769 22.4686C19.5274 21.8998 20.8998 20.5274 21.4686 18.7768C21.7505 17.9093 21.7503 16.8681 21.75 15.1619V14.8381C21.7503 13.1319 21.7505 12.0907 21.4686 11.2232C20.8998 9.47263 19.5274 8.1002 17.7769 7.53142L17.75 7.52281V7C17.75 3.82436 15.1757 1.25 12 1.25ZM9.91606 7.25C9.03686 7.24997 8.33514 7.24994 7.75003 7.28306V7C7.75003 4.65279 9.65282 2.75 12 2.75C14.3472 2.75 16.25 4.65279 16.25 7V7.28306C15.6649 7.24994 14.9632 7.24997 14.084 7.25H9.91606ZM7.12463 8.85329C7.72223 8.75262 8.52818 8.75 10 8.75H14C15.4719 8.75 16.2778 8.75262 16.8754 8.85329C17.0407 8.88113 17.1832 8.91571 17.3134 8.95801C18.6072 9.37841 19.6216 10.3928 20.042 11.6867C20.2408 12.2984 20.25 13.0803 20.25 15C20.25 16.9197 20.2408 17.7016 20.042 18.3133C19.6216 19.6072 18.6072 20.6216 17.3134 21.042C16.7016 21.2407 15.9197 21.25 14 21.25H10C8.08038 21.25 7.29842 21.2407 6.68671 21.042C5.39285 20.6216 4.37844 19.6072 3.95804 18.3133C3.75929 17.7016 3.75003 16.9197 3.75003 15C3.75003 13.0803 3.75929 12.2984 3.95804 11.6867C4.37844 10.3928 5.39285 9.37841 6.68671 8.95801C6.81688 8.91571 6.95938 8.88113 7.12463 8.85329Z" fill="#FF0000"/>*/}
                            {/*</svg>*/}
                        </div>
                        <CustomSlider value={config.isActive} disabled={config.isActive} onChange={(value) => onToggleConfigHandler(config)}/>
                        {/*<Button circular toggle disabled={config.isActive} loading={isLoading}*/}
                        {/*        onClick={() => onToggleConfigHandler(config)} className={'activeBtn'}*/}
                        {/*        active={config.isActive}>{config.isActive ? t(keys.configPage.myConfigs.enabled) : t(keys.configPage.myConfigs.disabled)}</Button>*/}
                        {/*<Button basic={true} inverted icon='copy outline' className={'code-btn'}*/}
                        {/*        content={`Config CODE - ${config.code}`} onClick={event => {*/}
                        {/*    navigator.clipboard.writeText(config.code.toString())*/}
                        {/*        .then(() => {*/}
                        {/*            addToast('The code is copied', {*/}
                        {/*                appearance: 'success',*/}
                        {/*                autoDismiss: true*/}
                        {/*            })*/}
                        {/*        })*/}
                        {/*        .catch(err => {*/}
                        {/*            console.log('Something went wrong', err);*/}
                        {/*        });*/}
                        {/*}}/>*/}
                        {/*<div className="config-item--actions--public">*/}
                        {/*    <Button icon circular toggle active={config.isPublic} disabled={config.disabledSharing}*/}
                        {/*            loading={isLoading}*/}
                        {/*            onClick={event => setConfigPublicFlagHandler(config)}>*/}
                        {/*        <Icon name={config.isPublic ? 'lock open' : 'lock'}/>*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        {/*<Button circular toggle disabled={config.isActive} loading={isLoading}*/}
                        {/*        onClick={() => onToggleConfigHandler(config)} className={'activeBtn'}*/}
                        {/*        active={config.isActive}>{config.isActive ? t(keys.configPage.myConfigs.enabled) : t(keys.configPage.myConfigs.disabled)}</Button>*/}
                        <div className="deleteIcon" onClick={() => onDeleteClickHandler(config)}>
                            <Icon name={'delete'}/>
                            {/*<Button circular disabled={config.isActive} negative={true} icon={'delete'}*/}
                            {/*        onClick={() => onDeleteClickHandler(config)}/>*/}
                        </div>
                        {/*<div className="config-item--actions">*/}
                        {/*    <Button circular inverted content={`Updated: ${moment().format('L LT')}`} disabled/>*/}

                        {/*</div>*/}
                    </div>
                })}
            </div>
            {/*</Scrollbars>*/}
        </div>
    </div>
}

export default MyConfigBrowser;
