import useAuth from "./useAuth";
import useProgress from "./useProgress";
import Constants from "../Configuration";
import {useEffect, useState} from "react";
import {RequestType} from "../Services/ApiService";
import {AxiosResponse} from "axios";

const FileDownload = require('js-file-download');
const useDllFiles = (preload: boolean = false) => {
    const {state} = useAuth();
    const [dllFiles, setDllFiles] = useState([]);
    const {
        SetError,
        ClearError,
        StartLoading,
        EndLoading,
        MessageContainer,
        MessagePanel,
        error,
        isLoading,
        BeginLoading
    } = useProgress();

    const {
        StartLoading: startToggleLoading,
        EndLoading: endToggleLoading,
        isLoading: toggleLoading
    } = useProgress();

    const getFiles = async () => {
        return await BeginLoading(async () => {
            const result = await state.apiService.callApi(Constants.apiEndpoint + 'info/library');
            if (result.data)
                setDllFiles(result.data)
        })
    }
    const makeValid = async (ids: number[]) => {
        return await BeginLoading(async () => {
            const result: AxiosResponse = await state.apiService.callApi(Constants.apiEndpoint + `Upload/library/valid`, {filesToUpdate: ids}, RequestType.POST);
            return result;
        })
    }
    const deleteDllFile = async (id: number) => {
        return await BeginLoading(async () => {
            return await state.apiService.callApi(Constants.apiEndpoint + `Manager/library/${id}`, {}, RequestType.DELETE);
        })
    }
    const downloadDllFile = async (id: number, name: string) => {
        return await BeginLoading(async () => {
            const response = await state.apiService.callApi(Constants.apiEndpoint + `Download/library/` + id, undefined, RequestType.GET, 'blob');
            FileDownload(response.data, `${name}`);
        })
    }

    const downloadClientFile = async (onDownloadProgress?: (progressEvent: any) => void) => {
        return await BeginLoading(async () => {
            const response = await state.apiService.callApi(Constants.apiEndpoint + 'Download/client/false', undefined, RequestType.GET, 'blob', onDownloadProgress);
            FileDownload(response.data, `Divine.exe`);
        })
    }

    useEffect(() => {

        const fetch = async () => {
            await getFiles();
        }
        if (preload)
            fetch();
    }, [])

    return {
        isLoading,
        error,
        MessagePanel,
        getFiles,
        makeValid,
        dllFiles,
        downloadDllFile,
        deleteDllFile,
        downloadClientFile
    }
}

export default useDllFiles;
