import {useEffect} from "react";
import Oidc, {UserManager, UserManagerSettings, WebStorageStateStore} from "oidc-client-ts";
import Constants from "../Configuration";

const SilentRenew = () => {
  useEffect(() => {
      const settings: UserManagerSettings = {
          authority: Constants.stsAuthority,
          client_id: Constants.clientId,
          redirect_uri: `${Constants.clientRoot}signin-callback.html`,
          silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
          post_logout_redirect_uri: `${Constants.clientRoot}`,
          automaticSilentRenew: true,
          filterProtocolClaims: true,
          loadUserInfo: true,
          monitorSession: true,
          userStore: new WebStorageStateStore({ store: window.localStorage }),
          // userStore: new WebStorageStateStore({ store: sessionStorage }),
          accessTokenExpiringNotificationTimeInSeconds: 10,
          response_mode: "query",
      };

      const mgr = new UserManager(settings);
      mgr.signinSilentCallback().catch(function (error) {
          console.error(error);
      });
  }, [])

    return <div className="kek">Updating token</div>
}

export default SilentRenew;