import React, {useEffect, useRef} from "react";
import {useModalState} from "../Hooks/useModalState";
import {Icon} from "semantic-ui-react";
import {CSSTransition} from "react-transition-group";
import Portal from "./portal/portal";
import Tail, {TailPosition} from "./portal/tail";
import {IRouteItem} from "../routes";
import {useHistory} from "react-router-dom";
import useAuth from "../Hooks/useAuth";

export type IAdminNavbarProps = {
    items: IRouteItem[];
}

const AdminNavbar = (props: IAdminNavbarProps) => {
    const _ref = useRef(null);
    const {claims, isAuthenticated, user, state} = useAuth();
    const {onOpen, isOpen, onToggle, onClose} = useModalState();
    const history = useHistory();
    const onOverlayClick = (e: any) => {
        if (isOpen) {
            console.log('onOverlayClick')
            onToggle();
        }
    };

    useEffect(() => {
        window.addEventListener('click', onOverlayClick);

        return () => {
            window.removeEventListener('click', onOverlayClick, false);
        }
    }, [onOverlayClick])

    const onOptionCLick = (e: any, option: IRouteItem) => {
        history.push(option.url)
        // e.preventDefault();
        // e.stopPropagation();
        // switch (option.id) {
        //     case 0: {
        //         setEditMode(plugin);
        //         break;
        //     }
        // }
    }

    return <>
        <div className="clickHandler" onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onToggle();
        }
        } ref={_ref}>
            <Icon name="cog" size={'large'}/>
        </div>
        <CSSTransition in={isOpen} timeout={240} classNames="animation__header-menu">
            <Portal className="krkn__portal" isVisible={isOpen}>
                <Tail offset={{x: -50, y: 5}} position={TailPosition.BottomCenter} trackWidth={false} target={_ref}>
                    <div className="admin_navbar__container">
                        {props.items.map((option: IRouteItem, index) => {
                            return <div className="admin_navbar__item"
                                        onClick={(e) => onOptionCLick(e, option)}>
                                {/*<Icon name={option.icon}/>*/}
                                {option.name}
                            </div>
                        })}
                    </div>
                </Tail>
            </Portal>
        </CSSTransition>
    </>
}

export default AdminNavbar;
