import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import axios, {AxiosResponse} from 'axios';
import Constants from "../Configuration";

export type IDropZoneFileProps = {
    name: string;
    size: number;
    fileId: number;
}

export type IDropZoneProps = {
    description?: string;
    onFileLoaded: (arg: IDropZoneFileProps) => void;
    onError?: (arg: any) => void;
    apiUrl?: string
}

const DropZone = ({description, onFileLoaded, apiUrl = "Upload", onError}: IDropZoneProps) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileVersion', '0.0.0.0');
            const controller = apiUrl === 'upload/library' ? file.name.endsWith('.exe') ? 'upload/client' : 'upload/library' : apiUrl;
            axios.post<any, AxiosResponse<number>>(Constants.apiEndpoint + controller, formData).then((r) => {
                if (r.status === 200) {
                    onFileLoaded({name: file.name, size: file.size, fileId: r.data})
                    console.log('result', r);
                }
                else {
                    if (onError)
                        onError(r.statusText)
                }
            });
        })
    }, [])
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxFiles: 10, multiple: true, onDrop});

    const files = acceptedFiles.map((file: File) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>{description ? description : `Перетащите сюда dll файл от плагина`}</p>
            </div>
            {acceptedFiles?.length > 0 && <aside>
                <h4>Файлы</h4>
                <ul>{files}</ul>
            </aside>}
        </section>
    );
}
export default DropZone;
